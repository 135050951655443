import i18n from "i18next";
import Backend from "i18next-http-backend";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultNS: "user-auth",
    fallbackLng: "en",
    debug: false,
    keySeparator: ".", // we do use keys in form messages.welcome with '.' separator
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
