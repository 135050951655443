import "./profilesettings.scss";

import React, { useCallback, useEffect, useState } from "react";
import {
  createNewtag,
  updateFellowData,
  updateOrgAndProject,
  createNewOrgAndProject,
  deleteOrgAndProject,
  updateExistingOrgAndProject,
} from "../../../services/fellow.patchService";
import {
  getAllInterests,
  getAllSubdivisions,
  getAllWorkFields,
  getOrganizationDetails,
} from "../../../services/fellow.getService";
import {
  getExistingContactInfo,
  getExistingProfileData,
  getFormatedQuestions,
  getNodeType,
} from "../../../services/helper-functions";
import {
  useContactDispatch,
  useContactState,
} from "../../context/contactContext";
import { useGlobalDispatch, useGlobalState } from "../../context/globalContext";

import AddNewOrganization from "../../basic-components/addoraganizations/addNewOrganization";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ContactForm from "../../forms/contactform";
import CustomCheckbox from "../../basic-components/checkbox";
import ErrorAlert from "../../basic-components/snackbar";
import Footer from "../footer";
import HelpQuestionnaire from "../../helpQuestionnaire";
import ProfileImageEdit from "../../basic-components/editprofileImage";
import SkeletonFellowCardLoader from "../../Loaders/skeletonFellowCardLoader";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Textfield from "../../basic-components/textfield";
import UserLocationForm from "../../forms/locationform";
import ISO6391 from "iso-639-1";
import Button from "@mui/material/Button";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
const ProfileSettings = (props) => {
  const contactDispatch = useContactDispatch();
  const { contactinfo, smaccounts, imaccounts, mediasToContact, questions } =
    useContactState();
  const {
    currentProfileImg,
    userImage,
    regions,
    countries,
    cities,
    fieldsOfWork,
    interests,
    fellowData,
    countriesInJson,
    defaultCountryCode,
  } = useGlobalState();
  const [errors, setErrors] = useState({
    errorSeverity: "",
    openSnackbar: false,
    snackbarMsg: "",
    orgs: [],
    isError: false,
    ccode: {},
    mobile: {},
    imMediaLink: [],
    bio: {},
    status: {},
  });
  const globalDispatch = useGlobalDispatch();
  const [userProfile, setProfile] = useState({
    states: [],
    state: null,
    country: null,
    city: "",
    status: "",
    collaborationStatus: false,
    region: null,
    organizations: [{ title: "", uri: "" }],
    mediasToContact: [{ title: "Email", isAllowed: true }],
  });
  const [organizationsDetails, setOrganizationsDetails] = useState([]);
  const [existingProfileData, setExistingProfileData] = useState();
  const [existingContactData, setExistingContactData] = useState();
  const [loader, setLoader] = useState(false);
  const [overlay, setOverlay] = useState({
    overlayId: 0,
    status: false,
  });
  const stopProp = (e) => {
    e.stopPropagation();
  };
  // const [questions, setquestions] = useState([]);
  const {
    t,
    areCountriesInJsonLoading,
    isFellowProfileLoading,
    isDefaultCountryCodeLoading,
  } = props;
  const isValidURI = function (uri) {
    return new RegExp(
      "^((cc:|https:|http:|[/][/]|www.)([a-z]|[A-Z]|[0-9]|[/.])*)$",
      "g"
    ).test(uri);
  };
  const onSave = async () => {
    let isNewAdd = [];
    let isDeleteLength = "";
    let data = [];
    let result = [];
    let temp2 = [];
    let temp3 = [];
    let tempNew = [];
    let tempNewAdd = [];
    let newadded = [];
    let resultForNewAdd = [];
    let addEditList = [];
    let updatedList = [];
    let delList = [];
    let count = 0;
    var fellowId = localStorage.profile_uuid;
    var fieldValidation = false;

    //get api call for check existing & new data comparison
    if (organizationsDetails.length > 0) {
      for (var i = 0; i < organizationsDetails.length; i++) {
        if (organizationsDetails[i].attributes.field_project_name === null) {
          fieldValidation = true;
          toast.error("Project Name can't be empty!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          break;
        }
      }
    }
    if (organizationsDetails.length > 0) {
      for (var a = 0; a < organizationsDetails.length; a++) {
        if (organizationsDetails[a].attributes.field_project_website !== null) {
          var valid = isValidURI(
            organizationsDetails[a].attributes.field_project_website.uri
          );

          if (!valid) {
            toast.error("URI formate not correct!", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            break;
          }
        }
      }
    }
    if (!fieldValidation) {
      getOrganizationDetails(fellowId).then((res) => {
        if (res.data) {
          //find length of new added orgs
          for (var h = 0; h < organizationsDetails.length; h++) {
            if (organizationsDetails[h].id === undefined) {
              isNewAdd.push(organizationsDetails[h]);
            }
          }
          //find length of deleted org
          let temp1 = organizationsDetails.length - isNewAdd.length;
          isDeleteLength = res.data.length - temp1;

          if (res.data.length === 0) {
            for (var a = 0; a < isNewAdd.length; a++) {
              createNewOrgAndProject(isNewAdd[a]).then((res1) => { // eslint-disable-line
                if (res1.data) {
                  newadded.push(res1.data);
                  if (newadded.length === isNewAdd.length) {
                    for (var b = 0; b < newadded.length; b++) {
                      temp1 = {
                        type: "paragraph--organizations_and_projects",
                        id: newadded[b].id,
                        meta: {
                          target_revision_id:
                            newadded[b].attributes.drupal_internal__revision_id,
                        },
                      };
                      resultForNewAdd.push(temp1);
                    }
                    updateOrgAndProject(resultForNewAdd).then((res) => {
                      if (res.status === 204) {
                        getOrganizationDetails(fellowId).then((res) => {
                          if (res.data) {
                            var temp10 = [];
                            var temp11 = res.data;
                            for (var i = 0; i < res.data.length; i++) {
                              if (
                                res.data[i].attributes.field_project_primary
                              ) {
                                temp10 = res.data[i];
                                temp11.splice(i, 1);
                              }
                            }
                            if (Object.keys(temp10).length > 0) {
                              temp11.unshift(temp10);
                            }
                            setOrganizationsDetails(temp11);
                            //setOrganizationsDetails(res.data);
                          }
                        });
                      }
                    });
                  }
                }
              });
            }
          } //1st case when no org is there
          else if (isNewAdd.length > 0 && isDeleteLength > 0) {
            for (var c = 0; c < organizationsDetails.length; c++) {
              if (organizationsDetails[c].id !== undefined) {
                temp2.push(organizationsDetails[c]);
              }
            }
            for (var y = 0; y < res.data.length; y++) {
              for (var z = 0; z < temp2.length; z++) {
                if (res.data[y].id === temp2[z].id) {
                  break;
                }
                if (z + 1 === temp2.length && res.data[y].id !== temp2[z].id) {
                  delList.push(res.data[y].id);
                }
              }
            }
            for (var m = 0; m < delList.length; m++) {
              deleteOrgAndProject(delList[m]).then((res) => {  // eslint-disable-line
                if (res.status === 204) {
                  count++;
                  if (count === delList.length) {
                    if (isNewAdd.length > 0) {
                      //checking length of newly added org
                      for (var t = 0; t < isNewAdd.length; t++) {
                        tempNew = {
                          type: "paragraph--organizations_and_projects",
                          attributes: {
                            field_project_description: {
                              value:
                                isNewAdd[t].attributes
                                  .field_project_description === null
                                  ? (isNewAdd[
                                      t
                                    ].attributes.field_project_description = "")
                                  : isNewAdd[t].attributes
                                      .field_project_description.value,
                            },
                            field_project_website: {
                              uri:
                                isNewAdd[t].attributes.field_project_website ===
                                null
                                  ? (isNewAdd[
                                      t
                                    ].attributes.field_project_website = "")
                                  : !isNewAdd[
                                      t
                                    ].attributes.field_project_website.uri.startsWith(
                                      "http://"
                                    ) &&
                                    !isNewAdd[
                                      t
                                    ].attributes.field_project_website.uri.startsWith(
                                      "https://"
                                    ) &&
                                    !isNewAdd[
                                      t
                                    ].attributes.field_project_website.uri.includes(
                                      ":"
                                    )
                                  ? "https://" +
                                    isNewAdd[t].attributes.field_project_website
                                      .uri
                                  : isNewAdd[t].attributes.field_project_website
                                      .uri,
                            },
                            field_project_end_date:
                              isNewAdd[t].attributes.field_project_end_date,
                            field_project_location:
                              isNewAdd[t].attributes.field_project_location,
                            field_project_name:
                              isNewAdd[t].attributes.field_project_name,
                            field_project_primary:
                              isNewAdd[t].attributes.field_project_primary,
                            field_project_start_date:
                              isNewAdd[t].attributes.field_project_start_date,
                          },
                        };
                        tempNewAdd.push(tempNew);
                      } //end of for
                      //post api call for adding new data
                      for (var b = 0; b < tempNewAdd.length; b++) {
                        createNewOrgAndProject(tempNewAdd[b]).then((res1) => {  // eslint-disable-line
                          if (res1.data) {
                            newadded.push(res1.data);
                            //for loop for calling patch api for new + existing data
                            if (newadded.length === tempNewAdd.length) {
                              for (var f = 0; f < newadded.length; f++) {
                                resultForNewAdd.push(newadded[f]);
                              }
                              //for loop for taking existing
                              for (
                                var e = 0;
                                e < organizationsDetails.length;
                                e++
                              ) {
                                if (organizationsDetails[e].id !== undefined) {
                                  updatedList.push(organizationsDetails[e]);
                                }
                              }
                              for (var g = 0; g < resultForNewAdd.length; g++) {
                                updatedList.push(resultForNewAdd[g]); //complete list of data
                              }
                              for (
                                var i = 0;
                                i < updatedList.length;
                                i++ //start of for
                              ) {
                                data = {
                                  type: "paragraph--organizations_and_projects",
                                  id: updatedList[i].id,
                                  attributes: {
                                    field_project_description: {
                                      value:
                                        updatedList[i].attributes
                                          .field_project_description === null
                                          ? (updatedList[
                                              i
                                            ].attributes.field_project_description =
                                              "")
                                          : updatedList[i].attributes
                                              .field_project_description.value,
                                    },
                                    field_project_end_date:
                                      updatedList[i].attributes
                                        .field_project_end_date,
                                    field_project_name:
                                      updatedList[i].attributes
                                        .field_project_name,
                                    field_project_primary:
                                      updatedList[i].attributes
                                        .field_project_primary,
                                    field_project_start_date:
                                      updatedList[i].attributes
                                        .field_project_start_date,
                                    field_project_website: {
                                      uri:
                                        updatedList[i].attributes
                                          .field_project_website === null
                                          ? (updatedList[
                                              i
                                            ].attributes.field_project_website =
                                              "")
                                          : !updatedList[
                                              i
                                            ].attributes.field_project_website.uri.startsWith(
                                              "http://"
                                            ) &&
                                            !updatedList[
                                              i
                                            ].attributes.field_project_website.uri.startsWith(
                                              "https://"
                                            ) &&
                                            !updatedList[
                                              i
                                            ].attributes.field_project_website.uri.includes(
                                              ":"
                                            )
                                          ? "https://" +
                                            updatedList[i].attributes
                                              .field_project_website.uri
                                          : updatedList[i].attributes
                                              .field_project_website.uri,
                                    },
                                  },
                                };
                                updateExistingOrgAndProject(
                                  data,
                                  updatedList[i].id
                                ).then((res2) => {  // eslint-disable-line
                                  if (res2.data) {
                                    addEditList.push(res2.data);
                                    if (
                                      addEditList.length === updatedList.length
                                    ) {
                                      for (
                                        var k = 0;
                                        k < addEditList.length;
                                        k++
                                      ) {
                                        temp1 = {
                                          type: "paragraph--organizations_and_projects",
                                          id: addEditList[k].id,
                                          meta: {
                                            target_revision_id:
                                              addEditList[k].attributes
                                                .drupal_internal__revision_id,
                                          },
                                        };
                                        result.push(temp1);
                                      }
                                      updateOrgAndProject(result).then(
                                        (res) => {
                                          if (res.status === 204) {
                                            getOrganizationDetails(
                                              fellowId
                                            ).then((res) => {
                                              if (res.data) {
                                                var temp10 = [];
                                                var temp11 = res.data;
                                                for (
                                                  var i = 0;
                                                  i < res.data.length;
                                                  i++
                                                ) {
                                                  if (
                                                    res.data[i].attributes
                                                      .field_project_primary
                                                  ) {
                                                    temp10 = res.data[i];
                                                    temp11.splice(i, 1);
                                                  }
                                                }
                                                if (
                                                  Object.keys(temp10).length > 0
                                                ) {
                                                  temp11.unshift(temp10);
                                                }
                                                setOrganizationsDetails(temp11);
                                                // setOrganizationsDetails(res.data);
                                              }
                                            });
                                          }
                                        }
                                      );
                                    }
                                  }
                                });
                              }
                            }
                          }
                        });
                      }
                    }
                  }
                }
              });
            }

            // new org add || new org added and existing one is edited
          } else if (isNewAdd.length > 0 && isDeleteLength === 0) {
            for (var t = 0; t < isNewAdd.length; t++) {
              tempNew = {
                type: "paragraph--organizations_and_projects",
                attributes: {
                  field_project_description: {
                    value:
                      isNewAdd[t].attributes.field_project_description === null
                        ? (isNewAdd[t].attributes.field_project_description =
                            "")
                        : isNewAdd[t].attributes.field_project_description
                            .value,
                  },
                  field_project_website: {
                    uri:
                      isNewAdd[t].attributes.field_project_website === null
                        ? (isNewAdd[t].attributes.field_project_website = "")
                        : !isNewAdd[
                            t
                          ].attributes.field_project_website.uri.startsWith(
                            "http://"
                          ) &&
                          !isNewAdd[
                            t
                          ].attributes.field_project_website.uri.startsWith(
                            "https://"
                          ) &&
                          !isNewAdd[
                            t
                          ].attributes.field_project_website.uri.includes(":")
                        ? "https://" +
                          isNewAdd[t].attributes.field_project_website.uri
                        : isNewAdd[t].attributes.field_project_website.uri,
                  },
                  field_project_end_date:
                    isNewAdd[t].attributes.field_project_end_date,
                  field_project_location:
                    isNewAdd[t].attributes.field_project_location,
                  field_project_name: isNewAdd[t].attributes.field_project_name,
                  field_project_primary:
                    isNewAdd[t].attributes.field_project_primary,
                  field_project_start_date:
                    isNewAdd[t].attributes.field_project_start_date,
                },
              };
              tempNewAdd.push(tempNew);
            } //end of for
            //post api call for adding new data
            for (var b = 0; b < tempNewAdd.length; b++) {
              createNewOrgAndProject(tempNewAdd[b]).then((res1) => {  // eslint-disable-line
                if (res1.data) {
                  newadded.push(res1.data);
                  //for loop for calling patch api for new + existing data
                  if (newadded.length === tempNewAdd.length) {
                    for (var f = 0; f < newadded.length; f++) {
                      resultForNewAdd.push(newadded[f]);
                    }
                    //for loop for taking existing
                    for (var e = 0; e < organizationsDetails.length; e++) {
                      if (organizationsDetails[e].id !== undefined) {
                        updatedList.push(organizationsDetails[e]);
                      }
                    }
                    for (var g = 0; g < resultForNewAdd.length; g++) {
                      updatedList.push(resultForNewAdd[g]); //complete list of data
                    }
                    for (
                      var i = 0;
                      i < updatedList.length;
                      i++ //start of for
                    ) {
                      data = {
                        type: "paragraph--organizations_and_projects",
                        id: updatedList[i].id,
                        attributes: {
                          field_project_description: {
                            value:
                              updatedList[i].attributes
                                .field_project_description === null
                                ? (updatedList[
                                    i
                                  ].attributes.field_project_description = "")
                                : updatedList[i].attributes
                                    .field_project_description.value,
                          },
                          field_project_end_date:
                            updatedList[i].attributes.field_project_end_date,
                          field_project_name:
                            updatedList[i].attributes.field_project_name,
                          field_project_primary:
                            updatedList[i].attributes.field_project_primary,
                          field_project_start_date:
                            updatedList[i].attributes.field_project_start_date,
                          field_project_website: {
                            uri:
                              updatedList[i].attributes
                                .field_project_website === null
                                ? (updatedList[
                                    i
                                  ].attributes.field_project_website = "")
                                : !updatedList[
                                    i
                                  ].attributes.field_project_website.uri.startsWith(
                                    "http://"
                                  ) &&
                                  !updatedList[
                                    i
                                  ].attributes.field_project_website.uri.startsWith(
                                    "https://"
                                  ) &&
                                  !updatedList[
                                    i
                                  ].attributes.field_project_website.uri.includes(
                                    ":"
                                  )
                                ? "https://" +
                                  updatedList[i].attributes
                                    .field_project_website.uri
                                : updatedList[i].attributes
                                    .field_project_website.uri,
                          },
                        },
                      };
                      updateExistingOrgAndProject(data, updatedList[i].id).then(
                        (res2) => {  // eslint-disable-line
                          if (res2.data) {
                            addEditList.push(res2.data);
                            if (addEditList.length === updatedList.length) {
                              for (var k = 0; k < addEditList.length; k++) {
                                temp1 = {
                                  type: "paragraph--organizations_and_projects",
                                  id: addEditList[k].id,
                                  meta: {
                                    target_revision_id:
                                      addEditList[k].attributes
                                        .drupal_internal__revision_id,
                                  },
                                };
                                result.push(temp1);
                              }
                              updateOrgAndProject(result).then((res) => {
                                if (res.status === 204) {
                                  getOrganizationDetails(fellowId).then(
                                    (res) => {
                                      if (res.data) {
                                        var temp10 = [];
                                        var temp11 = res.data;
                                        for (
                                          var i = 0;
                                          i < res.data.length;
                                          i++
                                        ) {
                                          if (
                                            res.data[i].attributes
                                              .field_project_primary
                                          ) {
                                            temp10 = res.data[i];
                                            temp11.splice(i, 1);
                                          }
                                        }
                                        if (Object.keys(temp10).length > 0) {
                                          temp11.unshift(temp10);
                                        }
                                        setOrganizationsDetails(temp11);
                                        //  setOrganizationsDetails(res.data);
                                      }
                                    }
                                  );
                                }
                              });
                            }
                          }
                        }
                      );
                    }
                  }
                }
              });
            }
          } //if org deleted and edit present org || org deleted
          else if (isNewAdd.length === 0 && isDeleteLength > 0) {
            //if all org deleted
            if (organizationsDetails.length === 0) {
              for (var h = 0; h < res.data.length; h++) {  // eslint-disable-line
                delList.push(res.data[h].id);
              }
            } //if some org deleted
            else {
              for (var d = 0; d < res.data.length; d++) {
                for (var e = 0; e < organizationsDetails.length; e++) {
                  if (res.data[d].id === organizationsDetails[e].id) {
                    break;
                  }
                  if (
                    e + 1 === organizationsDetails.length &&
                    res.data[data].id !== organizationsDetails[e].id
                  ) {
                    delList.push(res.data[d].id);
                  }
                }
              }
            }
            for (var m = 0; m < delList.length; m++) {  // eslint-disable-line
              deleteOrgAndProject(delList[m]).then((res) => {  // eslint-disable-line
                if (res.status === 204) {
                  count++;
                  if (count === delList.length) {
                    if (organizationsDetails.length > 0) {
                      //checking length of newly added org
                      for (var t = 0; t < organizationsDetails.length; t++) {
                        temp3 = {
                          type: "paragraph--organizations_and_projects",
                          id: organizationsDetails[t].id,
                          attributes: {
                            field_project_description: {
                              value:
                                organizationsDetails[t].attributes
                                  .field_project_description === null
                                  ? (organizationsDetails[
                                      t
                                    ].attributes.field_project_description = "")
                                  : organizationsDetails[t].attributes
                                      .field_project_description.value,
                            },
                            field_project_website: {
                              uri:
                                organizationsDetails[t].attributes
                                  .field_project_website === null
                                  ? (organizationsDetails[
                                      t
                                    ].attributes.field_project_website = "")
                                  : !organizationsDetails[
                                      t
                                    ].attributes.field_project_website.uri.startsWith(
                                      "http://"
                                    ) &&
                                    !organizationsDetails[
                                      t
                                    ].attributes.field_project_website.uri.startsWith(
                                      "https://"
                                    ) &&
                                    !organizationsDetails[
                                      t
                                    ].attributes.field_project_website.uri.includes(
                                      ":"
                                    )
                                  ? "https://" +
                                    organizationsDetails[t].attributes
                                      .field_project_website.uri
                                  : organizationsDetails[t].attributes
                                      .field_project_website.uri,
                            },
                            field_project_end_date:
                              organizationsDetails[t].attributes
                                .field_project_end_date,
                            field_project_location:
                              organizationsDetails[t].attributes
                                .field_project_location,
                            field_project_name:
                              organizationsDetails[t].attributes
                                .field_project_name,
                            field_project_primary:
                              organizationsDetails[t].attributes
                                .field_project_primary,
                            field_project_start_date:
                              organizationsDetails[t].attributes
                                .field_project_start_date,
                          },
                        };
                        updatedList.push(temp3);
                      }
                      for (var i = 0; i < updatedList.length; i++) {
                        updateExistingOrgAndProject(
                          updatedList[i],
                          updatedList[i].id
                        ).then((res2) => {
                          if (res2.data) {
                            addEditList.push(res2.data);
                            if (addEditList.length === updatedList.length) {
                              getOrganizationDetails(fellowId).then((res) => {
                                if (res.data) {
                                  var temp10 = [];
                                  var temp11 = res.data;
                                  for (var i = 0; i < res.data.length; i++) {
                                    if (
                                      res.data[i].attributes
                                        .field_project_primary
                                    ) {
                                      temp10 = res.data[i];
                                      temp11.splice(i, 1);
                                    }
                                  }
                                  if (Object.keys(temp10).length > 0) {
                                    temp11.unshift(temp10);
                                  }
                                  setOrganizationsDetails(temp11);
                                  // setOrganizationsDetails(res.data);
                                }
                              });
                            }
                          }
                        });
                      }
                    }
                    // getapi call
                  }
                }
              });
            }
          }
          //edit org
          else {
            if (organizationsDetails.length > 0) {
              //checking length of newly added org
              for (var t = 0; t < organizationsDetails.length; t++) {  // eslint-disable-line
                temp3 = {
                  type: "paragraph--organizations_and_projects",
                  id: organizationsDetails[t].id,
                  attributes: {
                    field_project_description: {
                      value:
                        organizationsDetails[t].attributes
                          .field_project_description === null
                          ? (organizationsDetails[
                              t
                            ].attributes.field_project_description = "")
                          : organizationsDetails[t].attributes
                              .field_project_description.value,
                    },
                    field_project_website: {
                      uri:
                        organizationsDetails[t].attributes
                          .field_project_website === null
                          ? (organizationsDetails[
                              t
                            ].attributes.field_project_website = "")
                          : !organizationsDetails[
                              t
                            ].attributes.field_project_website.uri.startsWith(
                              "http://"
                            ) &&
                            !organizationsDetails[
                              t
                            ].attributes.field_project_website.uri.startsWith(
                              "https://"
                            ) &&
                            !organizationsDetails[
                              t
                            ].attributes.field_project_website.uri.includes(":")
                          ? "https://" +
                            organizationsDetails[t].attributes
                              .field_project_website.uri
                          : organizationsDetails[t].attributes
                              .field_project_website.uri,
                    },
                    field_project_end_date:
                      organizationsDetails[t].attributes.field_project_end_date,
                    field_project_location:
                      organizationsDetails[t].attributes.field_project_location,
                    field_project_name:
                      organizationsDetails[t].attributes.field_project_name,
                    field_project_primary:
                      organizationsDetails[t].attributes.field_project_primary,
                    field_project_start_date:
                      organizationsDetails[t].attributes
                        .field_project_start_date,
                  },
                };
                updatedList.push(temp3);
              }
              for (var i = 0; i < updatedList.length; i++) {
                updateExistingOrgAndProject(
                  updatedList[i],
                  updatedList[i].id
                ).then((res2) => {
                  if (res2.data) {
                    addEditList.push(res2.data);
                    if (addEditList.length === updatedList.length) {
                      getOrganizationDetails(fellowId).then((res) => {
                        if (res.data) {
                          var temp10 = [];
                          var temp11 = res.data;
                          for (var i = 0; i < res.data.length; i++) {
                            if (res.data[i].attributes.field_project_primary) {
                              temp10 = res.data[i];
                              temp11.splice(i, 1);
                            }
                          }
                          if (Object.keys(temp10).length > 0) {
                            temp11.unshift(temp10);
                          }
                          setOrganizationsDetails(temp11);
                        }
                      });
                    }
                  }
                });
              }
            }
          }
          //latest Change
        }
      }); //end of get api call

      const id = await localStorage.getItem("profile_uuid");

      let fellowInfo = {
        data: { type: getNodeType("fellow"), id, attributes: {} },
      };
      if (errors.ccode.isErorr || errors.mobile.isErorr) {
        setErrors((state) => ({
          ...state,
          openSnackbar: true,
          errorSeverity: "error",
          snackbarMsg: "Please enter valid details",
        }));
        return;
      }

      if (userProfile.bio && errors.bio.isErorr) {
        setErrors((state) => ({
          ...state,
          openSnackbar: true,
          errorSeverity: "error",
          snackbarMsg: "Please enter valid details",
        }));
        return;
      }

      if (userProfile.status && errors.status.isErorr) {
        setErrors((state) => ({
          ...state,
          openSnackbar: true,
          errorSeverity: "error",
          snackbarMsg: "Please enter valid details",
        }));
        return;
      }

      if (userProfile.region) {
        fellowInfo.data["relationships"] = {
          field_region: {
            data: {
              type: getNodeType("region"),
              id: userProfile?.region?.id,
            },
          },
        };
      }

      //validation for im accounts
      let imErrors = false;
      errors.imMediaLink.forEach((im) => {
        if (im?.isErorr) {
          imErrors = true;
          setErrors((state) => ({
            ...state,
            openSnackbar: true,
            errorSeverity: "error",
            snackbarMsg: "Please enter valid details",
          }));
          return;
        }
      });
      if (imErrors) {
        return;
      }

      //validation for organization urls
      let orgsErrors = false;
      errors.orgs.forEach((im) => {
        if (im?.isErorr) {
          orgsErrors = true;
          setErrors((state) => ({
            ...state,
            openSnackbar: true,
            errorSeverity: "error",
            snackbarMsg: "Please enter valid details",
          }));
          return;
        }
      });
      if (orgsErrors) {
        return;
      }

      let field_other_social_media_links = {};
      smaccounts.forEach((acc) => {
        let value, title;

        if (
          acc?.handler &&
          acc?.mediatype?.title !== "Tumblr" &&
          acc?.mediatype?.title !== "Other"
        )
          value = acc.handler.replace(acc.mediatype.url, "");

        if (acc?.mediatype?.title === "Other") {
          title = "website";
          value = acc.handler;
        }

        if (acc?.mediatype?.title === "Tumblr") value = acc.handler;

        if (value)
          field_other_social_media_links = acc?.handler && {
            platform_values: {
              ...field_other_social_media_links.platform_values,
              [title || acc.mediatype.title.toLowerCase()]: { value: value },
            },
          };
      });

      let field_fd_fellow_ims = {};
      imaccounts.forEach((acc) => {
        if (acc?.handler)
          field_fd_fellow_ims = {
            platform_values: {
              ...field_fd_fellow_ims.platform_values,
              [acc.mediatype.title.toLowerCase()]: { value: acc.handler },
            },
          };
      });
      let orgs = [];
      userProfile &&
        userProfile.organizations.forEach((org) => {
          if (org.uri && org.title)
            orgs.push({
              title: org.title,
              uri:
                !org.uri.startsWith("http://") &&
                !org.uri.startsWith("https://") &&
                !org.uri.includes(":")
                  ? "https://" + org.uri
                  : org.uri,
            });
        });
      const topics =
        userProfile?.selectedWorkFields.map((topic) => {
          return { type: getNodeType("topics"), id: topic.id };
        }) || [];

      let allTags = [];
      const newTags = [];
      userProfile.selectedInterests.forEach((tag) => {
        if (typeof tag === "string") newTags.push(tag);
        else allTags.push({ type: getNodeType("tags"), id: tag.id });
      });

      const spokenLanguages = userProfile?.selectedSpokenLanguage.map(
        (s) => s.code
      );

      const questionnaires = getFormatedQuestions(questions);

      setLoader(true);
      Promise.all(newTags.map((tag) => createNewtag(tag)))
        .then((responses) => Promise.all(responses.map((res) => res)))
        .then((results) => {
          allTags = [...allTags, ...results];
          fellowInfo = {
            data: {
              type: getNodeType("fellow"),
              id,
              attributes: {
                ...questionnaires,
                field_fd_fellow_location: {
                  country_code: userProfile?.country?.code || null,
                  administrative_area:
                    userProfile?.state?.code ||
                    userProfile?.state?.title ||
                    null,
                  locality: userProfile?.city || "",
                },
                field_fd_profile_status: userProfile?.status || "",
                field_fd_spoken_languages: spokenLanguages || [],
                field_other_social_media_links,
                field_fd_fellow_ims,
                field_phone:
                  // `${contactinfo?.ccode || defaultCountryCode},${
                  `${contactinfo?.ccode},${
                    contactinfo?.mobile
                  }` || "",
                field_fd_bio: { value: userProfile?.bio || "" },
                field_link_organizations: orgs || [],
                field_collaboration_status: userProfile.collaborationStatus,
                field_fd_contact_preferences: JSON.stringify(
                  userProfile.mediasToContact
                ),
              },
              relationships: {
                ...fellowInfo.data.relationships,
                field_topics: { data: topics || [] },
                field_tags: { data: allTags || [] },
              },
            },
          };

          updateFellowData(fellowInfo)
            .then((res) => {
              if (res) {
                globalDispatch({ type: "setUserData", payload: res });
                setLoader(false);
                setErrors((errors) => ({
                  ...errors,
                  openSnackbar: true,
                  errorSeverity: "success",
                  snackbarMsg: t("toastMessages:profile_data_save.success"),
                }));
              }
            })
            .catch((err) => {
              setLoader(false);
              setErrors((errors) => ({
                ...errors,
                openSnackbar: true,
                errorSeverity: "error",
                snackbarMsg: "Cound not save the data.",
              }));
            });
        })
        .catch((err) => {
          setErrors((errors) => ({
            ...errors,
            openSnackbar: true,
            errorSeverity: "error",
            snackbarMsg: "Cound not create new tags.",
          }));
        });
    }
  };

  const onCancel = () => {
    var fellowId = localStorage.profile_uuid;
    getOrganizationDetails(fellowId).then((res) => {
      if (res.data) {
        var temp10 = [];
        var temp11 = res.data;
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].attributes.field_project_primary) {
            temp10 = res.data[i];
            temp11.splice(i, 1);
          }
        }
        if (Object.keys(temp10).length > 0) {
          temp11.unshift(temp10);
        }
        setOrganizationsDetails(temp11);
        // setOrganizationsDetails(res.data);
      }
    });
    if (existingProfileData) setProfile({ ...existingProfileData });

    if (existingContactData) {
      let data = JSON.parse(JSON.stringify(existingContactData));
      contactDispatch({ type: "setDetails", payload: data });
    }
  };

  const setErrorValues = (data) => {
    setErrors((state) => ({ ...state, ...data }));
  };

  const setImages = (croppedImage) => {
    setProfile((state) => ({
      ...state,
      imageBinary: croppedImage?.imageBinary || "",
      image: croppedImage?.imageUrl || currentProfileImg,
    }));
  };

  const handleSocialMedia = (event, index) => {
    const { mediasToContact } = userProfile;
    const { checked } = event.target;
    let allMedias = [...mediasToContact];
    allMedias[index].isAllowed = checked;
    setProfile((state) => ({ ...state, mediasToContact: allMedias }));
  };

  // for work feilds
  const setUserDetails = (event) => {
    let { value, name } = event.target;
    let data = { ...errors };
    setProfile((state) => ({ ...state, [name]: value, name }));

    if (name === "status" && value.length > 100) {
      data.status.isErorr = true;
      data.status.msg = "Status should have 100 characters only.";
    } else {
      data.status.isErorr = false;
      data.status.msg = "";
    }
    setErrors(data);
  };

  // for adding organizations
  const addOrganizations = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    let temp = [];
    let temp1 = [];
    temp = {
      type: "paragraph--organizations_and_projects",
      attributes: {
        field_project_description: null,
        field_project_website: null,
        field_project_end_date: today,
        field_project_location: "",
        field_project_name: "",
        field_project_primary: false,
        field_project_start_date: today,
      },
    };
    temp1 = [...organizationsDetails];
    temp1.push(temp);
    setOrganizationsDetails(temp1);
  };

  const updateOrgDetails = (event, index, eventName) => {
    let temp1 = [...organizationsDetails];
    let { name, value } = "";
    if (eventName === "startDate" || eventName === "endDate") {
      name = eventName;
      value = event;
    } else {
      name = event.target.name;
      value = event.target.value;
    }

    if (name === "title") {
      temp1[index].attributes.field_project_name = value;
    } else if (name === "description") {
      temp1[index].attributes.field_project_description === null
        ? (temp1[index].attributes.field_project_description = {})
        : (temp1[index].attributes.field_project_description.value = value);
    } else if (name === "uri") {
      temp1[index].attributes.field_project_website === null
        ? (temp1[index].attributes.field_project_website = {})
        : (temp1[index].attributes.field_project_website.uri = value);
      if (temp1[index].attributes.field_project_website.uri) {
        temp1[index].attributes.field_project_website.uri =
          !temp1[index].attributes.field_project_website.uri.startsWith(
            "http://"
          ) &&
          !temp1[index].attributes.field_project_website.uri.startsWith(
            "https://"
          ) &&
          !temp1[index].attributes.field_project_website.uri.includes(":")
            ? "https://" + temp1[index].attributes.field_project_website.uri
            : temp1[index].attributes.field_project_website.uri;
      }
    } else if (name === "checkbox") {
      if (event.target.checked) {
        for (var i = 0; i < organizationsDetails.length; i++) {
          temp1[i].attributes.field_project_primary = false;
        }
      }
      temp1[index].attributes.field_project_primary = event.target.checked;
    } else if (name === "startDate") {
      var startDate = value;
      var dd = String(startDate === null ? "" : startDate.getDate()).padStart(
        2,
        "0"
      );
      var mm = String(
        startDate === null ? "" : startDate.getMonth() + 1
      ).padStart(2, "0"); //January is 0!
      var yyyy = startDate === null ? "" : startDate.getFullYear();
      startDate = yyyy + "-" + mm + "-" + dd;
      temp1[index].attributes.field_project_start_date = startDate;
    } else if (name === "endDate") {
      var endDate = value;
      var ddate = String(endDate === null ? "" : endDate.getDate()).padStart(
        2,
        "0"
      );
      var mmonth = String(endDate === null ? "" : endDate.getMonth() + 1).padStart(
        2,
        "0"
      ); //January is 0!
      var yyyyear = endDate === null ? "" : endDate.getFullYear();
      endDate = yyyyear + "-" + mmonth + "-" + ddate;
      temp1[index].attributes.field_project_end_date = endDate;
    }
    setOrganizationsDetails(temp1);
  };

  //details for location form
  const setLocationDetails = (value, name) => {
    setProfile((state) => ({ ...state, [name]: value }));

    //if there are values for country field
    if (name === "country" && value) {
      //if there are values for subdivisions field
      if (value.subdivisions.length > 0)
        setProfile((state) => ({ ...state, states: value.subdivisions }));
      else
        setProfile((state) => ({
          ...state,
          city: "",
          states: [],
          state: null,
        }));
    }
  };

  //seeking-collaboration switch on change
  const handleChange = (event) => {
    setProfile((state) => ({
      ...state,
      collaborationStatus: event.target.checked,
    }));
  };

  const setSnackbar = (value) => {
    setErrors((errors) => ({ ...errors, openSnackbar: value }));
  };
  //getting profileform data from localstaorage(update on api integration)
  useEffect(() => {
    //api for orgnization details
    var fellowId = localStorage.profile_uuid;
    getOrganizationDetails(fellowId).then((res) => {
      if (res.data) {
        var temp10 = [];
        var temp11 = res.data;
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].attributes.field_project_primary) {
            temp10 = res.data[i];
            temp11.splice(i, 1);
          }
        }
        if (Object.keys(temp10).length > 0) {
          temp11.unshift(temp10);
        }
        setOrganizationsDetails(temp11);
        //  setOrganizationsDetails(res.data);
      }
    });
    if (!fieldsOfWork?.length) {
      getAllWorkFields().then((res) => {
        if (res)
          globalDispatch({
            type: "setListData",
            payload: { name: "fieldsOfWork", value: res.data || [] },
          });
      });
    }

    if (!interests?.length) {
      getAllInterests().then((res) => {
        if (res)
          globalDispatch({
            type: "setListData",
            payload: { name: "interests", value: res.data },
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setProfile((state) => ({ ...state, mediasToContact }));
  }, [mediasToContact]);

  useEffect(() => {
    if (
      Object.keys(fellowData).length > 0 &&
      Object.keys(countriesInJson).length > 0 &&
      defaultCountryCode
    ) {
      let existingData = getExistingProfileData(fellowData, countriesInJson);
      if (existingData?.userProfile) {
        setProfile((state) => ({
          ...existingData.userProfile,
          image: userImage || currentProfileImg,
          organizations: [...existingData.organizations],
        }));
        setExistingProfileData((state) => ({
          ...existingData.userProfile,
          image: userImage || currentProfileImg,
          organizations: [...existingData.organizations],
        }));
        getSubdivisionsOfUserCountry(existingData);
      }
      const questionsData = t("questionnaires:questions", {
        returnObjects: true,
      });
      let existingContactData = getExistingContactInfo(
        fellowData,
        defaultCountryCode,
        questionsData
      );
      let copy = JSON.parse(
        JSON.stringify(existingContactData.mediasToContact)
      );

      if (existingContactData) {
        setProfile((state) => ({ ...state, mediasToContact: copy }));
        setExistingContactData((state) => ({
          ...state,
          ...JSON.parse(JSON.stringify(existingContactData)),
        }));
        contactDispatch({ type: "setDetails", payload: existingContactData });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fellowData, countriesInJson, defaultCountryCode, t, contactDispatch]);

  const getSubdivisionsOfUserCountry = (existingData) => {
    if (existingData?.userProfile?.country) {
      getAllSubdivisions(existingData.userProfile.country.code)
        .then((res) => {
          if (res) {
            let state = null;
            if (existingData?.userProfile?.state?.code) {
              let found = res.find(
                (s) => s.code === existingData.userProfile.state.code
              );
              state = found ? found : null;
            }
            setProfile((profile) => ({
              ...profile,
              states: res || [],
              state: state,
            }));
            setExistingProfileData((profile) => ({
              ...profile,
              states: res || [],
              state: state,
            }));
          }
        })
        .catch((err) => {
          setSnackbar(true, "error", "Could not fetch the subdivisions");
        });
    }
  };

  const handleQuestions = useCallback(
    (value) => {
      contactDispatch({ type: "setQuestionnaireData", payload: value });
    },
    [contactDispatch]
  );
  const LoginOverlay = ({ removeOverlay }) => {
    return (
      <div className="overlay_background" onClick={(e) => removeOverlay()}>
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <form style={{ alignItems: "normal" }}>
            <span style={{ textAlign: "initial" }}>
              <div className="head-text">
                {overlay.overlayId === 1
                  ? t("settings:profile.oraganizations")
                  : overlay.overlayId === 2
                  ? t("settings:profile.contact")
                  : t("profile.form_fields.fields_of_interest.interest")}
              </div>
            </span>
            {overlay.overlayId === 1 ? (
              <div>
                <p
                  style={{
                    textAlign: "initial",
                    color: "gray",
                  }}
                >
                  {t(
                    "profile.form_fields.tooltip_discription.organization_and_project.line1"
                  )}
                </p>
                <p
                  style={{
                    textAlign: "initial",
                    color: "gray",
                  }}
                >
                  {t(
                    "profile.form_fields.tooltip_discription.organization_and_project.line2"
                  )}
                </p>
              </div>
            ) : overlay.overlayId === 2 ? (
              <div>
                <p
                  style={{
                    textAlign: "initial",
                    color: "gray",
                  }}
                >
                  {t("profile.form_fields.tooltip_discription.contact.line1")}
                </p>
                <span style={{ textAlign: "initial" }}>
                  <div className="head-text">
                    {t("profile.form_fields.tooltip_discription.contact.line3")}
                  </div>
                </span>
                <p
                  style={{
                    textAlign: "initial",
                    color: "gray",
                  }}
                >
                  {t("profile.form_fields.tooltip_discription.contact.line2")}
                </p>
              </div>
            ) : (
              <div>
                <p
                  style={{
                    textAlign: "initial",
                    color: "gray",
                  }}
                >
                  {t(
                    "profile.form_fields.tooltip_discription.interest_tag.line1"
                  )}
                </p>
                <p
                  style={{
                    textAlign: "initial",
                    color: "gray",
                  }}
                >
                  {t(
                    "profile.form_fields.tooltip_discription.interest_tag.line2"
                  )}
                </p>
              </div>
            )}
            <div>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#4155ee",
                  width: "100%",
                  marginTop: "4%",
                }}
                onClick={() =>
                  setOverlay({
                    overlayId: 0,
                    status: false,
                  })
                }
              >
                OK
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const randomId = function(length = 6) {
    return Math.random().toString(36).substring(2, length+2);
  };

  return (
    <div className="profile-settings">
      {!isFellowProfileLoading &&
      !areCountriesInJsonLoading &&
      !isDefaultCountryCodeLoading ? (
        <>
          <div className="profile-settings-content">
            {/* image profile */}
            <ProfileImageEdit
              profileImage={userProfile.image || userImage}
              setImages={setImages}
              setErrorValues={setErrorValues}
              t={t}
            />
            <div className="seeking-collaboration">
              <Switch
                checked={userProfile.collaborationStatus || false}
                onChange={handleChange}
                color="primary"
                name="seeking"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              <div className="title">
                {t("profile.form_fields.seeking_collaboration")}
              </div>
            </div>
            <Textfield
              id="status"
              onChange={setUserDetails}
              value={userProfile.status}
              label={t("common:contact_list.filter_lable")}
              name="status"
              className="status"
              error={errors?.status?.isErorr}
              errorMsg={errors?.status?.isErorr && errors?.status?.msg}
            />
            {/* bacics location form */}
            <div className="content-box location-details">
              <div className="head-text">{t("settings:profile.basics")}</div>
              <UserLocationForm
                regions={regions}
                countries={countries}
                cities={cities}
                states={userProfile?.states || []}
                setLocationDetails={setLocationDetails}
                region={userProfile.region && userProfile.region}
                country={userProfile.country || null}
                state={userProfile.state}
                city={userProfile.city}
                t={t}
              />
              <Textfield
                label={t("profile.form_fields.bio.lable")}
                id={t('bio-') + randomId()}
                rows={4}
                defaultValue="Default Value"
                variant="filled"
                name="bio"
                onChange={setUserDetails}
                value={userProfile.bio}
                multiline={true}
                className="bio"
                error={errors.bio.isErorr}
                errorMsg={errors.bio.isErorr && errors.bio.msg}
              />
            </div>

            {/* fields of work */}
            <div className="content-box workfields">
              <div className="head-text">
                {t("profile.form_fields.fields_of_work.lable")}
              </div>
              <Autocomplete
                multiple
                options={fieldsOfWork}
                id={t('field-of-work-') + randomId()}
                limitTags={1}
                getOptionLabel={(option) => option?.attributes?.name || ""}
                value={userProfile?.selectedWorkFields || []}
                onChange={(event, value) => {
                  console.log("data show", value);
                  setProfile((state) => ({
                    ...state,
                    selectedWorkFields: value,
                  }));
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                filterSelectedOptions
                name="work"
                className="autocomplete-feilds"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={t("profile.form_fields.fields_of_work.lable")}
                    placeholder={t("profile.form_fields.fields_of_work.lable")}
                  />
                )}
              />
              {/* fields of Speaking language(s) */}
              <Autocomplete
                id={t('spokenLanguage-') + randomId()}
                multiple
                options={ISO6391.getLanguages(ISO6391.getAllCodes())}
                freeSolo
                limitTags={1}
                value={userProfile?.selectedSpokenLanguage || []}
                onChange={(event, value) => {
                  setProfile((state) => ({
                    ...state,
                    selectedSpokenLanguage: value,
                  }));
                }}
                getOptionLabel={(option) => option.name || option.code}
                getOptionSelected={(option, value) =>
                  option.code === value.code
                }
                name="spokenLanguage"
                className="autocomplete-feilds spoken-language"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={t("common:speaking_lang.title")}
                  />
                )}
              />
              {/* fields of interests */}
              <div>
                <div className="head-text hint-text">
                  {t("profile.form_fields.fields_of_interest.interest")}
                  <HelpOutlineIcon
                    className="hint-icon profileSetting"
                    onClick={() => {
                      setOverlay({
                        overlayId: 3,
                        status: true,
                      });
                    }}
                  />
                </div>
                <Autocomplete
                  multiple
                  options={interests}
                  id={t('work-') + randomId()}
                  freeSolo
                  limitTags={1}
                  getOptionLabel={(option) =>
                    option?.attributes?.name || option || ""
                  }
                  value={userProfile?.selectedInterests || []}
                  onChange={(event, value) => {
                    setProfile((state) => ({
                      ...state,
                      selectedInterests: value,
                    }));
                  }}
                  name="work"
                  className="autocomplete-feilds interests"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label={t("profile.form_fields.fields_of_interest.lable")}
                      placeholder={t(
                        "profile.form_fields.fields_of_interest.lable"
                      )}
                    />
                  )}
                />
                {/* <div className="helper-text">
                  {t("profile.form_fields.fields_of_interest.helper_text")}
                </div> */}
              </div>
            </div>
            {/* -----Changes----- */}
            <div
              className={overlay.status ? "overlay_shown" : "overlay_hidden"}
            >
              <LoginOverlay removeOverlay={() => setOverlay(overlay.status)} />
            </div>
            {/* -----Changes----- */}
            {/* organizations */}
            <div className="content-box">
              <div className="head-text hint-text">
                {t("settings:profile.oraganizations")}
                <HelpOutlineIcon
                  className="hint-icon profileSetting"
                  onClick={() => {
                    setOverlay({
                      overlayId: 1,
                      status: true,
                    });
                  }}
                />
              </div>
              {organizationsDetails.length < 0
                ? []
                : organizationsDetails.map((org, index) => {
                    return (
                      <>
                        <AddNewOrganization
                          errors={errors.orgs[index]}
                          key={index}
                          data={org}
                          deleteOrganization={() => {
                            let temp1 = [...organizationsDetails];
                            temp1.splice(index, 1);
                            setOrganizationsDetails(temp1);
                          }}
                          index={index}
                          onChange={updateOrgDetails}
                          t={t}
                        />
                      </>
                    );
                  })}
              <div className="add" onClick={addOrganizations}>
                <p className="resend">
                  + {t("profile.form_fields.add_organization.lable")}
                </p>
              </div>
            </div>

            {/* contact info form */}
            <div className="content-box">
              <div className="head-text hint-text">
                {t("settings:profile.contact")}
                <HelpOutlineIcon
                  className="hint-icon profileSetting"
                  onClick={() => {
                    setOverlay({
                      overlayId: 2,
                      status: true,
                    });
                  }}
                />
              </div>
              <ContactForm
                setErrorValues={setErrorValues}
                errors={errors}
                t={t}
              />
              <p className="sub-headings">
                {t("contact_info.form_fields.media_preference.lable")}
              </p>
              <div className="contact-options">
                {userProfile.mediasToContact &&
                  userProfile.mediasToContact.map((item, index) => {
                    return (
                      <CustomCheckbox
                        name={item.title}
                        label={item.title}
                        onChange={(event) => handleSocialMedia(event, index)}
                        isChecked={item.isAllowed}
                        key={index}
                      />
                    );
                  })}
              </div>
            </div>

            <div className="content-box questionnaire">
              <HelpQuestionnaire
                t={t}
                questions={questions}
                setquestions={handleQuestions}
                fellowData={fellowData}
              />
            </div>
          </div>
          <Footer onSave={onSave} onCancel={onCancel} t={t} loader={loader} />
        </>
      ) : (
        <SkeletonFellowCardLoader />
      )}
      <ErrorAlert
        openSnackbar={errors.openSnackbar}
        msg={errors.snackbarMsg}
        errorSeverity={errors.errorSeverity}
        setSnackbar={setSnackbar}
      />
    </div>
  );
};

export default ProfileSettings;
