import React from "react";
import LinkIcon from "../../../images/website-link.svg";
import LocationIcon from "../../../images/LocationIcon.png";
const DisplayOrganizations = ({ organizations, text }) => {
  return organizations.length > 0 ? (
    <div className="content-box">
      <div className="header">
        <h1 className="head-text">{text}</h1>
      </div>
      {organizations.length > 0 &&
        organizations.map((org, index) => {
          var startDate =
            org.attributes.field_project_start_date == null
              ? ""
              : new Date(org.attributes.field_project_start_date);
          var dateEnd =
            org.attributes.field_project_end_date == null
              ? ""
              : new Date(org.attributes.field_project_end_date);
          var months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          if (startDate !== "") {
            var mmm = startDate.getMonth();
            var yyyy = startDate.getFullYear();
            startDate = months[mmm] + "  " + yyyy;
          }
          if (dateEnd !== "") {
            var mmm1 = dateEnd.getMonth();
            var yyyy1 = dateEnd.getFullYear();
            dateEnd = months[mmm1] + "  " + yyyy1;
          }
          return (
            <div>
              <div className="orgs-project">
                <b>
                  {" "}
                  <p style={{ margin: "0px" }}>
                    {org.attributes.field_project_name === null
                      ? ""
                      : org.attributes.field_project_name}
                  </p>
                </b>

                <div hidden={org.attributes.field_project_location === null}>
                  <img
                    alt="location"
                    src={LocationIcon}
                    style={{ float: "left", margin: "1.5% 2% 0 0" }}
                  />
                  <p
                    style={{
                      fontSize: "12px",
                      margin: "0px",
                      color: "#00000099",
                    }}
                  >
                    {org.attributes.field_project_location === null
                      ? ""
                      : org.attributes.field_project_location}
                  </p>
                </div>

                <p hidden={startDate === "" || dateEnd === ""}>
                  {startDate + " - " + dateEnd}
                </p>

                <p>
                  {org.attributes.field_project_description === null
                    ? ""
                    : org.attributes.field_project_description.value}
                </p>
              </div>
              <div hidden={org.attributes.field_project_website === null}>
                <div className="orgs-websites" key={index}>
                  <img
                    src={LinkIcon}
                    className="link-icon"
                    alt="user website link"
                  />
                  <a
                    target="_blank"
                    className="website"
                    rel="noopener noreferrer"
                    href={
                      org.attributes.field_project_website === null
                        ? ""
                        : org.attributes.field_project_website.uri
                    }
                  >
                    {org.attributes.field_project_website === null
                      ? ""
                      : org.attributes.field_project_website.uri}
                  </a>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  ) : null;
};

export default DisplayOrganizations;
