import Autocomplete from "@material-ui/lab/Autocomplete";
// eslint-disable-next-line
import AutocompleteFields from "../basic-components/autocomplete";
import React from "react";
import TextField from "@material-ui/core/TextField";

const UserLocationForm = ({
  regions,
  countries,
  states,
  region,
  country,
  city,
  state,
  setLocationDetails,
  t,
}) => {
  return (
    <>
      <Autocomplete
        options={regions}
        value={region}
        onChange={(event, value) => {
          setLocationDetails(value, "region");
        }}
        name="region"
        className={`autocomplete-feilds address-fields`}
        getOptionLabel={(option) => option?.attributes?.name}
        getOptionSelected={(option) =>
          option.attributes.name === region.attributes.name
        }
        renderInput={(params) => {
          params.inputProps.autoComplete = "new-password";
          return (
            <TextField
              {...params}
              label={t("profile.form_fields.region.lable")}
              variant="filled"
            />
          );
        }}
      />
      <Autocomplete
        value={country}
        options={countries}
        onChange={(event, value) => setLocationDetails(value, "country")}
        name="country"
        className="autocomplete-feilds address-fields"
        getOptionLabel={(option) => option.title}
        getOptionSelected={(option) =>
          option.title === country.title || option.code === country.code || null
        }
        renderInput={(params) => {
          params.inputProps.autoComplete = "new-password";
          return (
            <TextField
              {...params}
              label={t("profile.form_fields.country.lable")}
              variant="filled"
            />
          );
        }}
      />
      <div className="two-relevant-infos">
        <TextField
          label={t("profile.form_fields.city.lable")}
          id="city"
          variant="filled"
          value={city}
          onChange={(event) => setLocationDetails(event.target.value, "city")}
          className="textfield address"
          name="city"
          disabled={country === null ? true : false}
        />

        <Autocomplete
          value={state}
          options={states}
          onChange={(event, value) => {
            setLocationDetails(value, "state");
          }}
          name="state"
          disabled={country === null || !states.length ? true : false}
          className="textfield address"
          getOptionLabel={(option) => option.title || option.code}
          getOptionSelected={(option) => option.title === state.title}
          renderInput={(params) => {
            params.inputProps.autoComplete = "new-password";
            return (
              <TextField
                {...params}
                label={t("profile.form_fields.state.lable")}
                variant="filled"
              />
            );
          }}
        />
      </div>
    </>
  );
};

export default UserLocationForm;
