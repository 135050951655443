import React from "react";
import "./addmedia.scss";
import Close from "../../../images/close-textfield.svg";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { renderIcon } from "../../../services/helper-functions";
import { useTranslation } from "react-i18next";

const NewSocialMediaField = (props) => {
  const { t } = useTranslation(["forms"]);
  const {
    onMediaSelector,
    onLinkSelector,
    deleteMediaType,
    account,
    mediasToDisplay,
    index,
    type,
    label,
    errorMsg,
    isErorr,
    showCloseIcon,
    filledAccounts,
  } = props;

  const getPlaceholderText = () => {
    switch (account?.mediatype?.title?.toLowerCase() || "") {
      case "whatsapp":
        return t(
          "forms:contact_info.form_fields.add_instant_messanger.whatsapp_placeholder"
        );
      case "telegram":
        return t(
          "forms:contact_info.form_fields.add_instant_messanger.telegram_placeholder"
        );
      case "skype":
        return t(
          "forms:contact_info.form_fields.add_instant_messanger.skype_placeholder"
        );
      case "facebook messanger":
        return t(
          "forms:contact_info.form_fields.add_instant_messanger.messenger_placeholder"
        );
      case "hangout":
        return t(
          "forms:contact_info.form_fields.add_instant_messanger.hangount_placeholder"
        );
      default:
        return t(
          "forms:contact_info.form_fields.add_instant_messanger.placeholder"
        );
    }
  };

  return (
    <div key={index} className="new-media-type">
      <Autocomplete
        freeSolo
        disableClearable={true}
        selectOnFocus
        forcePopupIcon
        inputValue={account.handler || ""}
        onChange={(event, value) => {
          onMediaSelector(value, index, "mediatype", type);
          if (value) onLinkSelector("handler", value.url, index, type);
        }}
        onInputChange={(event, value, reason) => {
          if (reason === "input") {
            onLinkSelector("handler", value, index, type);
          } else if (reason === "clear") {
            onLinkSelector("handler", "", index, type);
          }
        }}
        getOptionLabel={(option) => option.url}
        options={mediasToDisplay}
        className={`autocomplete-feilds ${isErorr ? "error" : ""}`}
        getOptionDisabled={(option) => {
          const find = filledAccounts?.filter(
            (item) => item?.mediatype?.title === option?.title && item?.handler
          );
          if (find[0]) {
            return true;
          }
          return false;
        }}
        renderOption={(option) => (
          <React.Fragment>
            {renderIcon(option.title.toLowerCase(), "contact-icons")}
            <span style={{ marginLeft: "10px" }}>{option.title}</span>
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={account?.mediatype?.title || label}
            variant="filled"
            placeholder={
              type === 1
                ? getPlaceholderText()
                : t(
                    "forms:contact_info.form_fields.add_social_media.placeholder"
                  )
            }
            error={isErorr}
            helperText={errorMsg}
          />
        )}
      />
      {(showCloseIcon || (!showCloseIcon && account.handler)) && (
        <div
          className="close-wrapper"
          onClick={() => deleteMediaType(type, index)}
        >
          <img
            className="close-icon"
            src={Close}
            alt="close organization structure"
          />
        </div>
      )}
    </div>
  );
};

export default NewSocialMediaField;
