import "./search-results.scss";
import React, { useEffect, useState } from "react";
import {
  getLocalStorage,
  getFellowImage,
  getFellowLocation,
  getRelationshipMappings,
} from "../../services/helper-functions";
import { useGlobalDispatch, useGlobalState } from "../context/globalContext";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomButton from "../basic-components/button";
import FellowRow from "../basic-components/fellowRow";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactComponent as SearchIcon } from "../../images/search-icon.svg";
import SkeletonFellowCardLoader from "../Loaders/skeletonFellowCardLoader";
import TextField from "@material-ui/core/TextField";
import UserLocationForm from "../forms/locationform";
import {
  getAllRegions,
  getAllWorkFields,
  getFellowProfile,
} from "../../services/fellow.getService";
import { searchFellow } from "../../services/fellow.getService";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import saveSearchIcon from "../../images/bookmark_border.png";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { saveSearches } from "../../services/fellow.patchService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
const SearchResults = (props) => {
  const { t } = useTranslation(["forms", "search", "common"]);
  const statusFilter = [
    { isCollaborating: false, title: t("common:search_filter.all_status") },
    {
      isCollaborating: true,
      title: t("common:search_filter.seeking_collaboration"),
    },
  ];
  const [status, setStatus] = useState(statusFilter[0]);
  const {
    currentProfileImg,
    regions,
    countries,
    states,
    fellowData,
    fieldsOfWork,
    searchKeyword,
    countriesInJson,
  } = useGlobalState();
  const [searchDetails, setSearchDetails] = useState({
    region: null,
    country: null,
    state: null,
    city: "",
    seekingCollaboration: false,
    topics: [],
    searchKeyword: "",
    status:
      fellowData.data === undefined
        ? ""
        : fellowData.data.attributes.field_fd_profile_status,
  });
  const [advancedFilter, setAdvancedFilter] = useState(false);
  const [selectedWorkFeilds, setSelectedWorkFields] = useState([]);
  const [filteredfellows, setFilteredFellows] = useState([]);
  const [collaboratingFellows, setCollaboratingFellows] = useState([]);
  const [saveSearch, setSaveSearch] = useState(true); //for saveSearchIcon
  const [tileData, setNoTileData] = useState({ title: "", isAbsent: false });
  const location = useLocation();
  const globalDispatch = useGlobalDispatch();
  const history = useHistory();
  const {
    addFellowToContactList,
    removeFellowFromContactList,
    setContactList,
    contactList,
    loaders,
  } = props;
  const { contactLoader } = loaders;
  const [pageOffset, setPageOffset] = useState(0);
  const [isLoading, setLoader] = useState(false);
  //changes for popup of save search
  const [saveSearchDataList, setSaveSearchDataList] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchLabel, setSearchLabel] = useState("");
  const [fellowCount, setFellowCount] = useState("");

  const onStatusChange = async (value) => {
    setStatus(value);
    setLoader(true);
    if (value && value.isCollaborating) {
      setSearchDetails((state) => ({
        ...state,
        seekingCollaboration: true,
      }));
      searchFellow(pageOffset, { seekingCollaboration: true }).then(
        (res) => {
          setFilteredFellows(res);
          setCollaboratingFellows(res);
          setStatus(statusFilter[1]);
          setLoader(false);
          setPageOffset((pageOffset) => pageOffset + 10);
          setFellowCount(res.meta.count);
        }
      );
      return;
    } else {
      searchFellow(pageOffset).then((res) => {
        if (res) {
          setLoader(false);
          setPageOffset((pageOffset) => pageOffset + 10);
          setFilteredFellows(res);
          setFellowCount(res.meta.count);
        }
      });
    }
  };

  const setLocationDetails = (value, name) => {
    setSearchDetails((state) => ({ ...state, [name]: value }));
    if (name === "country" && value) {
      globalDispatch({ type: "setAllStates", payload: value.subdivisions });
    }
    if (name === "country" && !value) {
      setSearchDetails((state) => ({ ...state, state: null, city: "" }));
    }
  };
  useEffect(() => {
    if (location.state.searchData) {
      globalDispatch({
        type: "setSearchKeyword",
        payload: location.state.searchData?.searchKeyword,
      });
      setSelectedWorkFields(location.state.searchData?.topics);
      setSearchDetails((state) => ({
        ...state,
        region: location.state.searchData?.region,
        country: location.state.searchData?.country,
        state: location.state.searchData?.state,
        city: location.state.searchData?.city,
        topics: location.state.searchData?.topics,
        searchKeyword: location.state.searchData?.searchKeyword,
      }));
      setLoader(true);
      submitSearch(location.state.searchData, true);
      setLoader(false);
      return; //api call for search result
    }
    //changes for save search
    var list = [];
    let id = getLocalStorage("profile_uuid");
    getFellowProfile(id).then((res) => {
      if (res) {
        list = res.data.attributes.field_fd_saved_searches;
        setSaveSearchDataList(list);
      }
    });
    //end changes
    if (!fieldsOfWork?.length) {
      getAllWorkFields().then((res) => {
        if (res)
          globalDispatch({
            type: "setListData",
            payload: { name: "fieldsOfWork", value: res.data || [] },
          });
      });
    }

    //end changes
    if (!regions?.length) {
      getAllRegions().then((res) => {
        if (res)
          globalDispatch({
            type: "setListData",
            payload: { name: "regions", value: res.data || [] },
          });
      });
    }

    if (!location?.state) {
      setLoader(true);
      searchFellow(0, {}).then((res) => {
        if (res) {
          setFilteredFellows(res);
          setLoader(false);
          setPageOffset((pageOffset) => pageOffset + 10);
          setFellowCount(res.meta.count);
        }
      });
    }

    return function cleanUp() {
      globalDispatch({ type: "setSearchKeyword", payload: "" });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsOfWork, location, regions, globalDispatch]);

  useEffect(() => {
    setLoader(true);
    if (location && location.state && fellowData) {
      switch (location?.state?.searchby) {
        case "city":
          if (
            fellowData?.data?.attributes?.field_fd_fellow_location?.locality
          ) {
            let locationDetails = {
              city: fellowData.data.attributes.field_fd_fellow_location
                .locality,
            };
            let country =
              fellowData?.data && countriesInJson
                ? {
                    code: fellowData.data.attributes.field_fd_fellow_location
                      .country_code,
                    title:
                      countriesInJson[
                        fellowData.data.attributes.field_fd_fellow_location
                          .country_code
                      ].name,
                  }
                : null;
            locationDetails["country"] = country;
            setSearchDetails((state) => ({
              ...state,
              city:
                fellowData.data.attributes.field_fd_fellow_location.locality ||
                "",
              country: country,
            }));
            searchFellow(pageOffset, locationDetails).then((res) => {
              setFilteredFellows(res);
              setPageOffset((pageOffset) => pageOffset + 10);
              setLoader(false);
              setFellowCount(res.meta.count);
            });
            return;
          }
          setLoader(false);
          setNoTileData({ title: "city", isAbsent: true });
          break;
        case "topics":
          if (fellowData?.data?.relationships?.field_topics?.data) {
            const list = getRelationshipMappings(
              fellowData.data.relationships.field_topics.data,
              fellowData?.included
            );
            setSelectedWorkFields(list);
            searchFellow(pageOffset, { topics: list }).then((res) => {
              setFilteredFellows(res);
              setLoader(false);
              setFellowCount(res.meta.count);
            });
            return;
          }
          setLoader(false);
          setNoTileData({ title: "topics", isAbsent: true });
          break;
        case "country":
          if (
            fellowData?.data?.attributes?.field_fd_fellow_location
              ?.country_code &&
            countriesInJson
          ) {
            setSearchDetails((state) => ({
              ...state,
              country: fellowData.data
                ? {
                    code: fellowData.data.attributes.field_fd_fellow_location
                      .country_code,
                    title:
                      countriesInJson[
                        fellowData.data.attributes.field_fd_fellow_location
                          .country_code
                      ].name,
                  }
                : null,
            }));
            searchFellow(pageOffset, {
              country: {
                code:
                  fellowData.data &&
                  fellowData.data.attributes.field_fd_fellow_location
                    .country_code,
              },
            }).then((res) => {
              setFilteredFellows(res);
              setPageOffset((pageOffset) => pageOffset + 10);
              setLoader(false);
              setFellowCount(res.meta.count);
            });
            return;
          }
          setLoader(false);
          setNoTileData({ title: "country", isAbsent: true });
          break;
        case "randomsearch":
          submitSearch();
          break;
        case "oneFieldOfWork":
          if (location?.state?.workField) {
            setSelectedWorkFields([location?.state?.workField]);
            searchFellow(pageOffset, {
              topics: [location?.state?.workField],
            }).then((res) => {
              setFilteredFellows(res);
              setPageOffset((pageOffset) => pageOffset + 10);
              setLoader(false);
              setFellowCount(res.meta.count);
            });
            return;
          }
          setLoader(false);
          break;
        case "seeking-collaboration":
          setSearchDetails((state) => ({
            ...state,
            seekingCollaboration: true,
          }));
          searchFellow(pageOffset, { seekingCollaboration: true }).then(
            (res) => {
              setFilteredFellows(res);
              setCollaboratingFellows(res);
              setStatus(statusFilter[1]);
              setLoader(false);
              setPageOffset((pageOffset) => pageOffset + 10);
              setFellowCount(res.meta.count);
            }
          );
          break;
        default:
          setLoader(false);
          return;
      }
    } else {
      searchFellow(pageOffset).then((res) => {
        if (res) {
          setLoader(false);
          setPageOffset((pageOffset) => pageOffset + 10);
          setFilteredFellows(res);
          setFellowCount(res.meta.count);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleSaveSearch = (
    searchKeyword,
    searchDetails,
    selectedWorkFeilds
  ) => {
    let temp = [];
    if (searchDetails?.country?.subdivisions) {
      delete searchDetails.country.subdivisions;
    }
    searchDetails.searchKeyword = searchKeyword;
    searchDetails.topics = selectedWorkFeilds;

    saveSearchDataList.push(JSON.stringify(searchDetails));
    saveSearches(saveSearchDataList).then((res) => {
      if (res) {
        temp = res.data.attributes.field_fd_saved_searches;
        setSaveSearchDataList(temp);
        toast.success("Saved!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    });
  };
  const submitSearch = (searchData, calledFormSearchList) => {
    setLoader(true);
    let newSearchDetails = { ...searchDetails };
    let searchKeywordLabel = "";
    let searchTopicsLabel = "";
    let topics = [];
    if (calledFormSearchList) {
      newSearchDetails = searchData;
    } else {
      newSearchDetails.searchKeyword = searchKeyword;
      newSearchDetails.topics = selectedWorkFeilds;
      setSearchDetails(newSearchDetails);
      setStatus(statusFilter[0]);
    }

    searchKeywordLabel = newSearchDetails?.searchKeyword
      ? '"' + newSearchDetails.searchKeyword + '"'
      : "";
    topics = newSearchDetails?.topics ? newSearchDetails?.topics : [];

    topics.forEach((item, index, array) => {
      if (index === 0) {
        searchTopicsLabel = searchTopicsLabel + item.attributes.name;
      } else if (index === array.length - 1) {
        searchTopicsLabel = searchTopicsLabel + " or " + item.attributes.name;
      } else {
        searchTopicsLabel = searchTopicsLabel + ", " + item.attributes.name;
      }
    });

    let searchCityLabel = newSearchDetails?.city ? newSearchDetails.city : "";
    let searchStateLabel = newSearchDetails?.state?.title
      ? newSearchDetails.state.title
      : "";
    let searchCountryLabel = newSearchDetails?.country?.title
      ? newSearchDetails.country.title
      : "";
    let searchRegionLabel = "";
    if (newSearchDetails?.region?.id) {
      regions.some((item) => {
        if (item.id === newSearchDetails.region.id) {
          searchRegionLabel = item.attributes.name;
          return true;
        } else {
          return false;
        }
      });
    }
    let searchLocationArray = [
      searchCityLabel,
      searchStateLabel,
      searchCountryLabel,
      searchRegionLabel,
    ].filter((a) => a);

    let searchLocationLabel = "";
    searchLocationLabel =
      searchLocationArray.length > 0
        ? (searchLocationLabel = searchLocationArray.join(", "))
        : "";

    if (searchKeywordLabel || searchLocationLabel) {
      searchTopicsLabel = searchTopicsLabel
        ? ' and Works In "' + searchTopicsLabel + '"'
        : "";
    } else {
      searchTopicsLabel = searchTopicsLabel
        ? 'Works In "' + searchTopicsLabel + '"'
        : "";
    }

    if (searchKeywordLabel) {
      searchLocationLabel = searchLocationLabel
        ? ' In "' + searchLocationLabel + '"'
        : "";
    } else {
      searchLocationLabel = searchLocationLabel
        ? 'In "' + searchLocationLabel + '"'
        : searchLocationLabel;
    }

    let searchLabel =
      searchKeywordLabel + searchLocationLabel + searchTopicsLabel;

    setSearchLabel(searchLabel);

    searchFellow(0, newSearchDetails).then((res) => {
      setFilteredFellows(res);
      setFellowCount(res.meta.count);
      setPageOffset(10);
      setLoader(false);
    });
  };

  const setRandomSearchText = (value) => {
    globalDispatch({ type: "setSearchKeyword", payload: value });
  };

  const handleKeyDown = (e) => {
    var keycode = e.keyCode || e.which || 0;
    if (e.key === "Enter" || keycode === 13) {
      globalDispatch({ type: "setSearchKeyword", payload: e.target.value });
      submitSearch();
    }
  };

  const fetchMoreData = () => {
    searchFellow(pageOffset, searchDetails).then((res) => {
      if (res) {
        setFilteredFellows((state) => ({
          ...state,
          data: filteredfellows.data.concat(res.data),
          included: filteredfellows.included.concat(res.included),
          links: res.links,
        }));
        if (searchDetails?.seekingCollaboration) {
          setCollaboratingFellows((state) => ({
            ...state,
            data: collaboratingFellows.data.concat(res.data),
            included: collaboratingFellows.included.concat(res.included),
            links: res.links,
          }));
        }
        setPageOffset((pageOffset) => pageOffset + 10);
        setFellowCount(res.meta.count);
      }
    });
  };

  useEffect(() => {
    if (
      fellowData?.data?.relationships?.field_fd_contact_list?.data &&
      !contactList?.data?.length
    ) {
      const list = getRelationshipMappings(
        fellowData?.data?.relationships?.field_fd_contact_list?.data,
        fellowData.included
      );
      setContactList({ data: list || [], include: fellowData.included });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fellowData]);

  const [saveSearchNoticeFlag, setSaveSearchNoticeFlag] = useState(
    localStorage.saveSearchNoticeFlag
  );
  useEffect(() => {
    if (localStorage.saveSearchNoticeFlag === undefined) {
      setSaveSearchNoticeFlag(false);
    } else {
      setSaveSearchNoticeFlag(true);
    }
  }, []);

  const checkIfHasMoreDataToLoad = (array) => {
    if (array?.links?.next) return true;
    else return false;
  };

  const displayFellows = (fellows) => {
    return (
      <InfiniteScroll
        dataLength={fellows?.data?.length || 0}
        next={fetchMoreData}
        hasMore={checkIfHasMoreDataToLoad(fellows)}
        loader={Array(5)
          .fill(null)
          .map((item, index) => {
            return <SkeletonFellowCardLoader key={index} />;
          })}
        className="fellow-list"
      >
        {fellows?.data &&
          fellows.data.map((fellow, index) => {
            let isFellowExistInContact = false;
            for (let i = 0; i < contactList?.data?.length; i++) {
              if (contactList.data[i].id === fellow.id) {
                isFellowExistInContact = true;
                break;
              }
            }
            return (
              <FellowRow
                key={index}
                id={fellow?.id}
                nid={fellow?.attributes?.drupal_internal__nid}
                image={
                  getFellowImage(
                    fellows?.included,
                    fellow?.relationships?.field_media_profile_image?.data?.id
                  ) || currentProfileImg
                }
                name={fellow?.attributes?.title || ""}
                location={
                  fellow?.attributes &&
                  fellow?.attributes?.field_fd_fellow_location &&
                  getFellowLocation(
                    fellow?.attributes?.field_fd_fellow_location,
                    countriesInJson
                  )
                }
                method={isFellowExistInContact ? "DELETE" : "POST"}
                addFellowToContactList={addFellowToContactList}
                removeFellowFromContactList={removeFellowFromContactList}
                socialMedias={
                  fellow?.attributes?.field_other_social_media_links
                    ?.platform_values || []
                }
                email={fellow?.attributes?.field_email || ""}
                phone={fellow?.attributes?.field_phone || ""}
                smAccs={
                  fellow?.attributes?.field_other_social_media_links
                    ?.platform_values
                }
                imAccs={
                  fellow?.attributes?.field_fd_fellow_ims?.platform_values
                }
                contactPrefs={fellow?.attributes?.field_fd_contact_preferences}
                isLoading={
                  contactLoader?.id === fellow?.id
                    ? contactLoader.isLoading
                    : undefined
                }
                t={t}
              />
            );
          })}
      </InfiniteScroll>
    );
  };

  const noResults = () => {
    return (
      <div className="no-result">
        {searchKeyword ? (
          <h1 className="search-text">{`"${searchKeyword}"`}</h1>
        ) : null}
        <div>Sorry! There are no results matching your search.</div>
      </div>
    );
  };

  const noProfileDataFound = () => {
    const onSkip = () => {
      setNoTileData(false);
      submitSearch();
    };
    const onEdit = () => {
      history.push("/settings");
    };
    return (
      <div className="no-data-found">
        <p className="description">{`Please add your current ${tileData.title} in the profile to receive accurate information.`}</p>
        <div className="button-wrapper">
          <CustomButton onClick={onSkip} text="Skip" classes="skip btn" />
          <CustomButton
            onClick={onEdit}
            text={t("forms:profile.edit_button.label")}
            classes="edit btn"
          />
        </div>
      </div>
    );
  };
  const clearAllFilters = () => {
    setSearchDetails({ region: null, country: null, state: null, city: "" });
    setSelectedWorkFields([]);
    setRandomSearchText("");
  };
  return (
    <div className="search-results">
      <div className="filter-wrapper">
        <div className="search-filter-wrapper">
          <div className="search-title-wrap">
            <div className="title">{t("search:search_box.title")}</div>
            {advancedFilter && (
              <div className="clear-filter" onClick={clearAllFilters}>
                {t("search:search_box.advance_filters.clear_filter")}
              </div>
            )}
          </div>
          <div className="searchbar-wrapper">
            <div className="searchbar">
              <SearchIcon className="search-icon" />
              <label for="search" className="hidden">Search</label>
              <input
                name="searchText"
                type="text"
                placeholder={t("search:search_box.search_lable")}
                className="search-text"
                value={searchKeyword}
                onChange={(e) => setRandomSearchText(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
                id="search"
              />
            </div>
          </div>
          {advancedFilter && (
            <div className="advanced-filter-wrapper">
              <div className="location">
                <div className="filter-title">
                  {t("search:search_box.advance_filters.location")}
                </div>
                <form autoComplete="no">
                  <div className="location-inputs-wrap">
                    <UserLocationForm
                      regions={regions}
                      countries={countries}
                      states={states}
                      setLocationDetails={setLocationDetails}
                      region={searchDetails.region && searchDetails.region}
                      country={searchDetails.country}
                      state={searchDetails.state}
                      city={searchDetails.city}
                      t={t}
                    />
                  </div>
                  <div className="work-fields-wrapper">
                    <h1 className="head-text">
                      {t("search:search_box.advance_filters.fields_of_work")}
                    </h1>
                    <Autocomplete
                      multiple
                      options={fieldsOfWork}
                      limitTags={1}
                      getOptionLabel={(option) => option.attributes.name}
                      value={selectedWorkFeilds}
                      onChange={(event, value) => {
                        setSelectedWorkFields(value);
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.drupal_internal__tid
                      }
                      name="work"
                      className="autocomplete-feilds work-input address-fields"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          label={t("profile.form_fields.fields_of_work.lable")}
                          placeholder={t(
                            "profile.form_fields.fields_of_work.lable"
                          )}
                        />
                      )}
                    />
                  </div>
                </form>
              </div>
            </div>
          )}
          <div className="search-title-wrap toggle-filters">
            <div
              className="collapse-filter-text"
              onClick={() => setAdvancedFilter((state) => !state)}
            >
              {advancedFilter
                ? t("search:search_box.advance_filters.collapse")
                : t("search:search_box.advance_filters.title")}
            </div>
            <CustomButton
              classes="search"
              text={t("search:search_box.search_button")}
              onClick={submitSearch}
            />
          </div>
        </div>
      </div>
      {tileData.isAbsent && noProfileDataFound()}
      <div className="content-wrapper">
        <div className="title-wrap">
          <div className="title">{t("search:filter_results.title")} <span className="total-items">{fellowCount} results</span></div>
          <div className="filter-dropdown">
            <Autocomplete
              disableClearable={true}
              value={status}
              options={statusFilter}
              onChange={(event, value) => {
                onStatusChange(value);
              }}
              name="status"
              className="autocomplete-feilds work-input"
              getOptionLabel={(option) => option.title}
              getOptionSelected={(option, value) =>
                option.title === value.title
              }
              renderInput={(params) => {
                params.inputProps.autoComplete = "new-password";
                return (
                  <TextField
                    {...params}
                    label={t("search:filter_results.status_lable")}
                    variant="filled"
                  />
                );
              }}
            />
          </div>
        </div>
        <div
          hidden={!saveSearch}
          className="saveSearchOutlinedIcon"
          onClick={() => {
            setSaveSearch(false);
            setOpen(true);
          }}
        >
          <span></span>
        </div>
        <div
          hidden={saveSearch}
          className="saveSearchFilledIcon"
          onClick={() => {
            setSaveSearch(true);
          }}
        >
          <span></span>
        </div>
        {!saveSearchNoticeFlag ? (
          <div className="no-data-found">
            <img
              src={saveSearchIcon}
              alt="icon"
              style={{ float: "left", margin: "1%" }}
            />
            <p className="description" style={{ display: "flow-root" }}>
              {t("search:save_search_welcome_message")}
            </p>
            {/* <div className="button-wrapper">
                <CustomButton
                  onClick={() => {
                    setSaveSearchNoticeFlag(true);
                    localStorage.setItem("saveSearchNoticeFlag", 1);
                  }}
                  text="Skip"
                  classes="skip btn" />
                <CustomButton
                  hidden={saveSearch}
                  onClick={() => {
                    setSaveSearchNoticeFlag(true);
                    localStorage.setItem("saveSearchNoticeFlag", 1);
                    setSaveSearch(true);
                    setOpen(true);
                  }}
                  text={"Save"}
                  classes="edit btn"
                />
              </div> */}
            <div className="button-wrapper">
              <CustomButton
                onClick={() => {
                  setSaveSearchNoticeFlag(true);
                  localStorage.setItem("saveSearchNoticeFlag", 1);
                }}
                text="DISMISS"
                classes="skip btn"
              />
              <CustomButton
                hidden={saveSearch}
                onClick={() => {
                  setSaveSearchNoticeFlag(true);
                  localStorage.setItem("saveSearchNoticeFlag", 1);
                  setSaveSearch(true);
                  setOpen(true);
                }}
                text={"SAVE SEARCH"}
                classes="edit btn saveSearch"
                style={{ color: "#4155EE" }}
              />
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {!isLoading &&
          (collaboratingFellows?.data?.length > 0 ||
            filteredfellows?.data?.length > 0) &&
          displayFellows(
            status?.isCollaborating ? collaboratingFellows : filteredfellows
          )}
        {isLoading && (
          <div className="fellow-list">
            {Array(5)
              .fill(null)
              .map((item, index) => {
                return <SkeletonFellowCardLoader key={index} />;
              })}
          </div>
        )}{" "}
        {!isLoading &&
          ((!collaboratingFellows?.data?.length &&
            !filteredfellows?.data?.length) ||
            (status?.isCollaborating && !collaboratingFellows?.data?.length)) &&
          noResults()}
      </div>
      <div style={{ height: "25%", width: "50%" }}>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px", // Set your width here
              },
            },
          }}
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "20px",
                lineHeight: "30px",
                letterSpacing: "0.15px",
                color: " #4155EE",
              }}
            >
              {searchLabel}
            </DialogContentText>
          </DialogContent>
          <div style={{ textAlign: "center", margin: "5%" }}>
            <Button
              variant="text"
              style={{ color: "rgba(0, 0, 0, 0.87)", marginRight: "10px" }}
              onClick={() => {
                setSaveSearch(true);
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "#4155EE" }}
              onClick={() => {
                handleSaveSearch(
                  searchKeyword,
                  searchDetails,
                  selectedWorkFeilds
                );
                setOpen(false);
                setSaveSearch(true);
              }}
              className="saveSearchClick"
            >
              save
            </Button>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default SearchResults;
