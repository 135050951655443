import React from "react";
import { profiles } from "../../services/constantValues";

const GlobalStateContext = React.createContext();
const GlobalDispatchContext = React.createContext();

let initialState = {
  currentProfileImg: "",
  userImage: "",
  locationDetails: {},
  email: "",
  fellowData: "",
  regions: [],
  countries: [],
  countriesInJson: {},
  states: [],
  city: "",
  searchKeyword: "",
  fieldsOfWork: [],
  interests: [],
  preferredLang: null,
  defaultCountryCode: "",
  notificationPrefs: [],
};

function globalReducer(state, action) {
  switch (action.type) {
    case "setDefaultprofile":
      let defaultProfile =
        profiles[Math.floor(Math.random() * profiles.length)];
      return {
        ...state,
        currentProfileImg: defaultProfile,
      };

    case "setCurrentProfileImg":
      return {
        ...state,
        userImage:
          action.payload ||
          profiles[Math.floor(Math.random() * profiles.length)],
      };

    case "setLocationDetails":
      return {
        ...state,
        locationDetails: action.payload,
      };

    case "setEmail":
      return {
        ...state,
        email: action.payload,
      };

    case "setUserData":
      return {
        ...state,
        fellowData: action.payload,
      };

    case "setAllRegions":
      return {
        ...state,
        regions: action.payload,
      };

    case "setAllCountries":
      return {
        ...state,
        countries: action.payload,
      };

    case "setAllStates":
      return {
        ...state,
        states: action.payload,
      };

    case "setSearchKeyword":
      return {
        ...state,
        searchKeyword: action.payload,
      };

    case "setListData":
      let data = { ...state };
      data[action.payload.name] = action.payload.value;
      return {
        ...data,
      };

    default:
      return { ...state };
  }
}

function GlobalProvider({ children }) {
  const [state, dispatch] = React.useReducer(globalReducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
}

function useGlobalState() {
  const context = React.useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error("useGlobalState must be used within a GlobalProvider");
  }
  return context;
}

function useGlobalDispatch() {
  const context = React.useContext(GlobalDispatchContext);
  if (context === undefined) {
    throw new Error("useGlobalDispatch must be used within a GlobalProvider");
  }
  return context;
}

export {
  GlobalProvider,
  useGlobalState,
  useGlobalDispatch,
  GlobalDispatchContext,
  GlobalStateContext,
  globalReducer,
};
