import "./App.scss";

import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import {
  addToContactList,
  removeFromContactList,
} from "./services/fellow.patchService";
import {
  getFellowProfile,
  getFellowsContactList,
} from "./services/fellow.getService";
import {
  useGlobalDispatch,
  useGlobalState,
} from "./components/context/globalContext";

import ContactInfo from "./components/onboardingScreens/contactDetails/contactInfo";
import ContactList from "./components/contactList/index";
import { ContactProvider } from "./components/context/contactContext";
import CookieBanner from "./components/basic-components/cookieBanner";
import Cookies from "js-cookie";
import Dashboard from "./components/dashboard";
import EmailVerification from "./components/login/codeVerification";
import ErrorAlert from "./components/basic-components/snackbar";
import FellowProfile from "./components/fellowProfile";
import FinishScreen from "./components/onboardingScreens/finishScreen/finishscreen";
import GetStarted from "./components/onboardingScreens/welcomeScreen/getStarted";
import Header from "./components/basic-components/header";
import Login from "./components/login";
import PageNotFound from "./components/errorScreens/pageNotFound/index";
import Policies from "./components/policyScreens";
import PolicyMethod from "./components/policyScreens/policyMethod";
import Profile from "./components/onboardingScreens/userProfile/profile";
import { ProfileProvider } from "./components/context/profileContext";
import SearchResults from "./components/searchResults/index";
import Settings from "./components/settings";
import SavedList from "./components/savedList";
import Status from "./components/onboardingScreens/userStatus/status";
import Sticky from "./components/onboardingScreens/userSticky/sticky";
import Support from "./components/supportScreen";
import TagManager from "react-gtm-module";
import { allowedLaguages } from "./services/constantValues";
import { getFellowImage } from "./services/helper-functions";
import { getLocalStorage } from "./services/helper-functions";
import { useListings } from "./components/utils/useListings";
import { useTranslation } from "react-i18next";

function App() {
  const globalDispatch = useGlobalDispatch();
  const { preferredLang } = useGlobalState();
  const [isCookiePolicyAccepted, setCookieOn] = useState(undefined);
  const [isLogin, setIsLogin] = useState(false);
  const [hasEmailProvided, setHasEmailProvided] = useState(false);
  const { i18n } = useTranslation();
  const [contactList, setContactList] = useState({ data: [] });
  const [errors, setErrors] = useState({
    openSnackbar: false,
    msg: "",
    errorSeverity: "",
  });
  const [loaders, setLoader] = useState({
    contactLoader: { id: undefined, isLoading: false },
  });
  const [lang, setLang] = useState(preferredLang);

  const setListingsLoader = (name, value) => {
    setLoader((state) => ({ ...state, [name]: value }));
  };

  //try to call only once at dashboard component
  const {
    mostActiveFellows,
    recentlyActiveFellows,
    activityFeedData,
    fellowsSeekingForCollaboration,
    newlyActiveFellows,
    setActivityFeedData,
  } = useListings(setListingsLoader);

  // Set language Direction
  document.documentElement.dir = i18n.dir();

  useEffect(() => {
    const setGTM = () => {
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
      };
      TagManager.initialize(tagManagerArgs);
    };
    if (isCookiePolicyAccepted === true) setGTM();
  }, [isCookiePolicyAccepted]);

  useEffect(() => {
    globalDispatch({ type: "setDefaultprofile", payload: {} });
    let token = getLocalStorage("token");
    if (token) {
      setListingsLoader("isFellowProfileLoading", true);
      updateLogin(true);
      const getprofile = async () => {
        let id = await getLocalStorage("profile_uuid");
        getFellowProfile(id)
          .then(async (res) => {
            if (res) {
              let image = await getFellowImage(
                res?.included,
                res?.data?.relationships?.field_media_profile_image?.data?.id
              );
              globalDispatch({ type: "setCurrentProfileImg", payload: image });
              globalDispatch({
                type: "setEmail",
                payload: res?.data?.attributes?.field_email || "",
              });
              globalDispatch({ type: "setUserData", payload: res });
              if (res?.data?.attributes?.field_fd_ui_language) {
                i18n.changeLanguage(
                  res?.data?.attributes?.field_fd_ui_language
                );
              }
              setListingsLoader("isFellowProfileLoading", false);
            }
          })
          .catch((err) => console.log(err));
      };
      getprofile();
    }

    //set cookie policy
    let cookie = Cookies.get("isCookiePolicyAccepted");
    if (cookie === undefined) setUserConsent(undefined);
    else setUserConsent(cookie === "true");

    //check for default lang preferance on first load
    let language = i18n?.language;
    let defaultLang;
    defaultLang = allowedLaguages.find((lang) => lang.code === language);
    if (defaultLang) setLang(defaultLang || null);
    else {
      defaultLang = allowedLaguages.find((lang) => lang.code === "en");
      setLang(defaultLang || null);
    }
    globalDispatch({
      type: "setListData",
      payload: { name: "preferredLang", value: defaultLang || null },
    });
  }, [globalDispatch, i18n]);

  const updateLogin = (value) => {
    setIsLogin(value);
  };

  const UpdateEmailProvided = (value) => {
    setHasEmailProvided(value);
  };

  const setUserConsent = (value) => {
    setCookieOn(value);
  };

  //update language locally
  const updateFDLanguage = (lang) => {
    setLang(lang);
    if (lang && preferredLang && lang?.code !== preferredLang?.code) {
      i18n.changeLanguage(lang.code);
      globalDispatch({
        type: "setListData",
        payload: { name: "preferredLang", value: lang },
      });
    }
  };

  const addFellowToContactList = (id) => {
    setLoader((state) => ({
      ...state,
      contactLoader: { id, isLoading: true },
    }));
    if (id) {
      addToContactList(id).then((res) => {
        if (res) {
          setErrors({
            openSnackbar: true,
            errorSeverity: res?.status ? "success" : "error",
            msg: res.msg,
          });
          setLoader((state) => ({
            ...state,
            contactLoader: { id, isLoading: false },
          }));
          getFellowsContactList().then((res) => {
            res && setContactList({ data: res?.data, include: res.included });
          });
        }
      });
    }
  };

  const removeFellowFromContactList = (id) => {
    setLoader((state) => ({
      ...state,
      contactLoader: { id, isLoading: true },
    }));
    if (id) {
      removeFromContactList(id).then((res) => {
        if (res) {
          setErrors({
            openSnackbar: true,
            errorSeverity: res?.status ? "success" : "error",
            msg: res.msg,
          });
          setLoader((state) => ({
            ...state,
            contactLoader: { id, isLoading: false },
          }));
          getFellowsContactList().then((res) => {
            res && setContactList({ data: res?.data, include: res.included });
          });
        }
      });
    }
  };

  const setSnackbar = (value) => {
    setErrors((errors) => ({ ...errors, openSnackbar: value }));
  };

  return (
    <>
      {!isLogin ? (
        <Router>
          <Header updateLogin={updateLogin} isLogin={isLogin} />
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => (
                <Login
                  {...props}
                  UpdateEmailProvided={UpdateEmailProvided}
                  lang={lang}
                  updateFDLanguage={updateFDLanguage}
                />
              )}
            />
            {hasEmailProvided && (
              <Route
                path="/emailverification"
                render={(props) => (
                  <EmailVerification
                    {...props}
                    updateLogin={updateLogin}
                    lang={lang}
                    updateFDLanguage={updateFDLanguage}
                    setListingsLoader={setListingsLoader}
                  />
                )}
              />
            )}
            <Route
              path="/policies/:nid"
              render={(props) => <PolicyMethod {...props} />}
            />
            <Route path="*" component={PageNotFound} />
          </Switch>
          <CookieBanner
            isCookiePolicyAccepted={isCookiePolicyAccepted}
            setUserConsent={setUserConsent}
          />
        </Router>
      ) : (
        <Router>
          <Header updateLogin={updateLogin} isLogin={isLogin} />
          <Switch>
            {window.location.pathname === "/" && <Redirect to="/dashboard" />}
            <Route path="/onboarding/getstarted">
              <ContactProvider>
                <ProfileProvider>
                  <GetStarted
                    lang={lang}
                    updateFDLanguage={updateFDLanguage}
                    loaders={loaders}
                  />
                </ProfileProvider>
              </ContactProvider>
            </Route>
            <Route path="/onboarding/contactinfo">
              <ContactProvider>
                <ContactInfo
                  lang={lang}
                  updateFDLanguage={updateFDLanguage}
                  loaders={loaders}
                />
              </ContactProvider>
            </Route>
            <Route path="/onboarding/profile">
              <ProfileProvider>
                <Profile lang={lang} updateFDLanguage={updateFDLanguage} />
              </ProfileProvider>
            </Route>
            <Route path="/onboarding/sticky">
              <ProfileProvider>
                <Sticky lang={lang} updateFDLanguage={updateFDLanguage} />
              </ProfileProvider>
            </Route>
            <Route path="/onboarding/status">
              <ProfileProvider>
                <Status lang={lang} updateFDLanguage={updateFDLanguage} />
              </ProfileProvider>
            </Route>
            <Route path="/onboarding/finish">
              <ProfileProvider>
                <FinishScreen lang={lang} updateFDLanguage={updateFDLanguage} />
              </ProfileProvider>
            </Route>

            <Route path="/dashboard">
              <ProfileProvider>
                <Dashboard
                  mostActiveFellows={mostActiveFellows}
                  recentlyActiveFellows={recentlyActiveFellows}
                  activityFeedData={activityFeedData}
                  setActivityFeedData={setActivityFeedData}
                  fellowsSeekingForCollaboration={
                    fellowsSeekingForCollaboration
                  }
                  newlyActiveFellows={newlyActiveFellows}
                  updateLogin={updateLogin}
                  loaders={loaders}
                />
              </ProfileProvider>
            </Route>
            <Route path="/settings">
              <ContactProvider>
                <ProfileProvider>
                  <Settings loaders={loaders} />
                </ProfileProvider>
              </ContactProvider>
            </Route>
            <Route path="/savedList">
              <ContactProvider>
                <ProfileProvider>
                  <SavedList
                    loaders={loaders}
                    contactList={contactList}
                    removeFellowFromContactList={removeFellowFromContactList}
                    setContactList={setContactList}
                  />
                </ProfileProvider>
              </ContactProvider>
            </Route>
            <Route path="/fellowprofile">
              <ContactProvider>
                <ProfileProvider>
                  <FellowProfile
                    addFellowToContactList={addFellowToContactList}
                    removeFellowFromContactList={removeFellowFromContactList}
                    contactList={contactList}
                    setContactList={setContactList}
                    loaders={loaders}
                  />
                </ProfileProvider>
              </ContactProvider>
            </Route>
            <Route
              path="/fellow/:nid/:title"
              render={(props) => (
                <ContactProvider>
                  <ProfileProvider>
                    <FellowProfile
                      addFellowToContactList={addFellowToContactList}
                      removeFellowFromContactList={removeFellowFromContactList}
                      contactList={contactList}
                      setContactList={setContactList}
                      loaders={loaders}
                      {...props}
                    />
                  </ProfileProvider>
                </ContactProvider>
              )}
            ></Route>
            <Route path="/contactlist">
              <ContactList
                contactList={contactList}
                removeFellowFromContactList={removeFellowFromContactList}
                setContactList={setContactList}
                loaders={loaders}
              />
            </Route>
            <Route path="/search">
              <SearchResults
                addFellowToContactList={addFellowToContactList}
                removeFellowFromContactList={removeFellowFromContactList}
                contactList={contactList}
                setContactList={setContactList}
                loaders={loaders}
              />
            </Route>
            <Route path="/support">
              <Support />
            </Route>
            <Route
              path="/policies/:nid"
              render={(props) => <PolicyMethod {...props} />}
            />
            <Route path="/policies">
              <Policies />
            </Route>
            <Route path="*" component={PageNotFound} />
          </Switch>
          <CookieBanner
            isCookiePolicyAccepted={isCookiePolicyAccepted}
            setUserConsent={setUserConsent}
          />
        </Router>
      )}
      <ErrorAlert
        openSnackbar={errors.openSnackbar}
        msg={errors.msg}
        errorSeverity={errors.errorSeverity}
        setSnackbar={setSnackbar}
      />
    </>
  );
}

export default App;
