import "./getStarted.scss";

import React, { useState } from "react";

import CustomButton from "../../basic-components/button";
import ErrorAlert from "../../basic-components/snackbar";
import LanguagePicker from "../../basic-components/languagePicker";
import SkeletonFellowCardLoader from "../../Loaders/skeletonFellowCardLoader";
import { updatePrefrredLanguage } from "../../../services/fellow.patchService";
import { useGlobalState } from "../../context/globalContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const GetStarted = (props) => {
  const { userImage, currentProfileImg, fellowData } = useGlobalState();
  const { t } = useTranslation(["forms"]);
  const { lang, updateFDLanguage, loaders } = props;
  const { isFellowProfileLoading } = loaders;
  const history = useHistory();
  const [errors, setErrors] = useState({
    errorSeverity: "",
    openSnackbar: false,
    msg: "",
  });
  const [loader, setLoader] = useState(false);
  const goNext = () => {
    setLoader(true);
    if (lang) {
      updatePrefrredLanguage(lang.code)
        .then((res) => {
          setLoader(false);
          history.push("/onboarding/contactinfo");
        })
        .catch((err) => {
          console.log("Error in saving language preferance :", err);
          setLoader(false);
          setErrors((errors) => ({
            ...errors,
            openSnackbar: true,
            errorSeverity: "error",
            msg: "Could not save the language preferance.",
          }));
        });
    }
  };
  const setSnackbar = (value) => {
    setErrors((errors) => ({ ...errors, openSnackbar: value }));
  };

  return (
    <div className="getstarted-container">
      {!isFellowProfileLoading ? (
        <>
          <div className="lang">
            <LanguagePicker setLang={updateFDLanguage} lang={lang} />
          </div>
          <div className="getstarted">
            <img
              src={userImage || currentProfileImg}
              alt="user icon"
              className="avt-img"
            />
            <div className="wrapper">
              <h1 className="head-text">
                {t("onboarding_welcome.title", {
                  name: fellowData?.data?.attributes?.title || "",
                })}
              </h1>
              <p className="content">
                {t("onboarding_welcome.description1")}
                {t("onboarding_welcome.description2")}
              </p>
            </div>
          </div>
          <div className="footer">
            <div className={`wrapper`}>
              <CustomButton
                classes="btn without-next-prev"
                text={t("onboarding_welcome.save_button")}
                variant="contained"
                onClick={goNext}
                isLoading={loader}
              />
              {
                <ul>
                  {/* changes for pagination */}
                  {Array(5)
                    .fill(null)
                    .map((i, index) => {
                      return (
                        <li
                          className={`${index === 0 ? "active" : ""}`}
                          key={index}
                        ></li>
                      );
                    })}
                </ul>
              }
            </div>
          </div>
          <ErrorAlert
            openSnackbar={errors.openSnackbar}
            msg={errors.msg}
            errorSeverity={errors.errorSeverity}
            setSnackbar={setSnackbar}
          />
        </>
      ) : (
        <SkeletonFellowCardLoader />
      )}
    </div>
  );
};

export default GetStarted;
