import "./dashboard.scss";
import { Link, useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  getFellowImage,
  getFellowLocation,
  addMetaTag,
  removeMetaTag,
} from "../../services/helper-functions";
import { useGlobalDispatch, useGlobalState } from "../context/globalContext";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import { ReactComponent as CountryFellows } from "../../images/fellows-in-us.svg";
import CustomButton from "../basic-components/button";
import FellowColTeaser from "../basic-components/fellowcolteaser";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ReactComponent as NearMe } from "../../images/nearme.svg";
import { ReactComponent as SearchIcon } from "../../images/search-icon.svg";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { ReactComponent as SimilarFeildFellows } from "../../images/fellows-similar-feilds.svg";
import SkeletonColLoader from "../Loaders/skeletonColLoader";
import SkeletonRowLoader from "../Loaders/skeletonRowLoader";
import Textfield from "../basic-components/textfield";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import promptImage from "../../images/Open Peeps Bust.svg";
import cneImageAI from "../../images/imgAI.jpg";
import promptImage1 from "../../images/Image.png";
import acrcImage from "../../images/acrc.png";
import actFeedImage from "../../images/Group 104.png";
import Modal from "../../components/dashboard/activityFeed/Modal";
import EditModal from "./activityFeed/EditModal";
import DisplayWorkFields from "../basic-components/displayworkfields";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ContactMethods from "../basic-components/contactMethods";
import iconIOS from "../../images/upload.png";
import {
  removeActivityPost,
  saveThePost,
} from "../../services/fellow.patchService";
import { getActivityFeed } from "../../services/fellow.getService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Avatar from "@material-ui/core/Avatar";
import { Parser } from "html-to-react";

toast.configure();
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#6AD29B",
  },
}))(LinearProgress);
//--for PWA prompt--
var deferredPrompt; // eslint-disable-line
const Dashboard = (props) => {
  const { currentProfileImg, fellowData, countriesInJson } = useGlobalState();
  const history = useHistory();
  const [bio, setBio] = useState();
  const {
    mostActiveFellows,
    recentlyActiveFellows,
    activityFeedData,
    fellowsSeekingForCollaboration,
    newlyActiveFellows,
    loaders,
    setActivityFeedData,
  } = props;
  const {
    areMostActiveFellowsLoading,
    areRecentActiveFellowsLoading,
    areActivityFeedDataLoading,
    areSeekingCollaborationFellowsLoading,
    areNewlyActiveFellowsLoading,
  } = loaders;
  const { t } = useTranslation(["headerAndDashboard"]);
  const globalDispatch = useGlobalDispatch();
  const [loader, setLoader] = useState(false);
  const [loaderForDelete, setLoaderForDelete] = useState(false);
  const [loaderForSavePost, setLoaderForSavePost] = useState(false);

  //for icon button
  // const [anchorEl, setAnchorEl] = useState([]);
  const onSave = () => {};
  const onContactAdd = () => {};
  //changes for contact fellow
  const [openContactFellow, setOpenContactFellow] = useState(false);
  const onClose = () => {
    setOpenContactFellow((state) => !state);
  };
  const setSearch = (filterBy) => {
    history.push({ pathname: "/search", state: { searchby: filterBy } });
  };
  const handleKeyDown = (e) => {
    var keycode = e.keyCode || e.which || 0;
    if (e.key === "Enter" || keycode === 13) {
      globalDispatch({ type: "setSearchKeyword", payload: e.target.value });
      setSearch("randomsearch");
    }
  };
  const [flag, setFlag] = useState( // eslint-disable-line
    window.screen.availWidth > 480 ? true : false
  );
  const [modalOpen, setModalOpen] = useState(false); //for open model box
  const [editModalOpen, setEditModalOpen] = useState(false); //for edit modal box
  const [editPostId, setEditPostId] = useState([]); //editpost
  const [pageLimit, setPageLimit] = useState(2);
  const [loadMoreStatus, setLoadMoreStatus] = useState(false);
  const [overlayHint, setOverlayHint] = useState(false);
  const [overlayWhatsNew, setOverlayWhatsNew] = useState(
    localStorage.whatsnewPopupNoticeFlag === undefined ? true : false
  );

  const htmlParser = new Parser();
  const [whatsNewBookDescription, setWhatsNewBookDescription] = useState(
    t("whats_new.book_description")
  );

  // let data = [];
  let imagePath = [];
  let fellow_drupal_internal_id = "";
  let smAccsData = "";
  let imAccsData = "";
  let contactPrefsData = "";
  let emailData = "";
  let phoneData = "";
  useEffect(() => {
    // var temp = navigator.userAgent.match(/chrome|chromium|crios|safari|iphone|ipad|ipod/i);
    // if (temp == undefined) {
    //   setCheckBrowser(false);
    // } else if (temp[0] === "Chrome" || temp[0] === "Safari") {
    //   setCheckBrowser(true);
    // }
    // if (localStorage.count === "undefined") {
    //   setCount(true);
    // } else {
    //   setCount(false);
    // }
    return () => {
      localStorage.setItem("count", 1);
    };
  }, []);

  // useEffect(() => {
  //   window.addEventListener("beforeinstallprompt", (e) => {
  //     // Prevent the mini-infobar from appearing on mobile
  //     e.preventDefault();

  //     // Stash the event so it can be triggered later.
  //     deferredPrompt = e;
  //     // Update UI notify the user they can install the PWA
  //     setInstallable(true);
  //   });

  //   window.addEventListener("appinstalled", () => {
  //     // Log install to analytics
  //     console.log("INSTALL: Success");
  //   });

  //   // Detects if device is on iOS
  //   const isIos = () => {
  //     // const userAgent = window.navigator.userAgent.toLowerCase();
  //     // return /iphone|ipad|ipod/.test( userAgent );
  //     return [
  //       'iPad Simulator',
  //       'iPhone Simulator',
  //       'iPod Simulator',
  //       'iPad',
  //       'iPhone',
  //       'iPod'
  //     ].includes(navigator.platform)
  //     // iPad on iOS 13 detection
  //     || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  //   }
  //   // Detects if device is in standalone mode
  //   const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  //   // Checks if should display install popup notification:
  //   if (isIos() && !isInStandaloneMode()) {
  //     setShowInstallMessage({ showInstallMessage: true });
  //   }
  // }, [installable, setInstallable]);

  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    // For Android.
    let deferredPrompt; // Variable should be out of scope of addEventListener method
    window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();	// This prevents default chrome prompt from appearing
        deferredPrompt = e;	 // Save for later
        let infoBar = document.querySelector('#custom-info-bar');
        if (infoBar) {
          infoBar.style.display = '';
          let installBtn = document.querySelector('#custom-install-button');
          installBtn.addEventListener('click', (e) => {
            //This prompt window
            deferredPrompt.prompt();
            // Here we wait for user interaction
            deferredPrompt.userChoice
            .then((choiceResult) => {
              console.log(choiceResult);
              if (choiceResult.outcome === 'accepted') {
                let infoBar = document.querySelector('#custom-info-bar');
                infoBar.style.display = 'none'; // Hide info bar
                deferredPrompt = null; // not need anymore
              }
            });
          });
        }
    });

    // For IOS.
    // Detects if device is on iOS
    const isIosDevice = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod|macintosh/.test(userAgent);
    }

    // Detects if device is on iOS
    const isSafari = () => {
      return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;
    }

    if (isIosDevice() && isSafari()) {
      setIsIOS(true);
    }

  }, [setIsIOS, isIOS]);

  useEffect(() => {
    var temp = [];
    getActivityFeed().then((res) => {
      if (res) {
        for (var i = 0; i < res.length; i++) {
          temp.push(false);
        }
        // setAnchorEl(temp);
        setActivityFeedData(res);
      }
    });

    // Cache metatag add for dashboard page only.
    addMetaTag("pragma","no-cache")
    addMetaTag("expires","0")
    addMetaTag("cache-control","no-cache")

    // Cleanup function (Once we leave the page we removed the metatag for other pages).
    return () => {
      removeMetaTag("pragma")
      removeMetaTag("expires")
      removeMetaTag("cache-control")
    };
  }, []);

  //Function call for show PWA Prompt
  // const handleInstallClick = (e) => {
  //   // Hide the app provided install promotion
  //   setInstallable(false);
  //   if (deferredPrompt !== undefined) {
  //     // Show the install prompt
  //     deferredPrompt.prompt();
  //     // Wait for the user to respond to the prompt
  //     deferredPrompt.userChoice.then((choiceResult) => {
  //       if (choiceResult.outcome === "accepted") {
  //         console.log("User accepted the install prompt");
  //       } else {
  //         console.log("User dismissed the install prompt");
  //       }
  //     });
  //   } else {
  //     deferredPrompt = null;
  //   }
  // };

  const handleDeletePost = (id) => {
    setLoaderForDelete(true);
    if (id === undefined || id === null || id === "") {
    } else {
      // setAnchorEl([]);
      var temp = [];
      removeActivityPost(id).then((res) => {
        getActivityFeed().then((res) => {
          if (res) {
            setLoaderForDelete(false);
            for (var i = 0; i < res.length; i++) {
              temp.push(false);
            }
            // setAnchorEl(temp);
            setActivityFeedData(res);
            //  setOpenModal(false);
          }
        });
        if (res.status) {
          setOverlay({
            overlayId: "",
            status: false,
          });
          setOverlay1({
            overlayId1: "",
            status1: false,
            title: "",
          });
          toast.success(res.msg, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        } else {
          toast.error(res.msg, { position: toast.POSITION.BOTTOM_CENTER });
        }
      });
    }
  };
  const handleEditPost = (fellow) => {
    setOverlay1({
      overlayId1: "",
      status1: false,
      title: "",
    });
    setEditPostId(fellow);
    setEditModalOpen(true);
  };
  const stopProp = (e) => {
    e.stopPropagation();
  };
  const [overlay, setOverlay] = useState({
    overlayId: "",
    status: false,
  });
  const [overlay1, setOverlay1] = useState({
    overlayId1: "",
    status1: false,
    title: "",
  });
  const [errorPopup, setErrorPopup] = useState(false);
  const LoginOverlay = ({ removeOverlay }) => {
    return (
      <div className="overlay_background" onClick={(e) => removeOverlay()}>
        <div
          className="overlay_card"
          style={{ width: flag ? "40%" : "80%" }}
          onClick={(e) => stopProp(e)}
        >
          <form>
            <h4 style={{ margin: "unset", color: "#4155ee" }}>
              {" "}
              {t("activity_feed.delete_post")}
            </h4>
            <p>
              {t("activity_feed.delete_text.line1")}
              <br />
              {t("activity_feed.delete_text.line2")}
            </p>
            <div>
              <Button
                variant="text"
                style={{
                  color: "slategray",

                  marginRight: "15px",
                }}
                onClick={() =>
                  setOverlay({
                    overlayId: "",
                    status: false,
                  })
                }
              >
                {t("activity_feed.cancel")}
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#4155ee",
                  marginLeft: "15px",
                }}
                onClick={() => {
                  handleDeletePost(overlay.overlayId);
                }}
              >
                {t("activity_feed.delete")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  };
  const LoginOverlay1 = ({ removeOverlay1 }) => {
    return (
      <div className="overlay_background" onClick={(e) => removeOverlay1()}>
        <div
          className="overlay_card"
          style={{
            width: flag ? "40%" : "80%",
            display: "block",
            padding: "22px",
            height: "auto",
          }}
          onClick={(e) => stopProp(e)}
        >
          {overlay1.title !== fellowData?.data?.attributes?.title ? (
            <div
              style={{
                width: "100%",
              }}
            >
              {/* <Button
                variant="outlined"
                style={{
                  color: "slategray",
                  borderColor: "#4a71ee",
                  marginBottom: "22px",
                  width: "100%",
                }}
                onClick={() => {
                  handleSavePost(overlay1.overlayId1);
                }}
              >
                {t("activity_feed.save_post")}
              </Button> */}
              <CustomButton
                onClick={() => {
                  handleSavePost(overlay1.overlayId1);
                }}
                style={{
                  color: "slategray",
                  borderColor: "#4a71ee",
                  marginBottom: "22px",
                  width: "100%",
                }}
                text={t("activity_feed.save_post")}
                variant="outlined"
                isLoading={loaderForSavePost}
              />
              <Button
                variant="contained"
                className='contact-fellow-analytics'
                style={{
                  backgroundColor: "#4155ee",
                  width: "100%",
                }}
                onClick={() => {
                  setOverlay1({
                    overlayId1: "",
                    status1: false,
                    title: "",
                  });
                  setOpenContactFellow(true);
                }}
              >
                {t("activity_feed.contact_fellow")}
              </Button>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                style={{
                  color: "slategray",
                  marginBottom: "22px",
                  width: "100%",
                  borderColor: "#4a71ee",
                }}
                onClick={() => {
                  // var temp = anchorEl[index];
                  // data[index] = temp ? false : true;
                  // setAnchorEl(data);
                  handleEditPost(overlay1.overlayId1);
                }}
              >
                {t("activity_feed.edit_post")}
              </Button>

              <CustomButton
                onClick={() => {
                  handleDeletePost(overlay1.overlayId1);
                }}
                style={{
                  backgroundColor: "#4155ee",
                  width: "100%",
                  color: "white",
                }}
                text={t("activity_feed.delete_post")}
                variant="contained"
                isLoading={loaderForDelete}
              />
            </div>
          )}
          <div
            style={{
              margin: "22px 0 0 0",
              borderTop: "1px solid #E0E0E0",
              paddingTop: "22px",
              textAlign: "right",
            }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#4155ee",
                width: "55%",
              }}
              onClick={() =>
                setOverlay1({
                  overlayId1: "",
                  status1: false,
                  title: "",
                })
              }
            >
              {t("activity_feed.cancel")}
            </Button>
          </div>
        </div>
      </div>
    );
  };
  const LoginOverlayHint = ({ removeOverlayHint }) => {
    return (
      <div className="overlay_background1" onClick={(e) => removeOverlayHint()}>
        <div className="overlay_card1" onClick={(e) => stopProp(e)}>
          <form style={{ alignItems: "normal" }}>
            <span style={{ textAlign: "initial" }}>
              <div className="head-text">ActivityFeed</div>
            </span>

            <div>
              <p
                style={{
                  textAlign: "initial",
                  color: "gray",
                }}
              >
                {t("activity_feed.welcome_text")}
              </p>
              <p
                style={{
                  textAlign: "initial",
                  color: "gray",
                }}
              >
                {t("activity_feed.first_time_view_text.line1")}
                {t("activity_feed.first_time_view_text.line2")}
              </p>
            </div>

            <div>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#4155ee",
                  width: "100%",
                  marginTop: "4%",
                }}
                onClick={() => setOverlayHint(false)}
              >
                OK
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const downloadPdf = () => {
    // Define the URL of your PDF file
    const pdfUrl = 'https://www.ashoka.org/themes/custom/blanco/pdf/changemaking_network_effects_full_book.pdf';

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '_blank'; // Open the link in a new tab
    link.setAttribute('download', 'downloaded-file.pdf'); // Set the desired file name

    // Trigger a click event to start the download
    link.click();
  };

  const LoginOverlayWhatsNew = ({ removeOverlayWhatsNew }) => {

    return (
      <div
        className="overlay_background1"
        onClick={(e) => removeOverlayWhatsNew()}
      >
        <div className="overlay_card1 modal-width-lg" onClick={(e) => stopProp(e)}>
          <form style={{ alignItems: "normal", width: "100%" }}>
            <div className="head-textnew">
              <img src={cneImageAI} alt="Book cover" className="img-book" />
            </div>

            <div className="head-text">
              {t("whats_new.book_title")}
            </div>
            <div style={{ marginBottom: "25px" }}>
              <p
                style={{
                  textAlign: "left",
                  marginBottom: "0",
                }}
              >
                {htmlParser.parse(whatsNewBookDescription)}
              </p>
            </div>
            <div style={{ display: "flex", borderTop: "1px solid #e0e0e0" }}>
              <Button
                variant="outlined"
                style={{
                  borderColor: "#4155ee",
                  color: "#4155ee",
                  width: "99%",
                  marginTop: flag ? "4%" : "8%",
                  marginRight: "1%",
                }}
                onClick={() => {
                  setOverlayWhatsNew(false);
                  localStorage.setItem("whatsnewPopupNoticeFlag", 1);
                }}
              >
                {t("whats_new.dismiss")}
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#4155ee",
                  width: "99%",
                  marginTop: flag ? "4%" : "8%",
                  marginLeft: "1%",
                }}
                onClick={() => { window.location.href = 'mailto:zmeydanoglu@ashoka.org'}}
              >
                {t("whats_new.download")}
              </Button>
            </div>

          </form>
        </div>
      </div>
    );
  };
  //change
  const ErrorPopup = ({ status }) => {
    return (
      <div className="overlay_background" onClick={(e) => status()}>
        <div
          className="overlay_card"
          style={{ width: flag ? "40%" : "80%", display: "block" }}
          onClick={(e) => stopProp(e)}
        >
          <div
            style={{ display: "inline-flex", marginTop: "4%", width: "70%" }}
          >
            <div>
              <img src={promptImage1} alt="user profile" />
            </div>
            <div style={{ color: "dimgray" }}>
              <h2 style={{ color: "#4155ee" }}> {t("activity_feed.sorry")}!</h2>
              {t("activity_feed.sry_text")}
            </div>
          </div>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#4155ee",
              float: "right",
              margin: "25% 10% 0% 0",
            }}
            onClick={() => setErrorPopup(false)}
          >
            {t("activity_feed.dismiss")}
          </Button>
        </div>
      </div>
    );
  };
  const handleLoadMorePost = () => {
    setLoader(true);
    var temp = pageLimit + 5;
    setPageLimit(temp);
    getActivityFeed(temp).then((res) => {
      if (res) {
        setLoader(false);
        if (res.meta.count <= temp) {
          setLoadMoreStatus(true);
        }
        setActivityFeedData(res);
      }
    });
  };

  const handleSavePost = (id) => {
    setLoaderForSavePost(true);
    let fellowId = fellowData?.data?.id;
    let postId = id;
    //setErrorPopup(true);
    saveThePost(fellowId, postId).then((res) => {
      if (res) {
        setLoaderForSavePost(false);
        setOverlay1({
          overlayId1: "",
          status1: false,
          title: "",
        });
        toast.success("Post Saved Successfully!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    });
  };

  const redirectSearch = () => {
    fellowData?.data && setSearch("seeking-collaboration")
  }
  return (
    <div className="dashboard">
      <div className="searchbar-wrapper">
        <div className="searchbar">
          <SearchIcon className="search-icon" />
          <label for="dash_search" className="hidden">Search</label>
          <input
            type="text"
            placeholder={t("dashboard.search_lable")}
            className="search-text"
            onKeyDown={(e) => handleKeyDown(e)}
            id="dash_search"
          />
        </div>
      </div>
      <div className="content-wrapper">
        <div className="main-content">
          <div className="left">
            <GridList cellHeight="auto">
              <div
                className="fellow-scroller-tiles"
                style={fellowData?.data ? { opacity: 1 } : { opacity: 0.5 }}
              >
                <GridListTile
                  className="item nearme city"
                  onClick={() => fellowData?.data && setSearch("city")}
                >
                  <NearMe />
                  <div className="title">
                    {t("dashboard.search_fellows_label")}
                  </div>
                  <div className="subtitle">
                    {t("dashboard.search_with_same_city")}
                  </div>
                </GridListTile>
                <GridListTile
                  className="item similarfields "
                  onClick={() => fellowData?.data && setSearch("topics")}
                >
                  <SimilarFeildFellows />
                  <div className="title">
                    {t("dashboard.search_fellows_label")}
                  </div>
                  <div className="subtitle">
                    {t("dashboard.search_with_same_fields")}
                  </div>
                </GridListTile>
                <GridListTile
                  className="item countryfellows"
                  onClick={() => fellowData?.data && setSearch("country")}
                >
                  <CountryFellows />
                  <div className="title">
                    {t("dashboard.search_fellows_label")}
                  </div>
                  <div className="subtitle">
                    {t("dashboard.search_with_same_country")}
                  </div>
                </GridListTile>
              </div>
            </GridList>

            <div className="active-fellow-wrapper">
              <h2 className="head-text">{t("dashboard.most_active")}</h2>
              <GridList cellHeight="auto">
                <div className="fellow-scroller">
                  {areMostActiveFellowsLoading &&
                    Array(8)
                      .fill(null)
                      .map((item, index) => {
                        return <SkeletonColLoader key={index} />;
                      })}
                  {!areMostActiveFellowsLoading &&
                    !mostActiveFellows?.data?.length && (
                      <div className="empty-most-active-fellows">
                        <SentimentVeryDissatisfiedIcon className="empty-icon-spacings" />
                        It’s a little quite in here… Let’s get
                        connected/connecting.
                      </div>
                    )}
                  {!areMostActiveFellowsLoading &&
                    mostActiveFellows?.data?.map((fellow, index) => (
                      <FellowColTeaser
                        key={index}
                        id={fellow?.id}
                        nid={fellow?.attributes?.drupal_internal__nid}
                        image={
                          getFellowImage(
                            mostActiveFellows.included,
                            fellow?.relationships?.field_media_profile_image
                              ?.data?.id
                          ) || currentProfileImg
                        }
                        name={fellow?.attributes?.title || ""}
                        country={
                          fellow?.attributes &&
                          fellow.attributes.field_fd_fellow_location &&
                          getFellowLocation(
                            fellow.attributes.field_fd_fellow_location,
                            countriesInJson
                          )
                        }
                      />
                    ))}
                </div>
              </GridList>
            </div>

            <div className="collaboration-wrapper">
              <div className="collaboration-header">
                <h2 className="head-text">
                  {t("dashboard.seeking_collaboration")}
                </h2>
                <button className="arrow-btn text-hide" onClick={redirectSearch}>
                  Right arrow
                  <ArrowForwardIcon
                    style={{ color: "#4155EE", cursor: "pointer" }}
                    aria-hidden="false"
                  />
                </button>
              </div>
              <GridList>
                <div className="fellow-scroller">
                  {areSeekingCollaborationFellowsLoading &&
                    Array(8)
                      .fill(null)
                      .map((item, index) => {
                        return <SkeletonColLoader key={index} />;
                      })}
                  {!areSeekingCollaborationFellowsLoading &&
                    !fellowsSeekingForCollaboration?.data?.length && (
                      <div className="empty-seeking-collab-fellows">
                        <SentimentVeryDissatisfiedIcon className="empty-icon-spacings" />
                        Change starts with us. Flip the switch and get
                        collaborating.
                      </div>
                    )}
                  {!areSeekingCollaborationFellowsLoading &&
                    fellowsSeekingForCollaboration?.data?.map(
                      (fellow, index) => (
                        <FellowColTeaser
                          key={index}
                          id={fellow?.id}
                          nid={fellow?.attributes?.drupal_internal__nid}
                          image={
                            getFellowImage(
                              fellowsSeekingForCollaboration.included,
                              fellow?.relationships?.field_media_profile_image?.data?.id
                            ) || currentProfileImg
                          }
                          name={fellow?.attributes?.title}
                          country={
                            fellow?.attributes &&
                            fellow.attributes.field_fd_fellow_location &&
                            getFellowLocation(
                              fellow.attributes.field_fd_fellow_location,
                              countriesInJson
                            )
                          }
                        />
                      )
                    )}
                </div>
              </GridList>
            </div>
            {/* -------changes------- */}
            <div className="active-fellow-wrapper">
              <div style={{ float: "right" }}>
                {" "}
                <HelpOutlineIcon
                  className="hint-icon activityFeed"
                  onClick={() => {
                    setOverlayHint(true);
                  }}
                />
              </div>
              <div className="active-fellow-wrapper">
                <h2 className="head-text">{t("dashboard.activity_feed")}</h2>
              </div>

              {/* --First time view only-- */}
              <div
                hidden={
                  activityFeedData?.data?.length > 0 ||
                  areActivityFeedDataLoading
                }
                style={{
                  backgroundColor: "#F5F5F5",
                  padding: flag ? "2%" : "2% 5% 2% 5%",
                  marginBottom: "5%",
                }}
              >
                <div
                  style={{
                    float: "left",
                    margin: flag ? " 5% 4% 2% 4%" : " 8% 4% 2% 4%",
                  }}
                >
                  <img src={actFeedImage} alt="user profile" />
                </div>
                <p style={{ color: "dimgray" }}>
                  <b>{t("activity_feed.first_time_view_text.line1")}</b>
                  <br />
                  {t("activity_feed.first_time_view_text.line2")}
                </p>
              </div>

              {flag ? (
                ""
              ) : (
                <div>
                  {modalOpen && (
                    <Modal
                      setOpenModal={setModalOpen}
                      setActivityFeedData={setActivityFeedData}
                    />
                  )}
                  {editModalOpen ? (
                    <EditModal
                      setOpenModal={setEditModalOpen}
                      PostId={editPostId}
                      setActivityFeedData={setActivityFeedData}
                    />
                  ) : (
                    ""
                  )}
                </div>
              )}
              <div className="activity-feed-wrapper">
                {/* --List of Feed activity-- */}
                <div className="feed-btn">
                  <p
                    style={{ paddingLeft: "20px", color: "dimgray" }}
                    onClick={() => {
                      setModalOpen(true);
                    }}
                  >
                    {t("activity_feed.create_post_text")}
                  </p>
                </div>
                {/* Changees for list */}
                <div
                  className={
                    overlay.status ? "overlay_shown" : "overlay_hidden"
                  }
                >
                  <LoginOverlay
                    removeOverlay={() => setOverlay(overlay.status)}
                  />
                </div>
                <div
                  className={
                    overlay1.status1 ? "overlay_shown" : "overlay_hidden"
                  }
                >
                  <LoginOverlay1
                    removeOverlay1={() => setOverlay1(overlay1.status1)}
                  />
                </div>
                <div
                  className={overlayHint ? "overlay_shown" : "overlay_hidden"}
                >
                  <LoginOverlayHint
                    removeOverlayHint={() => setOverlayHint(overlayHint)}
                  />
                </div>
                <div
                  className={
                    overlayWhatsNew ? "overlay_shown" : "overlay_hidden"
                  }
                >
                  <LoginOverlayWhatsNew
                    removeOverlayWhatsNew={() =>
                      setOverlayWhatsNew(overlayWhatsNew)
                    }
                  />
                </div>
                <div
                  className={errorPopup ? "overlay_shown" : "overlay_hidden"}
                >
                  <ErrorPopup status={() => setErrorPopup(true)} />
                </div>

                <div className="recent-active" style={{ border: "none" }}>
                  {areActivityFeedDataLoading &&
                    Array(5)
                      .fill(null)
                      .map((item, index) => {
                        return <SkeletonRowLoader key={index} />;
                      })}
                  {!areActivityFeedDataLoading &&
                    activityFeedData?.data &&
                    activityFeedData?.data?.map((fellow, index) => {
                      var uri = fellow?.attributes?.field_post_link ? fellow?.attributes?.field_post_link : "";
                      //date formate change
                      var previousDate = new Date(fellow.attributes.created);
                      var previousDateInFormat =
                        previousDate.getMonth() +
                        "/" +
                        previousDate.getDate() +
                        "/" +
                        previousDate.getFullYear();
                      var d = new Date();
                      var d1 = previousDateInFormat;
                      var d2 =
                        d.getMonth() +
                        "/" +
                        d.getDate() +
                        "/" +
                        d.getFullYear();
                      var d3 = new Date(d1);
                      var d4 = new Date(d2);
                      var diffTime = Math.abs(d4 - d3);
                      var diffDays = Math.ceil(
                        diffTime / (1000 * 60 * 60 * 24)
                      );
                      //date formate change

                      for (
                        var j = 0;
                        j < activityFeedData?.included.length;
                        j++
                      ) {
                        var key1 = activityFeedData.included[j];

                        if (fellow.relationships.uid.data.id === key1.id) {
                          if (
                            key1?.relationships?.field_profile_image?.data === null
                          ) {
                            imagePath = undefined;
                          } else {
                            for (
                              var i = 0;
                              i < activityFeedData?.included.length;
                              i++
                            ) {
                              if (
                                activityFeedData?.included[i].id ===
                                key1?.relationships?.field_profile_image?.data?.id
                              ) {
                                imagePath =
                                  activityFeedData?.included[i].relationships
                                    ?.thumbnail?.data?.meta?.imageDerivatives
                                    ?.links?.small_round_320x320?.href;
                              }
                            }
                          }
                          //for fellow link changes
                          { // eslint-disable-line
                            if (
                              key1.relationships?.field_fellow_profile?.data
                            ) {
                              var temp2 =
                                key1.relationships.field_fellow_profile.data.id;
                              for (
                                var z = 0;
                                z < activityFeedData.included.length;
                                z++
                              ) {
                                if (temp2 === activityFeedData.included[z].id) {
                                  fellow_drupal_internal_id =
                                    activityFeedData.included[z].attributes
                                      .drupal_internal__nid;
                                  //For contact fellow options
                                  smAccsData =
                                    activityFeedData?.included[z]?.attributes
                                      ?.field_other_social_media_links
                                      ?.platform_values;
                                  imAccsData =
                                    activityFeedData?.included[z]?.attributes
                                      ?.field_fd_fellow_ims?.platform_values;
                                  contactPrefsData =
                                    activityFeedData?.included[z]?.attributes
                                      ?.field_fd_contact_preferences;
                                  emailData =
                                    activityFeedData?.included[z]?.attributes
                                      ?.field_email;
                                  phoneData =
                                    activityFeedData?.included[z]?.attributes
                                      ?.field_phone;
                                }
                              }
                            }
                          }
                        }
                      }

                      return (
                        <div style={{ position: "relative" }} key={fellow.id}>
                          <IconButton
                            onClick={() => {
                              setOverlay1({
                                overlayId1: fellow?.id,
                                status1: true,
                                title: fellow?.attributes?.title,
                              });
                            }}
                            className="text-hide"
                            style={{ float: "right" }}
                          >
                            More
                            <MoreVertIcon />
                          </IconButton>
                          {/* </span> */}
                          <Link
                            to={{
                              pathname: `/fellow/${fellow_drupal_internal_id}/${fellow?.attributes?.title}`,
                              state: { id: fellow?.id },
                            }}
                            className="item"
                            key={index}
                          >
                            <img
                              style={{ width: "80px", height: "80px" }}
                              src={
                                imagePath === undefined
                                  ? currentProfileImg
                                  : imagePath
                              }
                              //src={currentProfileImg}
                              className="avt-img"
                              alt="user profile"
                            />
                            <div className="fellow-detail">
                              <div className="name">
                                <br />
                                {fellow?.attributes?.title}
                              </div>
                              <div style={{ display: "flex" }}>
                                <div className="country">
                                  {diffDays === 0
                                    ? "Today"
                                    : diffDays + " days ago"}
                                </div>
                                <div
                                  hidden={
                                    fellow?.attributes &&
                                    fellow?.attributes?.field_location === null
                                  }
                                  className="country"
                                >
                                  &nbsp;-&nbsp;
                                  {fellow?.attributes &&
                                    fellow?.attributes?.field_location}
                                </div>
                              </div>
                            </div>
                          </Link>
                          <div className="box-content">
                            {fellow?.attributes &&
                              fellow?.attributes?.field_post_content}
                          </div>
                          <div className="website-link">
                            <a
                              href={
                                uri === null
                                  ? ""
                                  : !uri.startsWith("http://") &&
                                    !uri.startsWith("https://")
                                  ? "https://" + uri
                                  : uri
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              className="orgs-websites website"
                              style={{ color: "#4155EE" }}
                            >
                              {fellow?.attributes &&
                                fellow?.attributes?.field_post_link}
                            </a>
                          </div>
                          {/* <div style={{ display: "flex" }}> */}
                          <DisplayWorkFields
                            classNameForWOrkField="work-fields1"
                            fieldsOfWork={
                              fellow?.relationships?.field_topics?.data
                            }
                            includedIn={activityFeedData?.included}
                            isClickable={true}
                          />
                          <DisplayWorkFields
                            classNameForWOrkField="work-fields1"
                            className="tags"
                            fieldsOfWork={
                              fellow?.relationships?.field_tags?.data
                            }
                            includedIn={activityFeedData?.included}
                          />
                          {/* </div> */}
                        </div>
                      );
                    })}

                  <div
                    name="Load_More"
                    style={{ textAlign: "center" }}
                    hidden={loadMoreStatus}
                  >
                    <CustomButton
                      onClick={() => {
                        handleLoadMorePost();
                      }}
                      classes="post-analytics"
                      style={{
                        backgroundColor: "#4155ee",
                        width: flag ? "50%" : "100%",
                        marginTop: "20px",
                        color: "#fff",
                      }}
                      text={t("activity_feed.load_more")}
                      variant="contained"
                      isLoading={loader}
                    />
                  </div>
                </div>
              </div>
              {/* Change for list */}
            </div>
          </div>

          <div className="right">
            {/* Hidden fellows profile Progress logic */}
            <div className="recent-active">
              <h2 className="head-text">{t("dashboard.progress.title")}</h2>
              <Textfield
                id="bio"
                label="Bio"
                rows={4}
                defaultValue="Default Value"
                variant="filled"
                name="bio"
                onChange={(e) => setBio(e.target.value)}
                value={bio}
                multiline={true}
                className="bio"
                isDisabled={true}
              />
              <div className="progress">
                <div className="progress-text">93% complete</div>
                <BorderLinearProgress variant="determinate" value={93} />
              </div>
              <div className="button-wrapper">
                <CustomButton
                  text={t("dashboard.progress.skip_button")}
                  onClick={onSave}
                  classes="outlined-btn"
                />
                <CustomButton
                  text={t("dashboard.progress.save_button")}
                  onClick={onContactAdd}
                  classes="filled-btn"
                />
              </div>
            </div>

            <div
              className="recent-active flex drawer-card"
              style={{ backgroundColor: "#036", marginTop: "0", padding: "25px", alignItems: "flex-start" }}
            >
              <div className="card-left">
                <img src={acrcImage} alt="ACRC" style={{filter: "brightness(0) invert(1)"}} />
              </div>
              <div className="card-right">
                <div style={{ marginLeft: "5%", color: "#fff" }}>
                  <strong>{t("dashboard.acrc_title")}</strong> {t("dashboard.acrc_description")}
                </div>
                <a href="https://acrc.ashoka.org/en" className="acrc-btn">
                  {t("dashboard.acrc_btn_text")}
                </a>
              </div>
            </div>

            <div className="recent-active">
              <h2 className="head-text">{t("dashboard.recently_active")}</h2>
              {areRecentActiveFellowsLoading &&
                Array(5)
                  .fill(null)
                  .map((item, index) => {
                    return <SkeletonRowLoader key={index} />;
                  })}
              {!areRecentActiveFellowsLoading &&
                recentlyActiveFellows?.data &&
                recentlyActiveFellows?.data?.map((fellow, index) => (
                  <Link
                    to={{
                      pathname: `/fellow/${fellow?.attributes?.drupal_internal__nid}/${fellow?.attributes?.title}`,
                      state: { id: fellow.id },
                    }}
                    className="item"
                    key={index}
                  >
                    <Avatar
                      alt="user icon"
                      src={
                        getFellowImage(
                          recentlyActiveFellows.included,
                          fellow?.relationships?.field_media_profile_image?.data?.id
                        ) || currentProfileImg
                      }
                      className="avt-img"
                    />
                    <div className="fellow-detail">
                      <div className="name">{fellow?.attributes?.title}</div>
                      <div className="country">
                        {fellow.attributes &&
                          fellow.attributes.field_fd_fellow_location &&
                          getFellowLocation(
                            fellow.attributes.field_fd_fellow_location,
                            countriesInJson
                          )}
                      </div>
                    </div>
                  </Link>
                ))}
              {!areRecentActiveFellowsLoading &&
                !recentlyActiveFellows?.data?.length && (
                  <div className="empty-recently-active-fellows">
                    <SentimentVeryDissatisfiedIcon className="empty-icon-spacings" />
                    It’s a little quite in here… Let’s get connected/connecting.
                    {t("dashboard.recently_active_fellow_loading")}
                  </div>
                )}
            </div>

            <div className="recent-active">
              <h2 className="head-text">{t("dashboard.new_fellows")}</h2>
              {areNewlyActiveFellowsLoading &&
                Array(5)
                  .fill(null)
                  .map((item, index) => {
                    return <SkeletonRowLoader key={index} />;
                  })}
              {!areNewlyActiveFellowsLoading &&
                !newlyActiveFellows?.data?.length && (
                  <div className="empty-new-fellows">
                    <BusinessCenterIcon className="empty-icon-spacings" />
                    <div>
                      {t("dashboard.new_active_working")}…{" "}
                      <span className="recommendations">
                        {t("dashboard.new_Active_recommended")}
                      </span>
                      <a
                        href="mailto:fdsupport@ashoka.org"
                        className="contactlink"
                      >
                        Contact us.
                      </a>
                    </div>
                  </div>
                )}
              {!areNewlyActiveFellowsLoading &&
                newlyActiveFellows?.data &&
                newlyActiveFellows?.data?.map((fellow, index) => (
                  <Link
                    to={{
                      pathname: `/fellow/${fellow?.attributes?.drupal_internal__nid}/${fellow?.attributes?.title}`,
                      state: { id: fellow.id },
                    }}
                    className="item"
                    key={index}
                  >
                    <Avatar
                      src={
                        getFellowImage(
                          newlyActiveFellows.included,
                          fellow.relationships.field_media_profile_image.data.id
                        ) || currentProfileImg
                      }
                      alt="user icon"
                      className="avt-img"
                    />
                    <div className="fellow-detail">
                      <div className="name">{fellow?.attributes?.title}</div>
                      <div className="country">
                        {fellow.attributes &&
                          fellow.attributes.field_fd_fellow_location &&
                          getFellowLocation(
                            fellow.attributes.field_fd_fellow_location,
                            countriesInJson
                          )}
                      </div>
                    </div>
                  </Link>
                ))}
            </div>

            {/* -------Changes------- */}
            {/* { showInstallMessage ? ( */}
              {/* <div
                className="recent-active flex"
                style={{ backgroundColor: "#f5f5f5", padding: "25px" }}
              >
                <div>
                  <img src={promptImage} alt="user profile" />
                </div>
                <div>
                  <div style={{ marginLeft: "5%", color: "dimgray" }}>
                    Install the Fellow Directory app on your iPhone: tap <span style={{width: "16px", display: "inline-block"}}><img src={iconIOS} width="100%" alt="" /></span> and then Add to homescreen.
                  </div>
                </div>
              </div> */}
            {/* ) : (
              <div></div>
            )} */}


            { isIOS ? (
              <>
                <div
                  className="recent-active flex only-mbl"
                  style={{ backgroundColor: "#f5f5f5", padding: "25px" }}
                >
                  <div>
                    <img src={promptImage} alt="user profile" />
                  </div>
                  <div>
                    <div style={{ marginLeft: "5%", color: "dimgray" }}>
                      Install the Fellow Directory app on your iPhone: tap <span style={{width: "16px", display: "inline-block"}}><img src={iconIOS} width="100%" alt="" /></span> and then Add to homescreen.
                    </div>
                  </div>
                </div>
              </>
              // <div className='sticky-modal'>Install the app on your iPhone: tap <span style={{width: "16px", display: "inline-block"}}><img src={iconIOS} width="100%" alt="" /></span> and then Add to homescreen.</div>
            ) : (
              <>
              {/* <div id='custom-info-bar'>
                <Button variant="dark" id='custom-install-button'>Install</Button>
              </div> */}
              </>
            )}
          </div>
          {modalOpen && flag && (
            <Modal
              setOpenModal={setModalOpen}
              setActivityFeedData={setActivityFeedData}
              setLoadMoreStatus={loadMoreStatus}
            />
          )}
          {editModalOpen && flag && (
            <EditModal
              setOpenModal={setEditModalOpen}
              PostId={editPostId}
              setActivityFeedData={setActivityFeedData}
            />
          )}
        </div>
      </div>
      <ContactMethods
        onClose={onClose}
        open={openContactFellow}
        smAccs={smAccsData}
        imAccs={imAccsData}
        contactPrefs={contactPrefsData}
        email={emailData || ""}
        phone={phoneData || ""}
      />
    </div>
  );
};

export default Dashboard;
