import React from "react";

export const DashboardIcon = ({ isActive }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 10H8V0H0V10ZM0 18H8V12H0V18ZM10 18H18V8H10V18ZM10 0V6H18V0H10Z"
      fill={isActive ? "#4155EE" : "black"}
      fillOpacity={isActive ? null : "0.6"}
    />
  </svg>
);
