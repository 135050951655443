import React, { useEffect, useState } from "react";
import { getPolicyData } from "../../../services/fellow.getService";
import SkeletonPolicyLoader from "../../Loaders/skeletonPolicyLoader";
import "./policyMethod.scss";

const PolicyMethod = (props) => {
  const { location } = props;
  const [policyData, setPolicyData] = useState("");
  const [isLoading, setLoader] = useState(false);
  const policyId = location?.state?.id;

  useEffect(() => {
    if (policyId) {
      setLoader(true);
      getPolicyData(policyId)
        .then((res) => {
          if (res) {
            setPolicyData(res);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log("err :", err);
          setLoader(false);
        });
    }
  }, [policyId]);

  return (
    <div className="policy-method-wrapper">
      <div className="container">
        {isLoading && <SkeletonPolicyLoader />}
        {!isLoading && policyData && (
          <>
            <div className="head-text">{location?.state?.title}</div>
            <div dangerouslySetInnerHTML={{ __html: policyData }} />
          </>
        )}
        {!isLoading && !policyData && <h2>Oops..no data found.</h2>}
      </div>
    </div>
  );
};

export default PolicyMethod;
