import React, { useState } from "react";
import CustomButton from "../basic-components/button";
import { useHistory } from "react-router-dom";
import ErrorAlert from "../basic-components/snackbar";
import { useGlobalState, useGlobalDispatch } from "../context/globalContext";
import { verifyOTP } from "../../services/authService";
import { getFellowProfile } from "../../services/fellow.getService";
import { generateOTP } from "../../services/authService";
import { getFellowImage } from "../../services/helper-functions";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import LanguagePicker from "../basic-components/languagePicker";
import { updatePrefrredLanguage } from "../../services/fellow.patchService";
import { allowedLaguages } from "../../services/constantValues";
import Loader from "../basic-components/loader";

const EmailVerification = (props) => {
  const [code, setCode] = useState([]);
  const [errors, setErrors] = useState({
    errorSeverity: "",
    openSnackbar: false,
    msg: "",
    isError: false,
  });
  const history = useHistory();
  const globalDispatch = useGlobalDispatch();
  const { email } = useGlobalState();
  const { updateLogin, lang, updateFDLanguage, setListingsLoader } = props;
  const { t } = useTranslation(["user-auth"]);
  const [loader, setLoader] = useState(false);
  const [resentOTPLoader, setResentOTPLoader] = useState(false);

  const onCode = (event, index) => {
    const { value } = event.target;

    let errorValues = { ...errors };
    if (isNaN(value)) return;

    if (String(value).length > 1) {
      if (String(value).length === 4) {
        let data = value.split("");
        setCode(data);
      }
      return;
    }

    let codeVal = [...code];
    codeVal[index] = value;
    setCode(codeVal);
    setErrors(errorValues);
    index < 3 && value && document.getElementById(`elem${index + 1}`).focus();
  };

  const setSnackbar = (value) => {
    setErrors((errors) => ({ ...errors, openSnackbar: value }));
  };

  const saveLanguageUpdate = () => {
    if (lang) {
      updatePrefrredLanguage(lang.code)
        .then((res) => {
          setLoader(false);
        })
        .catch((err) => {
          console.log("Error in saving language preferance :", err);
          setLoader(false);
          setErrors((errors) => ({
            ...errors,
            openSnackbar: true,
            errorSeverity: "error",
            msg: "Could not save the language preferance.",
          }));
        });
    }
  };

  const getVerificationCode = () => {
    setLoader(true);

    if (errors.isError || code.length !== 4) {
      setErrors((errors) => ({
        ...errors,
        openSnackbar: true,
        errorSeverity: "error",
        msg: "Please enter your verification code",
      }));
      setLoader(false);
      return;
    }

    for (let i = 0; i < code.length; i++) {
      if (code && !code[i]) {
        setErrors((errors) => ({
          ...errors,
          openSnackbar: true,
          errorSeverity: "error",
          msg: "Please enter your verification code",
        }));
        setLoader(false);
        return;
      }
    }

    verifyOTP({ email, otp: code.join("") }).then((result) => {
      if (result.status === 200) {
        localStorage.setItem("token", result.access_token);
        localStorage.setItem("profile_uuid", result.profile_uuid);
        updateLogin(true);

        if (!result.fd_status) {
          history.push("/onboarding/getstarted");
        } else {
          history.push("/dashboard");
        }
        setListingsLoader("isFellowProfileLoading", true);
        getFellowProfile(result.profile_uuid).then(async (res) => {
          if (res) {
            globalDispatch({ type: "setUserData", payload: res });
            let image = await getFellowImage(
              res?.included,
              res?.data?.relationships?.field_media_profile_image?.data?.id
            );
            globalDispatch({ type: "setCurrentProfileImg", payload: image });
            setListingsLoader("isFellowProfileLoading", false);
            // save user preferred language from backend id exist else save the selected UI lanaguage
            if (res?.data?.attributes?.field_fd_ui_language) {
              let language = res?.data?.attributes?.field_fd_ui_language;
              let defaultLang = allowedLaguages.find(
                (lang) => lang.code === language
              );
              if (defaultLang) updateFDLanguage(defaultLang || null);
            } else saveLanguageUpdate();
          }
        });
      } else
        setErrors((errors) => ({
          ...errors,
          openSnackbar: true,
          errorSeverity: "error",
          msg: result.message,
        }));
      setLoader(false);
    });
  };

  const onKeyDownPress = (event, index) => {
    const { value } = event.target;
    var key = event.keyCode || event.charCode;
    if (value && (key === 8 || key === 46)) {
      event.preventDefault();
      let codeVal = [...code];
      codeVal[index] = "";
      setCode(codeVal);
      index > 0 && document.getElementById(`elem${index - 1}`).focus();
    }
    if (key === "Enter" || key === 13) getVerificationCode();
  };
  const resendOTP = () => {
    setResentOTPLoader(true);
    setCode([]);
    generateOTP({ email }).then((result) => {
      if (result.status === 200) {
        setErrors((errors) => ({
          ...errors,
          openSnackbar: true,
          errorSeverity: "success",
          msg: "New OTP has been sent to you.",
        }));
        setResentOTPLoader(false);
        return;
      }
    });
  };

  return (
    <div className="verification">
      <div className="wrapper">
        <div>
          <LanguagePicker setLang={updateFDLanguage} lang={lang} />
        </div>
        <h1 className="head-text">{t("OTP_verification.title")}</h1>
        <div className="content">
          {t("OTP_verification.otp_email")}
          <b>{email || t("your_mail")}</b>
          <div>{t("OTP_verification.sub_title")}</div>
        </div>
        <div className="codes">
          {Array(4)
            .fill(null)
            .map((elem, index) => {
              return (
                <TextField
                  id={`elem${index}`}
                  key={index}
                  variant="filled"
                  onKeyDown={(e) => onKeyDownPress(e, index)}
                  value={isNaN(code[index]) ? "" : code[index]}
                  onChange={(event) => onCode(event, index)}
                  className={`textfield ${code[index] ? "filled" : ""} `}
                  inputProps={{
                    inputMode: "numeric",
                    autoComplete: "new-password",
                  }}
                />
              );
            })}
        </div>
        <CustomButton
          classes="code-btn"
          onClick={getVerificationCode}
          text={t("OTP_verification.signIn_button.lable")}
          variant="contained"
          isLoading={loader}
        />
        <div className="vcode">
          <div className="didnt-receive-otp">
            {t("OTP_verification.didn't_receieve_text")}
          </div>
          <div>{t("OTP_verification.check_spam")}</div>
          <div className="resend-otp-wrapper">
            <div
              className="resend"
              onClick={() => (!resentOTPLoader ? resendOTP() : null)}
            >
              {t("OTP_verification.resend")}
            </div>
            {resentOTPLoader && <Loader />}
          </div>
          <div className="login-support">
            {t("form.trouble.lable")}{" "}
            <a href={`mailto:fdsupport@ashoka.org`} className="conatct_us">
              {t("form.contact.lable")}
            </a>
          </div>
          {/* <div className="resend" onClick={resendOTP}>{t('OTP_verification.resend')}</div> */}
        </div>
      </div>
      <ErrorAlert
        openSnackbar={errors.openSnackbar}
        msg={errors.msg}
        errorSeverity={errors.errorSeverity}
        setSnackbar={setSnackbar}
      />
    </div>
  );
};

export default EmailVerification;
