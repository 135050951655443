import "./contactInfo.scss";

import React, { useEffect, useState } from "react";
import {
  getExistingContactInfo,
  getFormatedQuestions,
} from "../../../services/helper-functions";
import {
  useContactDispatch,
  useContactState,
} from "../../context/contactContext";

import ContactForm from "../../forms/contactform";
import CustomButton from "../../basic-components/button";
import CustomCheckbox from "../../basic-components/checkbox";
import ErrorAlert from "../../basic-components/snackbar";
// import HelpQuestionnaire from "../../helpQuestionnaire";
import InfoDialog from "./infoDialog";
import LanguagePicker from "../../basic-components/languagePicker";
import { ReactComponent as LeftArrow } from "../../../images/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../../images/right-arrow.svg";
import SkeletonFellowCardLoader from "../../Loaders/skeletonFellowCardLoader";
import { updateFellowData } from "../../../services/fellow.patchService";
import { updatePrefrredLanguage } from "../../../services/fellow.patchService";
import { useGlobalDispatch } from "../../context/globalContext";
import { useGlobalState } from "../../context/globalContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ContactInfo = (props) => {
  const { mediasToContact, smaccounts, imaccounts, contactinfo } =
    useContactState();
  const [errors, setErrors] = useState({
    errorSeverity: "",
    openSnackbar: false,
    msg: "",
    ccode: {},
    mobile: {},
    imMediaLink: [],
  });
  const { fellowData, defaultCountryCode } = useGlobalState();
  const [openDialog, setDialog] = useState(false);
  const contactDispatch = useContactDispatch();
  const globalDispatch = useGlobalDispatch();
  const { t } = useTranslation(["forms", "common", "questionnaires"]);
  const [loader, setLoader] = useState(false);
  const [questions, setquestions] = useState([]); // eslint-disable-line
  const { lang, updateFDLanguage, loaders } = props;
  const { isDefaultCountryCodeLoading, isFellowProfileLoading } = loaders;

  let history = useHistory();

  const setErrorValues = (data) => {
    setErrors(data);
  };

  const handleNext = () => {
    UpdateData();
  };

  const handleSkip = () => {
    setLoader(true);
    if (lang) {
      updatePrefrredLanguage(lang.code)
        .then((res) => {
          setLoader(false);
          history.push("/onboarding/profile");
        })
        .catch((err) => {
          console.log("Error in saving language preferance :", err);
          setLoader(false);
          setErrors((errors) => ({
            ...errors,
            openSnackbar: true,
            errorSeverity: "error",
            msg: "Could not save the language preferance.",
          }));
        });
    }
  };

  const UpdateData = async () => {
    const id = await localStorage.getItem("profile_uuid");
    let fellowInfo = { data: { attributes: {} } };

    if (errors.ccode.isErorr || errors.mobile.isErorr) {
      setErrors((state) => ({
        ...state,
        openSnackbar: true,
        errorSeverity: "error",
        msg: "Please enter valid details",
      }));
      return;
    }
    let imErrors = false;
    errors.imMediaLink.forEach((im) => {
      if (im.isErorr) {
        imErrors = true;
        setErrors((state) => ({
          ...state,
          openSnackbar: true,
          errorSeverity: "error",
          msg: "Please enter valid details",
        }));
        return;
      }
    });
    if (imErrors) {
      return;
    }

    let field_other_social_media_links = {};
    smaccounts.forEach((acc) => {
      let value, title;

      if (
        acc?.handler &&
        acc?.mediatype?.title !== "Tumblr" &&
        acc?.mediatype?.title !== "Other"
      )
        value = acc.handler.replace(acc.mediatype.url, "");

      if (acc?.mediatype?.title === "Other") {
        title = "website";
        value = acc.handler;
      }

      if (acc?.mediatype?.title === "Tumblr") value = acc.handler;

      if (value)
        field_other_social_media_links = acc?.handler && {
          platform_values: {
            ...field_other_social_media_links.platform_values,
            [title || acc.mediatype.title.toLowerCase()]: { value: value },
          },
        };
    });

    let field_fd_fellow_ims = {};
    imaccounts.forEach((acc) => {
      if (acc?.handler)
        field_fd_fellow_ims = {
          platform_values: {
            ...field_fd_fellow_ims.platform_values,
            [acc.mediatype.title.toLowerCase()]: { value: acc.handler },
          },
        };
    });

    //get questionnaires data
    const questionnaires = getFormatedQuestions(questions);

    fellowInfo = {
      data: {
        type: "node--fellow",
        id,
        attributes: {
          ...questionnaires,
          field_phone:
            `${contactinfo?.ccode || defaultCountryCode},${
              contactinfo?.mobile
            }` || "",
          field_other_social_media_links,
          field_fd_fellow_ims,
          field_fd_ui_language: lang?.code,
          field_fd_contact_preferences: JSON.stringify(mediasToContact),
        },
      },
    };

    setLoader(true);
    updateFellowData(fellowInfo)
      .then((res) => {
        if (res) {
          globalDispatch({ type: "setUserData", payload: res });
          setLoader(false);
          setErrors((state) => ({
            ...state,
            openSnackbar: true,
            errorSeverity: "success",
            msg: "Contact information updated.",
          }));
          handleSkip();
        } else {
          setErrors((state) => ({
            ...state,
            openSnackbar: true,
            errorSeverity: "error",
            msg: "Could not update the information.",
          }));
          setLoader(false);
        }
      })
      .catch((err) => {
        setErrors((state) => ({
          ...state,
          openSnackbar: true,
          errorSeverity: "error",
          msg: "Could not update the information.",
        }));
        setLoader(false);
      });
  };

  // const handleQuestions = useCallback((value) => {
  //   setquestions(value);
  // }, []);

  const handleBack = () => {
    history.push("/onboarding/getstarted");
  };

  const setSnackbar = (value) => {
    setErrors((errors) => ({ ...errors, openSnackbar: value }));
  };

  const handleSocialMedia = (event, index) => {
    const { checked } = event.target;
    let allMedias = [...mediasToContact];
    allMedias[index].isAllowed = checked;
    contactDispatch({ type: "setAllMedias", payload: allMedias });
  };

  const handleDialog = () => {
    setDialog(!openDialog);
  };

  useEffect(() => {
    if (Object.keys(fellowData).length > 0 && defaultCountryCode) {
      const existingData = getExistingContactInfo(
        fellowData,
        defaultCountryCode
      );
      if (existingData) {
        contactDispatch({ type: "setDetails", payload: existingData });
      }
    }
  }, [fellowData, defaultCountryCode, contactDispatch]);

  return !isDefaultCountryCodeLoading && !isFellowProfileLoading ? (
    <>
      <div className="contact-info">
        <div className="lang">
          <LanguagePicker setLang={updateFDLanguage} lang={lang} />
        </div>
        <div className="wrapper">
          <h1 className="head-text">{t("contact_info.title")}</h1>
          <p className="sub-description desc">
            {t("contact_info.description")}
          </p>
          <p className="resend" onClick={handleDialog}>
            {t("contact_info.who_will_see")}
          </p>
          <form style={{ display: "block" }}>
            <ContactForm
              setErrorValues={setErrorValues}
              errors={errors}
              t={t}
            />
            <p className="sub-headings">
              {t("contact_info.form_fields.media_preference.lable")}
            </p>
            <p className="sub-description">
              {t("contact_info.form_fields.media_preference.description")}
            </p>
            <div className="contact-options">
              {mediasToContact &&
                mediasToContact.map((item, index) => {
                  return (
                    <CustomCheckbox
                      name={item.title}
                      label={item.title}
                      onChange={(event) => handleSocialMedia(event, index)}
                      isChecked={item.isAllowed}
                      key={index}
                    />
                  );
                })}
            </div>
          </form>
          {/* -----Help us understand more about you section----- */}
          {/* <div className="content-box questionnaire">
            <HelpQuestionnaire
              questions={questions}
              setquestions={handleQuestions}
              fellowData={fellowData}
            />
          </div> */}
        </div>
        <InfoDialog open={openDialog} handleClose={handleDialog} t={t} />
        <ErrorAlert
          openSnackbar={errors.openSnackbar}
          msg={errors.msg}
          errorSeverity={errors.errorSeverity}
          setSnackbar={setSnackbar}
        />
      </div>
      <div className="footer">
        <div className={`wrapper`}>
          <CustomButton
            classes="btn"
            onClick={handleNext}
            text={t("forms:contact_info.save_button")}
            variant="contained"
            isLoading={loader}
          />
          <div className="prev-next-btn-wrapper">
            <div className="update-route prev" onClick={handleBack}>
              <LeftArrow />
              <CustomButton text={t("common:button_previous.lable")} />
            </div>
            <div className="update-route next" onClick={handleSkip}>
              <CustomButton
                text={t("common:button_skip.lable")}
                isLoading={loader}
              />
              <RightArrow />
            </div>
          </div>
          {
            <ul>
              {Array(5)
                .fill(null)
                .map((i, index) => {
                  return (
                    <li
                      className={`${
                        index === 1 || index === 0 ? "active" : ""
                      }`}
                      key={index}
                    ></li>
                  );
                })}
            </ul>
          }
        </div>
      </div>
    </>
  ) : (
    <div className="contact-info">
      <div className="wrapper">
        <SkeletonFellowCardLoader />
      </div>
    </div>
  );
};

export default ContactInfo;
