import React from "react";
import GridListTile from "@material-ui/core/GridListTile";
import { Link } from "react-router-dom";
import { Avatar } from "@material-ui/core";

const FellowColTeaser = (props) => {
  const { id, image, name, country, nid } = props;
  return (
    <GridListTile className="item">
      <Link to={{ pathname: `/fellow/${nid}/${name}`, state: { id: id } }}>
        <Avatar src={image} className="avt-img" alt="user profile" />
      </Link>
      <Link to={{ pathname: `/fellow/${nid}/${name}`, state: { id: id } }}>
        <div className="name">{name}</div>
      </Link>
      <div className="country">{country}</div>
    </GridListTile>
  );
};

export default FellowColTeaser;
