import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import "./style.scss";

const SkeletonFellowCardLoader = () => {
  return (
    <div className="fellow-card">
      <div className="flex fellow-image">
        <Skeleton
          animation="wave"
          variant="circle"
          style={{ marginBottom: 10, marginRight: 16 }}
          className="circle"
        />
        <div className="card-titles">
          <Skeleton
            animation="wave"
            width="100%"
            style={{ marginBottom: 6 }}
            className="line"
          />
          <Skeleton
            animation="wave"
            width="90%"
            style={{ marginBottom: 6 }}
            className="line"
          />
        </div>
      </div>
      <Skeleton variant="rect" style={{ width: "100%" }} className="rect" />
    </div>
  );
};

export default SkeletonFellowCardLoader;
