import constants from "./constants";
import i18n from "i18next";

var topicFieldData = [];

export const getMostActiveFellow = async () => {
  const token = await localStorage.getItem("token");
  const loggedInUserId = await localStorage.getItem("profile_uuid");
  const testFellow1 = '6ead1905-8693-4aec-b84a-f9d5aeba4c9a';
  const testFellow2 = 'ad4e435e-ec20-44ba-8c6d-ab02f2b8a353';
  const stafffellow = '38d67331-96e8-4b7c-af83-bff8c98d4f6c';

  if (!token) return;

  if (!loggedInUserId) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}`
  );
  var params = [
    ["filter[field_fd_profile_ready_for_fd]", 1],
    [
      "fields[node--fellow]",
      "title,field_fd_fellow_location,field_media_profile_image,drupal_internal__nid",
    ],
    ["fields[media--image]", "thumbnail"],
    ["sort[activity-count][path]", "field_fd_activity_count"],
    ["sort[activity-count][direction]", "DESC"],
    ["include", "field_media_profile_image"],
    ["filter[excludeLoggedInUser][condition][path]", "id"],
    ["filter[excludeLoggedInUser][condition][operator]", "NOT IN"],
    ["filter[excludeLoggedInUser][condition][value]", loggedInUserId],
    ["filter[excludeTestFellow1][condition][path]", "id"],
    ["filter[excludeTestFellow1][condition][operator]", "NOT IN"],
    ["filter[excludeTestFellow1][condition][value]", testFellow1],
    ["filter[excludeTestFellow2][condition][path]", "id"],
    ["filter[excludeTestFellow2][condition][operator]", "NOT IN"],
    ["filter[excludeTestFellow2][condition][value]", testFellow2],
    ["filter[excludeTestFellow3][condition][path]", "id"],
    ["filter[excludeTestFellow3][condition][operator]", "NOT IN"],
    ["filter[excludeTestFellow3][condition][value]", stafffellow],
    ["page[offset]", 0],
    ["page[limit]", 8],
  ];
  url.search = new URLSearchParams(params).toString();

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, opts)
    .then(async (res) => {
      const data = await res.json();
      if (res.status === 200) {
        return data;
      }
      return;
    })
    .catch((err) => {
      return err;
    });
};

export const getRecentlyActiveFellow = async () => {
  const token = await localStorage.getItem("token");
  const loggedInUserId = await localStorage.getItem("profile_uuid");
  const testFellow1 = '6ead1905-8693-4aec-b84a-f9d5aeba4c9a';
  const testFellow2 = 'ad4e435e-ec20-44ba-8c6d-ab02f2b8a353';
  const stafffellow = '38d67331-96e8-4b7c-af83-bff8c98d4f6c';

  if (!loggedInUserId) return;

  if (!token) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}`
  );

  var params = [
    ["filter[field_fd_profile_ready_for_fd]", 1],
    [
      "fields[node--fellow]",
      "title,field_fd_fellow_location,field_collaboration_status,field_media_profile_image,field_other_social_media_links,field_email,field_phone,drupal_internal__nid,field_fd_contact_preferences,field_other_social_media_links,field_fd_fellow_ims",
    ],
    ["fields[media--image]", "thumbnail"],
    ["sort", "title"],
    ["include", "field_media_profile_image, field_topics, field_tags"],
    ["filter[excludeLoggedInUser][condition][path]", "id"],
    ["filter[excludeLoggedInUser][condition][operator]", "NOT IN"],
    ["filter[excludeLoggedInUser][condition][value]", loggedInUserId],
    ["filter[excludeTestFellow1][condition][path]", "id"],
    ["filter[excludeTestFellow1][condition][operator]", "NOT IN"],
    ["filter[excludeTestFellow1][condition][value]", testFellow1],
    ["filter[excludeTestFellow2][condition][path]", "id"],
    ["filter[excludeTestFellow2][condition][operator]", "NOT IN"],
    ["filter[excludeTestFellow2][condition][value]", testFellow2],
    ["filter[excludeTestFellow3][condition][path]", "id"],
    ["filter[excludeTestFellow3][condition][operator]", "NOT IN"],
    ["filter[excludeTestFellow3][condition][value]", stafffellow],
    ["page[offset]", 0],
    ["page[limit]", 4]
  ];

  const response = await getFellowProfile(loggedInUserId);

  topicFieldData = response?.data?.relationships?.field_topics?.data;
  if (topicFieldData && topicFieldData.length > 0) {
    params = [
      ...params,
      ["filter[topic_tags_group][group][conjunction]", "OR"],
      ["filter[field_topics][condition][path]", "field_topics.id"],
      ["filter[field_topics][condition][operator]", "IN"],
      ["filter[field_topics][condition][memberOf]", "topic_tags_group"],
    ];
    topicFieldData.forEach((topic, index) => {
      // topicData.push(topic.id); //changes
      //default field-topic params
      params = [
        ...params,
        [`filter[field_topics][condition][value][${index}]`, topic.id],
      ];
    });
  }

  // var tagFieldData = response?.data?.relationships?.field_tags?.data;
  // console.log(tagFieldData, 'tagFieldData');
  // if (tagFieldData && tagFieldData.length > 0) {
  //   params = [
  //     ...params,
  //     ["filter[field_tags][condition][path]", "field_tags.id"],
  //     ["filter[field_tags][condition][operator]", "IN"],
  //     ["filter[field_tags][condition][memberOf]", "topic_tags_group"]
  //   ];
  //   tagFieldData.forEach((tag, index) => {
  //     // selectedTagData.push(tag.id); //changes
  //     //default field-topic params
  //     params = [
  //       ...params,
  //       [`filter[field_tags][condition][value][${index}]`, tag.id],
  //     ];
  //   });
  // }

  url.search = new URLSearchParams(params).toString();

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, opts)
    .then(async (res) => {
      const data = await res.json();
      if (res.status === 200) return data;
      return;
    })
    .catch((err) => {
      return err;
    });
};

// export const getRecentlyActiveFellow = async () => {
//   const token = await localStorage.getItem("token");
//   const loggedInUserId = await localStorage.getItem("profile_uuid");

//   if (!loggedInUserId) return;

//   if (!token) return;

//   const url = new URL(
//     `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}`
//   );
//   var params = [
//     ["filter[field_fd_profile_ready_for_fd]", 1],
//     [
//       "fields[node--fellow]",
//       "title,field_fd_fellow_location,field_media_profile_image,drupal_internal__nid",
//     ],
//     ["fields[media--image]", "thumbnail"],
//     ["sort[activity-time][path]", "field_fd_last_activity"],
//     ["sort[activity-time][direction]", "DESC"],
//     ["include", "field_media_profile_image"],
//     ["filter[excludeLoggedInUser][condition][path]", "id"],
//     ["filter[excludeLoggedInUser][condition][operator]", "NOT IN"],
//     ["filter[excludeLoggedInUser][condition][value]", loggedInUserId],
//     ["page[offset]", 0],
//     ["page[limit]", 4],
//   ];
//   url.search = new URLSearchParams(params).toString();

//   const opts = {
//     method: "GET",
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };

//   return fetch(url, opts)
//     .then(async (res) => {
//       const data = await res.json();
//       if (res.status === 200) return data;
//       return;
//     })
//     .catch((err) => {
//       return err;
//     });
// };

export const getFellowsSeekingForCollaboration = async () => {
  const token = await localStorage.getItem("token");
  const loggedInUserId = await localStorage.getItem("profile_uuid");
  const testFellow1 = '6ead1905-8693-4aec-b84a-f9d5aeba4c9a';
  const testFellow2 = 'ad4e435e-ec20-44ba-8c6d-ab02f2b8a353';
  const stafffellow = '38d67331-96e8-4b7c-af83-bff8c98d4f6c';

  if (!loggedInUserId) return;

  if (!token) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}`
  );
  var params = [
    ["filter[field_fd_profile_ready_for_fd]", 1],
    ["filter[field_collaboration_status]", 1],
    [
      "fields[node--fellow]",
      "title,field_fd_fellow_location,field_media_profile_image,drupal_internal__nid,",
    ],
    ["fields[media--image]", "thumbnail"],
    ["sort[activity-count][path]", "field_fd_activity_count"],
    ["sort[activity-count][direction]", "DESC"],
    ["sort[activity-time][path]", "field_fd_last_activity"],
    ["sort[activity-time][direction]", "DESC"],
    ["include", "field_media_profile_image"],
    ["filter[excludeLoggedInUser][condition][path]", "id"],
    ["filter[excludeLoggedInUser][condition][operator]", "NOT IN"],
    ["filter[excludeLoggedInUser][condition][value]", loggedInUserId],
    ["filter[excludeTestFellow1][condition][path]", "id"],
    ["filter[excludeTestFellow1][condition][operator]", "NOT IN"],
    ["filter[excludeTestFellow1][condition][value]", testFellow1],
    ["filter[excludeTestFellow2][condition][path]", "id"],
    ["filter[excludeTestFellow2][condition][operator]", "NOT IN"],
    ["filter[excludeTestFellow2][condition][value]", testFellow2],
    ["filter[excludeTestFellow3][condition][path]", "id"],
    ["filter[excludeTestFellow3][condition][operator]", "NOT IN"],
    ["filter[excludeTestFellow3][condition][value]", stafffellow],
    ["page[offset]", 0],
    ["page[limit]", 8],
  ];
  url.search = new URLSearchParams(params).toString();

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, opts)
    .then(async (res) => {
      const data = await res.json();
      if (res.status === 200) return data;
      return;
    })
    .catch((err) => {
      return err;
    });
};

export const getNewlyActiveFellow = async () => {
  const token = await localStorage.getItem("token");
  const loggedInUserId = await localStorage.getItem("profile_uuid");
  const testFellow1 = '6ead1905-8693-4aec-b84a-f9d5aeba4c9a';
  const testFellow2 = 'ad4e435e-ec20-44ba-8c6d-ab02f2b8a353';
  const stafffellow = '38d67331-96e8-4b7c-af83-bff8c98d4f6c';

  if (!loggedInUserId) return;

  if (!token) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}`
  );
  var params = [
    ["filter[field_fd_profile_ready_for_fd]", 1],
    [
      "fields[node--fellow]",
      "title,field_fd_fellow_location,field_media_profile_image,field_date,drupal_internal__nid",
    ],
    ["fields[media--image]", "thumbnail"],
    ["sort[sort-election-date][path]", "field_date"],
    ["sort[sort-election-date][direction]", "DESC"],
    ["include", "field_media_profile_image"],
    ["filter[excludeLoggedInUser][condition][path]", "id"],
    ["filter[excludeLoggedInUser][condition][operator]", "NOT IN"],
    ["filter[excludeLoggedInUser][condition][value]", loggedInUserId],
    ["filter[excludeTestFellow1][condition][path]", "id"],
    ["filter[excludeTestFellow1][condition][operator]", "NOT IN"],
    ["filter[excludeTestFellow1][condition][value]", testFellow1],
    ["filter[excludeTestFellow2][condition][path]", "id"],
    ["filter[excludeTestFellow2][condition][operator]", "NOT IN"],
    ["filter[excludeTestFellow2][condition][value]", testFellow2],
    ["filter[excludeTestFellow3][condition][path]", "id"],
    ["filter[excludeTestFellow3][condition][operator]", "NOT IN"],
    ["filter[excludeTestFellow3][condition][value]", stafffellow],
    ["page[offset]", 0],
    ["page[limit]", 4],
  ];
  url.search = new URLSearchParams(params).toString();

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, opts)
    .then(async (res) => {
      const data = await res.json();
      if (res.status === 200) return data;
      return;
    })
    .catch((err) => {
      return err;
    });
};

export const getFellowProfile = async (id) => {
  const token = await localStorage.getItem("token");

  if (!token || !id) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}/${id}`
  );

  var params = [
    [
      "fields[node--fellow]",
      "title,field_fd_fellow_location,field_media_profile_image,field_date,field_fd_fellow_ims,field_fd_profile_status,field_fellow_introduction,field_fellow_the_new_idea,field_fellow_the_person,field_fellow_the_problem,field_fellow_the_strategy,field_link_organizations,field_other_social_media_links,field_fd_bio,field_phone,field_collaboration_status,field_tags,field_topics,field_fd_contact_list,field_region,field_email,field_fd_ui_language,field_fd_contact_preferences,drupal_internal__nid,field_fd_company_stakeholder_typ,field_fd_product_service_aim,field_fd_industry_sector_operati,field_fd_product_services_provid,field_fd_products_services_targe,field_fd_consider_companies_stak,field_fd_sell_products_services,field_fd_spoken_languages,field_fd_saved_posts,field_fd_saved_searches",
    ],
    ["filter[field_fd_profile_ready_for_fd]", 1],
    ["fields[media--image]", "thumbnail"],
    [
      "include",
      "field_media_profile_image,field_tags,field_topics,field_region,field_fd_contact_list,field_fd_contact_list.field_media_profile_image",
    ],
  ];
  url.search = new URLSearchParams(params).toString();

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, opts).then(async (res) => {
    const data = await res.json();
    if (res.status === 200) {
      return data;
    }
    throw new Error(`Couldn't get the fellows data`);
  });
};

export const getFellowNotificationPreferences = async () => {
  const token = await localStorage.getItem("token");
  const id = await localStorage.getItem("profile_uuid");

  if (!token || !id) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}/${id}`
  );

  var params = [
    [
      "fields[node--fellow]",
      "field_fd_email_notif_fd_to_org,field_fd_email_notif_marketing,field_fd_email_notif_org_to_fd,field_fd_email_notif_remider",
    ],
    ["filter[field_fd_profile_ready_for_fd]", 1],
  ];
  url.search = new URLSearchParams(params).toString();

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, opts).then(async (res) => {
    const data = await res.json();
    if (res.status === 200) {
      return data;
    }
    throw new Error(`Couldn't get the fellows notification preferences`);
  });
};

export const getOtherFellowsInfo = async (nid) => {
  const token = await localStorage.getItem("token");

  if (!token || !nid) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}`
  );
  var params = [
    ["filter[field_fd_profile_ready_for_fd]", 1],
    [
      "fields[node--fellow]",
      "title,field_fd_fellow_location,field_media_profile_image,field_date,field_fd_fellow_ims,field_fd_profile_status,field_fellow_introduction,field_fellow_the_new_idea,field_fellow_the_person,field_fellow_the_problem,field_fellow_the_strategy,field_link_organizations,field_other_social_media_links,field_fd_bio,field_phone,field_collaboration_status,field_tags,field_topics,field_fd_contact_list,field_region,field_email,field_fd_ui_language,field_fd_contact_preferences",
    ],
    ["fields[media--image]", "thumbnail"],
    [
      "include",
      "field_media_profile_image,field_tags,field_topics,field_region,field_fd_contact_list,field_fd_contact_list.field_media_profile_image",
    ],
    ["filter[data][condition][path]", "drupal_internal__nid"],
    ["filter[data][condition][operator]", "="],
    ["filter[data][condition][value]", nid],
  ];
  url.search = new URLSearchParams(params).toString();

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, opts)
    .then(async (res) => {
      let result = await res.json();
      if (res.status === 200) return result;
      return;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllWorkFields = async () => {
  const token = await localStorage.getItem("token");

  if (!token) return;

  const url = new URL(
    `${constants.BASE_URL_NO_LANG}${i18n.language}/${constants.JSONAPI}${constants.TAXONOMY}${constants.TOPICS}`
  );
  var params = [
    ["fields[taxonomy_term--topics]", "name,drupal_internal__tid"],
    ["page[limit]", 200],
  ];
  url.search = new URLSearchParams(params).toString();

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, opts)
    .then(async (res) => {
      const data = await res.json();
      if (res.status === 200) {
        let workFields = data.data.sort((a, b) =>
          a.attributes.name > b.attributes.name ? 1 : -1
        );
        return { ...data, data: workFields };
      }
      return;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllInterests = async () => {
  const token = await localStorage.getItem("token");

  if (!token) return;

  const url = new URL(
    `${constants.BASE_URL_NO_LANG}${i18n.language}/${constants.JSONAPI}${constants.TAXONOMY}${constants.TAGS}`
  );
  var params = [
    ["fields[taxonomy_term--tags]", "name"],
    ["page[limit]", 200],
  ];
  url.search = new URLSearchParams(params).toString();

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, opts)
    .then(async (res) => {
      const data = await res.json();
      if (res.status === 200) return data;
      return;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllSpokenLanguage = async () => {
  const token = await localStorage.getItem("token");

  if (!token) return;

  const url = new URL(
    `${constants.BASE_URL_NO_LANG}${i18n.language}/${constants.JSONAPI}${constants.TAXONOMY}${constants.TAGS}`
  );
  var params = [
    ["fields[field_fd_spoken_languages]", "name"],
    ["page[limit]", 200],
  ];
  url.search = new URLSearchParams(params).toString();

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, opts)
    .then(async (res) => {
      const data = await res.json();
      if (res.status === 200) return data;
      return;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllRegions = async () => {
  const token = await localStorage.getItem("token");

  if (!token) return;

  const url = new URL(
    `${constants.BASE_URL_NO_LANG}${i18n.language}/${constants.JSONAPI}${constants.TAXONOMY}${constants.REGIONS}`
  );

  var params = [["fields[taxonomy_term--regions]", "name"]];
  url.search = new URLSearchParams(params).toString();

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, opts)
    .then(async (res) => {
      const data = await res.json();
      if (res.status === 200) {
        let regions = data.data.sort((a, b) =>
          a.attributes.name > b.attributes.name ? 1 : -1
        );
        // Remove the East Asia, South Asia, Southeast Asia & Global Office
        // regions.
        regions = regions.filter(function (obj) {
          let invalidRegions = [
            "d93b501e-8a37-4c31-bf34-7db0a1ebcca1",
            "598a5113-cfb3-4a53-ac87-b5ebacdab6f7",
            "b92e8368-d132-4442-b60a-e11d1094b591",
            "f411cffc-9ec9-428f-a2c4-64d4313fd482",
          ];
          return invalidRegions.indexOf(obj.id) === -1;
        });
        return { ...data, data: regions };
      }
      return;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllCountriesWithSubdivisions = async () => {
  const token = await localStorage.getItem("token");

  if (!token) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.ADDRESS}${constants.ALL}`
  );

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, opts)
    .then(async (res) => {
      const data = await res.json();
      if (res.status === 200) {
        var allCountries = [];
        await Object.keys(data).forEach((key) => {
          let subdivisions = [];
          if (data[key] && data[key].subdivisions) {
            Object.keys(data[key].subdivisions).forEach((subKey) => {
              subdivisions.push({
                code: subKey,
                title: data[key].subdivisions[subKey],
              });
            });
          }
          subdivisions = subdivisions.sort((a, b) =>
            a.title > b.title ? 1 : -1
          );
          allCountries.push({
            code: key,
            title: data[key].name,
            subdivisions: subdivisions,
          });
        });
        allCountries = allCountries.sort((a, b) =>
          a.title > b.title ? 1 : -1
        );
        return { allCountries, data };
      }
      return;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllSubdivisions = async (country) => {
  const token = await localStorage.getItem("token");

  if (!token) return;

  if (!country) return;

  const url = `${constants.BASE_URL}${constants.ADDRESS}/${country}${constants.SUBDIVISIONS}`;

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, opts)
    .then(async (res) => {
      const data = await res.json();
      if (res.status === 200) {
        let subdivisions = [];
        await Object.keys(data).forEach((key) => {
          subdivisions.push({ code: key, title: data[key] });
        });
        return subdivisions;
      }
      return;
    })
    .catch((err) => {
      return err;
    });
};

//create a function with agrs for path,operatior and value and return the params to make more optimised
export const searchFellow = async (offset, searchDetails) => {
  const token = await localStorage.getItem("token");
  const loggedInUserId = await localStorage.getItem("profile_uuid");

  if (!loggedInUserId) return;

  if (!token) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.FELLOW_SEARCH_SEARCH_API}`
  );
  //chnages
  let title1 = "";
  let region1 = "";
  let country1 = "";
  let state1 = "";
  let city1 = "";
  let selectedTopics1 = [];
  let status1 = ""; // eslint-disable-line

  var params = [
    [
      "fields[node--fellow]",
      "title,field_fd_fellow_location,field_collaboration_status,field_media_profile_image,field_other_social_media_links,field_email,field_phone,drupal_internal__nid,field_fd_contact_preferences,field_other_social_media_links,field_fd_fellow_ims,field_fd_profile_ready_for_fd",
    ],
    ["sort", "title"],
    ["fields[media--image]", "thumbnail"],
    ["include", "field_media_profile_image"],
    ["filter[fd_ready]",1],
    ["filter[skipUser][condition][path]", "uuid"],
    ["filter[skipUser][condition][operator]", "NOT IN"],
    // ["filter[skipUser][condition][value]", loggedInUserId],
    ["page[offset]", offset || 0],
    ["page[limit]", 10],
  ];

  if (searchDetails?.searchKeyword) {
    params = [...params, ["filter[fulltext]", searchDetails.searchKeyword]];
    title1 = searchDetails.searchKeyword; //changes
  }

  if (searchDetails?.region) {
    params = [...params, ["filter[region_uuid]", searchDetails.region.id]];
    region1 = searchDetails?.region.attributes.name; //changes
  }

  if (searchDetails?.country) {
    params = [...params, ["filter[country]", searchDetails.country.code]];
    country1 = searchDetails?.country.title; //changes
  }

  if (searchDetails?.state) {
    params = [...params, ["filter[state]", searchDetails.state.code]];
    state1 = searchDetails?.state; //changes
  }
  if (searchDetails?.city) {
    params = [...params, ["filter[city]", searchDetails.city]];
    city1 = searchDetails?.city; //changes
  }

  if (searchDetails?.seekingCollaboration) {
    params = [...params, ["filter[collab_ready]", 1]];
  }

  if (searchDetails?.topics && searchDetails.topics.length > 0) {
    params = [
      ...params,
      [`filter[topics][condition][path]`, `topic_uuid`],
      [`filter[topics][condition][operator]`, `IN`],
    ];
    searchDetails.topics.forEach((topic, index) => {
      selectedTopics1.push(topic.attributes.name); //changes
      //default field-topic params
      params = [
        ...params,
        [`filter[topics][condition][value][${index}]`, topic.id],
      ];
    });
  }
  if (searchDetails?.status) {
    params = [...params, ["filter[status]", searchDetails.status]];
    status1 = searchDetails?.city; //changes
  }

  url.search = new URLSearchParams(params).toString();

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  var obj = {
    title: title1,
    region: region1,
    country: country1,
    state: state1,
    city: city1,
    selectedTopics: selectedTopics1.toString(),
  };
  return fetch(url, opts)
    .then(async (res) => {
      const data = await res.json();
      if (res.status === 200) data["obj"] = obj;
      return data;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllFellows = async () => {
  const token = await localStorage.getItem("token");
  const loggedInUserId = await localStorage.getItem("profile_uuid");
  const testFellow1 = '6ead1905-8693-4aec-b84a-f9d5aeba4c9a';
  const testFellow2 = 'ad4e435e-ec20-44ba-8c6d-ab02f2b8a353';
  const stafffellow = '38d67331-96e8-4b7c-af83-bff8c98d4f6c';

  if (!loggedInUserId) return;

  if (!token) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}`
  );
  var params = [
    ["filter[field_fd_profile_ready_for_fd]", 1],
    [
      "fields[node--fellow]",
      "title,field_fd_fellow_location,field_collaboration_status,field_media_profile_image,drupal_internal__nid",
    ],
    ["fields[media--image]", "thumbnail"],
    ["include", "field_media_profile_image"],
    ["filter[excludeLoggedInUser][condition][path]", "id"],
    ["filter[excludeLoggedInUser][condition][operator]", "NOT IN"],
    ["filter[excludeLoggedInUser][condition][value]", loggedInUserId],
    ["filter[excludeTestFellow1][condition][path]", "id"],
    ["filter[excludeTestFellow1][condition][operator]", "NOT IN"],
    ["filter[excludeTestFellow1][condition][value]", testFellow1],
    ["filter[excludeTestFellow2][condition][path]", "id"],
    ["filter[excludeTestFellow2][condition][operator]", "NOT IN"],
    ["filter[excludeTestFellow2][condition][value]", testFellow2],
    ["filter[excludeTestFellow3][condition][path]", "id"],
    ["filter[excludeTestFellow3][condition][operator]", "NOT IN"],
    ["filter[excludeTestFellow3][condition][value]", stafffellow],
    ["page[offset]", 0],
    ["page[limit]", 20],
  ];
  url.search = new URLSearchParams(params).toString();

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, opts)
    .then(async (res) => {
      let result = await res.json();
      if (res.status === 200) return result;
      return;
    })
    .catch((err) => {
      return err;
    });
};

export const getFellowsContactList = async () => {
  const token = await localStorage.getItem("token");
  const loggedInUserId = await localStorage.getItem("profile_uuid");

  if (!loggedInUserId || !token) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}/${loggedInUserId}/field_fd_contact_list`
  );
  var params = [
    ["filter[field_fd_profile_ready_for_fd]", 1],
    [
      "fields[node--fellow]",
      "field_fd_fellow_location,title,field_media_profile_image,field_collaboration_status,field_other_social_media_links,field_phone,field_email,drupal_internal__nid,field_fd_contact_preferences,field_other_social_media_links,field_fd_fellow_ims",
    ],
    ["fields[media--image]", "thumbnail"],
    ["include", "field_media_profile_image"],
  ];
  url.search = new URLSearchParams(params).toString();

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, opts)
    .then(async (res) => {
      let result = await res.json();
      if (res.status === 200) return result;
      return;
    })
    .catch((err) => {
      return err;
    });
};

export const getPolicyData = async (uuid) => {
  if (!uuid) return;

  const url = `${constants.BASE_URL_NO_LANG}${i18n.language}/${constants.JSONAPI}${constants.NODE}/page/${uuid}`;

  const opts = {
    method: "GET",
  };
  return fetch(url, opts)
    .then(async (res) => {
      let result = await res.json();
      if (res.status === 200) {
        return result?.data?.attributes?.body?.processed;
      }
      return;
    })
    .catch((err) => {
      return err;
    });
};
//--changes for activity Feed--
export const getActivityFeed = async (count) => {
  const token = await localStorage.getItem("token");
  const loggedInUserId = await localStorage.getItem("profile_uuid");

  if (!loggedInUserId) return;

  if (!token) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FD_POST}`
  );
  var params = [
    [
      "fields[node--fd_post]",
      "title,status,promote,field_tags,field_topics,field_location,drupal_internal__nid,field_post_content,field_post_link,created,uid",
    ],
    ["fields[taxonomy_term--topics]", "name"],
    ["fields[taxonomy_term--tags]", "name"],
    ["fields[user--user]", "field_profile_image,field_fellow_profile"],
    [
      "fields[node--fellow]",
      "field_fellow_profile,drupal_internal__nid,title,field_other_social_media_links,field_fd_fellow_ims,field_fd_contact_preferences,field_email,field_phone",
    ], //to get fellow drupal_internal__nid
    [
      "include",
      "field_tags,field_topics,uid,uid.field_profile_image,uid.field_fellow_profile",
    ],
    ["fields[media--image]", "thumbnail"],
    ["page[offset]", 0],
    ["page[limit]", count === undefined || count === 0 ? 2 : count],
    ["sort[post-sort][path]", "created"],
    ["sort[post-sort][direction]", "DESC"],
  ];
  url.search = new URLSearchParams(params).toString();

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, opts)
    .then(async (res) => {
      const data = await res.json();

      if (res.status === 200) return data;

      return;
    })
    .catch((err) => {
      return err;
    });
};
//view edit post
export const getViewActivityFeed = async (id) => {
  const token = await localStorage.getItem("token");
  if (!token) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FD_POST}/${id}`
  );
  var params = [
    [
      "fields[node--fd_post]",
      "title,field_location,field_post_content,field_post_link,field_tags,field_topics,uid",
    ],

    ["fields[user--user]", "field_profile_image"],

    ["include", "uid,uid.field_profile_image,uid.field_fellow_profile"],
    ["fields[node--fellow]", "drupal_internal__nid,title"], //to get fellow drupal_internal__nid and title
    ["fields[media--image]", "thumbnail"],
  ];
  url.search = new URLSearchParams(params).toString();

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, opts)
    .then(async (res) => {
      const data = await res.json();
      if (res.status === 200) return data;
      return;
    })
    .catch((err) => {
      return err;
    });
};
//api for location
export const getLocation = async (name) => {
  const url = new URL(
    "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=" +
      name +
      "&types=geocode&key=" +
      process.env.REACT_APP_API_KEY
  );
  const opts = {
    method: "GET",
    headers: {
      "Content-Type": "application/vnd.api+json",
    },
  };

  return fetch(url, opts)
    .then(async (res) => {
      const data = await res.json();
      if (res.status === 200) return data;
      return;
    })
    .catch((err) => {
      return err;
    });
};
//get save post id list
export const getSavedPostIdList = async (fellowId) => {
  const token = await localStorage.getItem("token");
  if (!token) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}/${fellowId}${constants.RELATIONSHIPS}/field_fd_saved_posts`
  );
  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
  };

  return fetch(url, opts)
    .then(async (res) => {
      const data = await res.json();
      if (res.status === 200) return data;
      return;
    })
    .catch((err) => {
      return err;
    });
};
//api for Organization details
export const getOrganizationDetails = async (fellowId) => {
  const token = await localStorage.getItem("token");
  if (!token) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}/${fellowId}/field_fd_projects`
  );
  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
  };

  return fetch(url, opts)
    .then(async (res) => {
      const data = await res.json();
      if (res.status === 200) return data;
      return;
    })
    .catch((err) => {
      return err;
    });
};
