import React from "react";

export const ContactsIcon = ({ isActive }) => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0H2C0.9 0 0.0100002 0.9 0.0100002 2L0 18L7 15L14 18V2C14 0.9 13.1 0 12 0Z"
      fill={isActive ? "#4155EE" : "black"}
      fillOpacity={isActive ? null : "0.6"}
    />
  </svg>
);
