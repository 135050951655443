import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "./contactInfo.scss";
import CustomButton from "../../basic-components/button";

const InfoDialog = (props) => {
  const { open, handleClose, t } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="info-dialog"
    >
      <DialogTitle id="alert-dialog-title" className="head-text">
        {t("contact_info.who_will_see")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="content">
          {t("contact_info.who_will_see_desc")}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="button">
        <CustomButton onClick={handleClose} text="OK" />
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
