import React from "react";
import Cookies from "js-cookie";
import "./cookieBanner.scss";
import CustomButton from "../button";
import { useTranslation } from "react-i18next";
import Cross from "../../../images/cross-icon.svg";
import { Link } from "react-router-dom";

const CookieBanner = (props) => {
  const { isCookiePolicyAccepted, setUserConsent } = props;
  const { t } = useTranslation(["user-auth"]);

  const setCookie = (value) => {
    Cookies.set("isCookiePolicyAccepted", value, { expires: 100 });
    setUserConsent(value);
  };

  return (
    isCookiePolicyAccepted === undefined && (
      <div className="cookies">
        <div className="header">
          <span className="text">{t("cookie.title")}</span>
          <img
            src={Cross}
            className="cross"
            onClick={() => setCookie(false)}
            alt="cancle cookies"
          />
        </div>
        <p className="content">
          {t("cookie.description")}{" "}
          <Link
            className="cookie-policy"
            to={{
              pathname: `/policies/46978`,
              state: {
                id: "dc8f850e-e2d7-43fa-a082-bc51219aead1",
                title: t("cookie.cookie_policy.lable"),
              },
            }}
          >
            {t("cookie.cookie_policy.lable")}
          </Link>
        </p>
        <div className="cookie-btns">
          <CustomButton
            onClick={() => setCookie(false)}
            text={t("cookie.decline_button.lable")}
            classes="decline"
          />
          <CustomButton
            onClick={() => setCookie(true)}
            text={t("cookie.accpet_buttton.lable")}
            classes="accept"
            variant="contained"
          />
        </div>
      </div>
    )
  );
};

export default CookieBanner;
