const url = process.env.REACT_APP_API_URL;
const urlNoLang = process.env.REACT_APP_API_URL_NO_LANG;

const constants = Object.freeze({
  BASE_URL: url,
  BASE_URL_NO_LANG: urlNoLang,
  USER_OTP: "user/otp",
  GENERATE_OTP: "/generate",
  VERIFY_OTP: "/verify",
  JSONAPI: "jsonapi",
  RESTAPI: "restapi",
  MEDIA: "/media/image",
  FIELD_IMAGE_FILE: "/field_media_image",
  NODE: "/node",
  FELLOW: "/fellow",
  RELATIONSHIPS: "/relationships",
  TAXONOMY: "/taxonomy_term",
  TOPICS: "/topics",
  TAGS: "/tags",
  REGIONS: "/regions",
  ADDRESS: "address/repository",
  ALL: "/all",
  COUNTRIES: "/countries",
  SUBDIVISIONS: "/subdivisions",
  FELLOW_SEARCH: "/views/fd_search/default",
  FELLOW_SEARCH_SEARCH_API: "/index/fellow_directory",
  FD_POST: "/fd_post",
  PARAGRAPH: "/paragraph",
  ORGANIZATION_AND_PROJECT: "/organizations_and_projects",
  SENDMAIL: "/sendmail",
});

export default constants;
