import React from "react";
import TextField from "@material-ui/core/TextField";
import "./textfield.scss";
const Textfield = (props) => {
  const {
    label,
    value,
    onChange,
    className,
    name,
    id,
    onKeyDown,
    multiline,
    index,
    error,
    inputProps,
    errorMsg,
    isDisabled,
  } = props;
  return (
    <TextField
      error={error}
      id={id}
      label={error ? "Incorrect Field" : label}
      variant="filled"
      value={value}
      onChange={(event) => onChange(event, index)}
      className={`textfield ${className} ${error ? "error" : ""} ${
        value ? "filled" : ""
      } `}
      name={name}
      onKeyDown={onKeyDown}
      multiline={multiline}
      helperText={error && errorMsg ? errorMsg : error && "Incorrect Field."}
      // inputProps={inputProps}
      inputProps={{
        ...inputProps,
        "data-testid": id,
      }}
      disabled={isDisabled}
    />
  );
};

export default Textfield;
