import React, { useState, useEffect } from "react";
import "./saveSearch.scss";
import { getLocalStorage } from "../../../services/helper-functions";
import {
  getAllRegions,
  getAllWorkFields,
  getFellowProfile,
} from "../../../services/fellow.getService";
import { saveSearches } from "../../../services/fellow.patchService";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGlobalDispatch, useGlobalState } from "../../context/globalContext";
import { useTranslation } from "react-i18next";
import SkeletonRowLoader from "../../Loaders/skeletonRowLoader";
import DeleteIcon from "@mui/icons-material/Delete";
toast.configure();
const SaveSearch = (props) => {
  const globalDispatch = useGlobalDispatch();
  const { regions, fieldsOfWork } = useGlobalState();
  const { t } = useTranslation(["savedLists"]);
  const [areDataLoading, setAreDataLoading] = useState(true);
  useEffect(() => {
    //end changes
    if (!fieldsOfWork?.length) {
      getAllWorkFields().then((res) => {
        if (res)
          globalDispatch({
            type: "setListData",
            payload: { name: "fieldsOfWork", value: res.data || [] },
          });
      });
    }

    //end changes
    if (!regions?.length) {
      getAllRegions().then((res) => {
        if (res)
          globalDispatch({
            type: "setListData",
            payload: { name: "regions", value: res.data || [] },
          });
      });
    }
  }, [fieldsOfWork, regions, globalDispatch]);

  const [saveSearchDataList, setSaveSearchDataList] = useState([]);
  useEffect(() => {
    //changes for save search
    var list = [];
    let id = getLocalStorage("profile_uuid");
    getFellowProfile(id).then((res) => {
      if (res) {
        list = res.data.attributes.field_fd_saved_searches;
        setAreDataLoading(false);
        setSaveSearchDataList(list);
      }
    });
  }, []);

  return (
    <div className="settings-content">
      <div className="account-settings">
        {areDataLoading &&
          Array(5)
            .fill(null)
            .map((item, index) => {
              return <SkeletonRowLoader key={index} />;
            })}
        {saveSearchDataList.length > 0 ? (
          saveSearchDataList.map((savedSearch, index) => {
            let savedSearchDetail = JSON.parse(savedSearch);
            let searchKeywordLabel = savedSearchDetail.searchKeyword
              ? '"' + savedSearchDetail.searchKeyword + '"'
              : "";
            let searchTopicsLabel = "";
            savedSearchDetail.topics.forEach((item, index, array) => {
              if (index === 0) {
                searchTopicsLabel = searchTopicsLabel + item.attributes.name;
              } else if (index === array.length - 1) {
                searchTopicsLabel =
                  searchTopicsLabel + " or " + item.attributes.name;
              } else {
                searchTopicsLabel =
                  searchTopicsLabel + ", " + item.attributes.name;
              }
            });

            let searchCityLabel = savedSearchDetail?.city
              ? savedSearchDetail.city
              : "";
            let searchStateLabel = savedSearchDetail?.state?.title
              ? savedSearchDetail.state.title
              : "";
            let searchCountryLabel = savedSearchDetail?.country?.title
              ? savedSearchDetail.country.title
              : "";
            let searchRegionLabel = "";
            if (savedSearchDetail?.region?.id) {
              regions.some((item) => {
                if (item.id === savedSearchDetail.region.id) {
                  searchRegionLabel = item.attributes.name;
                  return true;
                } else {
                  return false;
                }
              });
            }
            let searchLocationArray = [
              searchCityLabel,
              searchStateLabel,
              searchCountryLabel,
              searchRegionLabel,
            ].filter((a) => a);

            let searchLocationLabel = "";
            searchLocationLabel =
              searchLocationArray.length > 0
                ? (searchLocationLabel = searchLocationArray.join(", "))
                : "";

            if (searchKeywordLabel || searchLocationLabel) {
              searchTopicsLabel = searchTopicsLabel
                ? ' and Works In "' + searchTopicsLabel + '"'
                : "";
            } else {
              searchTopicsLabel = searchTopicsLabel
                ? 'Works In "' + searchTopicsLabel + '"'
                : "";
            }

            if (searchKeywordLabel) {
              searchLocationLabel = searchLocationLabel
                ? ' In "' + searchLocationLabel + '"'
                : "";
            } else {
              searchLocationLabel = searchLocationLabel
                ? 'In "' + searchLocationLabel + '"'
                : searchLocationLabel;
            }

            let searchLabel =
              searchKeywordLabel + searchLocationLabel + searchTopicsLabel;

            return (
              <div className="saved-list-saved-content" key={index}>
                <div className="content-box">
                  <button className="delete-btn text-hide" onClick={() => {
                    let temp = [];
                    saveSearchDataList.splice(index, 1);
                    saveSearches(saveSearchDataList).then((res) => {
                      if (res) {
                        temp = res.data.attributes.field_fd_saved_searches;
                        setSaveSearchDataList(temp);
                        toast.success("Deleted!", {
                          position: toast.POSITION.BOTTOM_CENTER,
                        });
                      }
                    });
                  }}>
                    Delete
                    <DeleteIcon color="danger"  />
                  </button>
                  <div style={{ width: "80%" }}>
                    <Link
                      className="image-wrap"
                      to={{
                        pathname: "/search",
                        state: { searchData: savedSearchDetail },
                      }}
                    >
                      {searchLabel}
                    </Link>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div
            hidden={saveSearchDataList?.data?.length > 0 || areDataLoading}
            style={{
              backgroundColor: "#F5F5F5",
              padding: "2% 5% 2% 5%",
              margin: "5%",
            }}
          >
            <div>
              <b
                style={{
                  textAlign: "initial",
                  color: "gray",
                }}
              >
                {t("saved_searches.welcome_tip.title")}
              </b>
              <p
                style={{
                  textAlign: "initial",
                  color: "gray",
                }}
              >
                {t("saved_searches.welcome_tip.message")}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SaveSearch;
