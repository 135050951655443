import React from "react";
import CustomButton from "../../basic-components/button";
import "./footer.scss";

const Footer = (props) => {
  const { onSave, onCancel, t, loader } = props;
  return (
    <div className="settings-footer">
      <div className="wrapper">
        <CustomButton
          onClick={onSave}
          text={t("common:save_button")}
          classes="btn savechanges"
          variant="contained"
          isLoading={loader}
        />
        <CustomButton
          onClick={onCancel}
          text={t("common:cancel_button")}
          classes="cancel-btn cancelchanges"
        />
      </div>
    </div>
  );
};

export default Footer;
