import profile1 from "../images/defaultProfiles/profile1.svg";
import profile2 from "../images/defaultProfiles/profile2.svg";
import profile3 from "../images/defaultProfiles/profile3.svg";
import profile4 from "../images/defaultProfiles/profile4.svg";
import profile5 from "../images/defaultProfiles/profile5.svg";
import profile6 from "../images/defaultProfiles/profile6.svg";
import profile7 from "../images/defaultProfiles/profile7.svg";
import profile8 from "../images/defaultProfiles/profile8.svg";
import profile9 from "../images/defaultProfiles/profile9.svg";
import profile10 from "../images/defaultProfiles/profile10.svg";
import profile11 from "../images/defaultProfiles/profile11.svg";
import profile12 from "../images/defaultProfiles/profile11.svg";

export const profiles = [
  profile1,
  profile2,
  profile3,
  profile4,
  profile5,
  profile6,
  profile7,
  profile8,
  profile9,
  profile10,
  profile11,
  profile12,
];

// media types
export const medias = [
  { title: "Facebook", url: "https://www.facebook.com/" },
  { title: "Twitter", url: "https://www.twitter.com/" },
  { title: "Linkedin", url: "https://www.linkedin.com/" },
  { title: "Instagram", url: "https://www.instagram.com/" },
  { title: "Pinterest", url: "https://www.pinterest.com/" },
  { title: "Vimeo", url: "https://www.vimeo.com/" },
  { title: "Flickr", url: "https://www.flickr.com/" },
  { title: "Youtube", url: "https://www.youtube.com/" },
  { title: "Tumblr", url: "https://tumblr.com/" },
  { title: "Other", url: "" },
];

//instant messanger types
export const instantMessangers = [
  { title: "WhatsApp", url: "" },
  { title: "Hangout", url: "" },
  { title: "Telegram", url: "" },
  { title: "Skype", url: "" },
  { title: "Facebook Messanger", url: "" },
];

//Allowed Language preferances
export const allowedLaguages = [
  { title: "Italian", code: "it" },
  { title: "Korean", code: "ko" },
  { title: "English", code: "en" },
  { title: "Czech", code: "cs" },
  { title: "Slovak", code: "sk" },
  { title: "Polish", code: "pl" },
  { title: "Romanian", code: "ro" },
  { title: "Spanish", code: "es" },
  { title: "French", code: "fr" },
  { title: "Japanese", code: "ja" },
  { title: "Portuguese", code: "pt" },
  { title: "Portuguese, Brazil", code: "pt-BR" },
  { title: "German", code: "de" },
  { title: "Arabic", code: "ar" },
  { title: "Bahasa Indonesia", code: "id" },
];
