import React, { useState } from "react";
import TechLife from "../../images/TechLifeCommunication.svg";
import CustomButton from "../basic-components/button";
import "./support.scss";
import { useTranslation } from "react-i18next";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {createContact} from "../../services/fellow.patchService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const options = ['I want to send an email message to my Ashoka Rep', 'I need tech support', 'I want to flag a sensitive code of conduct (or other) issue', 'I have feedback to improve the tool'];

const Support = () => {
  const { t } = useTranslation(["supportPage"]);
  const [value, setValue] = useState(options[0]);
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = () => {
    setLoader(true);
    if (message !== "") {
      let createContactData = [];
      createContactData = {
        help: value,
        message: message,
      };
      createContact(createContactData).then((res) => {
        if (res) {
          toast.success(res.data, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          setLoader(false);
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          setLoader(false);
        }
      });
    }
    setValue(options[0]);
    setMessage('');
  };

  return (
    <div className="support-page">
      <div className="container-wrapper">
        <div className="container">
          <h1 className="head-text">{t("supportPage:page_title")}</h1>
          <img src={TechLife} alt="Tech life communication" className="image" />

          <form
            method="POST"
            target="_blank"
            className="contact-wrapper"
          >
            <div className="contact-control">
              <Autocomplete
                value={value}
                name="Help"
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                id="controllable-states-demo"
                options={options}
                className="autocomplete-feilds"
                required
                renderInput={(params) => <TextField {...params} label="What kind of help do you need?" variant="filled" />}
              />
            </div>
            <div className="contact-control">
              <TextField
                id="message"
                label="Message"
                rows={4}
                variant="filled"
                name="Message"
                onChange={handleChange}
                value={message}
                multiline={true}
                className="message"
                required
              />
            </div>
            <CustomButton
              classes="code-btn"
              text="Send"
              type="submit"
              onClick={handleSubmit}
              isLoading={loader}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Support;
