import React from "react";

const ProfileStateContext = React.createContext();
const ProfileDispatchContext = React.createContext();

let initialState = {
  userProfile: {
    region: null,
    country: null,
    city: "",
    state: null,
    work: "",
    bio: "",
    image: "",
    status: "",
    collaborationStatus: false,
    selectedWorkFields: [],
    selectedInterests: [],
    selectedSpokenLanguage: []
  },
  organizations: [{ title: "", uri: "" }],
};

function profileReducer(state, action) {
  switch (action.type) {
    case "setUserProfile":
      let userProfile = { ...state.userProfile };
      userProfile[action.payload.name] = action.payload.value;
      return {
        ...state,
        userProfile: userProfile,
      };

    case "setFieldsOfWork":
      return {
        ...state,
        fieldsOfWork: action.payload,
      };

    case "removeWorkFeild":
      let fieldsOfWork = [...state.fieldsOfWork];
      fieldsOfWork.splice(action.payload, 1);
      return {
        ...state,
        fieldsOfWork: fieldsOfWork,
      };

    case "updateOrgDetails":
      return {
        ...state,
        organizations: action.payload,
      };

    case "setImageProfile":
      let result = { ...state.userProfile };
      result.image = action.payload;
      return {
        ...state,
        userProfile: result,
      };

    case "setDetails":
      return {
        ...action.payload,
      };

    default:
      return { ...state };
  }
}

function ProfileProvider({ children }) {
  const [state, dispatch] = React.useReducer(profileReducer, initialState);

  return (
    <ProfileStateContext.Provider value={state}>
      <ProfileDispatchContext.Provider value={dispatch}>
        {children}
      </ProfileDispatchContext.Provider>
    </ProfileStateContext.Provider>
  );
}

function useProfileState() {
  const context = React.useContext(ProfileStateContext);
  if (context === undefined) {
    throw new Error("useProfileState must be used within a ProfileProvider");
  }
  return context;
}

function useProfileDispatch() {
  const context = React.useContext(ProfileDispatchContext);
  if (context === undefined) {
    throw new Error("useProfileDispatch must be used within a ProfileProvider");
  }
  return context;
}

export { ProfileProvider, useProfileState, useProfileDispatch };
