import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import "./style.scss";

const SkeletonColLoader = () => {
  return (
    <div className="fellow-scroller-item">
      <div>
        <Skeleton
          animation="wave"
          variant="circle"
          width={80}
          height={80}
          style={{ marginBottom: 10 }}
        />
      </div>
      <div>
        <Skeleton
          animation="wave"
          height={10}
          width="100%"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={10}
          width="90%"
          style={{ marginBottom: 6 }}
        />
      </div>
    </div>
  );
};

export default SkeletonColLoader;
