import "./settings.scss";
import React, { useEffect, useState } from "react";
import SaveSearch from "./saveSearch";
import ActivityFeed from "./activityFeed";
import ContactList from "./contactList";
// import { SettingsIcon } from "../SvgComponents/settings";
import { getFellowNotificationPreferences } from "../../services/fellow.getService";
import { useGlobalDispatch } from "../context/globalContext";
import { useTranslation } from "react-i18next";

const SavedList = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation([
    "forms",
    "settings",
    "common",
    "toastMessages",
    "questionnaires",
  ]);
  const {
    // areCountriesInJsonLoading,
    // isFellowProfileLoading,
    // isDefaultCountryCodeLoading,
    contactList,
    setContactList,
    removeFellowFromContactList,
    loaders,
  } = props;
  const emailNotifications = [
    {
      lable: t("settings:notifications.email_notifications.type1"),
      fieldName: "field_fd_email_notif_remider",
      notificationStatus: false,
    },
    {
      lable: t("settings:notifications.email_notifications.type2"),
      fieldName: "field_fd_email_notif_fd_to_org",
      notificationStatus: false,
    },
    {
      lable: t("settings:notifications.email_notifications.type3"),
      fieldName: "field_fd_email_notif_org_to_fd",
      notificationStatus: false,
    },
    {
      lable: t("settings:notifications.email_notifications.type4"),
      fieldName: "field_fd_email_notif_marketing",
      notificationStatus: false,
    },
  ];

  const [notifUpdates, setNotifUpdates] = useState([...emailNotifications]);
  const globalDispatch = useGlobalDispatch();

  const renderContent = (t) => {
    switch (activeTab) {
      case 0:
        return (
          <ContactList
            t={t}
            // areCountriesInJsonLoading={areCountriesInJsonLoading}
            // isFellowProfileLoading={isFellowProfileLoading}
            // isDefaultCountryCodeLoading={isDefaultCountryCodeLoading}
            loaders={loaders}
            contactList={contactList}
            removeFellowFromContactList={removeFellowFromContactList}
            setContactList={setContactList}
          />
        );
      case 1:
        return <SaveSearch t={t} />;
      case 2:
        return (
          <ActivityFeed
            t={t}
            notifUpdates={notifUpdates}
            setNotifUpdates={setNotifUpdates}
          />
        );
      default:
        return (
          <ContactList
            t={t}
            loaders={loaders}
            contactList={contactList}
            removeFellowFromContactList={removeFellowFromContactList}
            setContactList={setContactList}
            // areCountriesInJsonLoading={areCountriesInJsonLoading}
            // isFellowProfileLoading={isFellowProfileLoading}
            // isDefaultCountryCodeLoading={isDefaultCountryCodeLoading}
          />
        );
    }
  };

  useEffect(() => {
    getFellowNotificationPreferences()
      .then((res) => {
        if (res) {
          const data = res?.data?.attributes;
          let state = [...notifUpdates];
          data &&
            Object.keys(data).forEach((key) => {
              state.forEach((item) => {
                if (item.fieldName === key) item.notificationStatus = data[key];
              });
            });
          setNotifUpdates(state);
          globalDispatch({
            type: "setListData",
            payload: { name: "notificationPrefs", value: state },
          });
        }
      })
      .catch((err) => {
        console.log("error in fetching fellow notification preferences :", err);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="settings">
      {/* tabs and main menu  */}
      <div className="settings-header-wrapper">
        {/* <div className="settings-icon">
          <SettingsIcon opacity="1" />
          <div className="settings-title">{t("common:settings")}</div>
        </div> */}
        <div className="settings-header">
          <div
            className={`menu ${activeTab === 0 ? "active" : ""}`}
            onClick={() => setActiveTab(0)}
          >
            CONTACTS
          </div>
          <div
            className={`menu ${activeTab === 1 ? "active" : ""}`}
            onClick={() => setActiveTab(1)}
          >
            SEARCHES
          </div>
          <div
            className={`menu ${activeTab === 2 ? "active" : ""}`}
            onClick={() => setActiveTab(2)}
          >
            ACTIVITY FEED
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>{renderContent(t)}</div>
    </div>
  );
};

export default SavedList;
