import React from "react";
import "./languagePicker.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { allowedLaguages } from "../../../services/constantValues";
import TextField from "@material-ui/core/TextField";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  popper: {
    maxWidth: "fit-content",
    boxSizing: 'border-box',
    overflowX: "hidden",
  },
});

const PopperMy = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom-end" />;
};

// sort by title
allowedLaguages.sort(function (a, b) {
  const nameA = a.title.toUpperCase(); // ignore upper and lowercase
  const nameB = b.title.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
});

const LanguagePicker = (props) => {
  const { setLang, lang } = props;

  return (
    <div>
      <Autocomplete
        options={allowedLaguages}
        value={lang || null}
        disableClearable={true}
        PopperComponent={PopperMy}
        className="lang-picker"
        name="lang"
        getOptionLabel={(option) => option.code}
        onChange={(event, value) => setLang(value)}
        renderOption={(option) => (
          <React.Fragment>
            <span>{option.title}</span>
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="lang"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </div>
  );
};

export default withStyles(styles)(LanguagePicker);
