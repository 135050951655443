import React, { useState } from "react";
import Close from "../../../images/close-textfield.svg";
import Textfield from "../textfield";
import CustomCheckbox from "../../basic-components/checkbox";
import "./addorganization.scss";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
const AddNewOrganization = (props) => {
  const { deleteOrganization, index, onChange, data, errors, t } = props;
  const [selectedStartDate, handleStartDateChange] = useState(
    new Date(
      new Date(
        data.attributes === undefined
          ? ""
          : data.attributes.field_project_start_date
      )
    )
  );
  const [selectedEndDate, handleEndDateChange] = useState(
    new Date(
      new Date(
        data.attributes === undefined
          ? ""
          : data.attributes.field_project_end_date
      )
    )
  );
  const onKeypress = (event, index) => {
    onChange(event, index);
  };

  const randomId = function(length = 6) {
    return Math.random().toString(36).substring(2, length+2);
  };

  return data.attributes !== undefined ? (
    <div className="org-block">
      <Textfield
        label={t("profile.form_fields.organizations.url.lable")}
        id={t('organization-name-') + randomId()}
        color="primary"
        name="title"
        className="name"
        value={
          data.attributes.field_project_name === null
            ? ""
            : data.attributes.field_project_name
        }
        onChange={(event) => onChange(event, index)}
        onKeyDown={(event) => onKeypress(event, index)}
      />
      <CustomCheckbox
        //name={item.title}
        name="checkbox"
        label="Primary"
        onChange={(event) => onChange(event, index)}
        isChecked={data.attributes.field_project_primary}
        key={index}
      />
      <div className="mobile-info">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            // className={classes.root}
            autoOk
            variant="inline"
            name="startDate"
            inputVariant="filled"
            label="Start Date"
            format="MMM yyyy"
            value={selectedStartDate}
            id={t('start-date-') + randomId()}
            InputProps={{
              disableUnderline: false,
            }}
            InputAdornmentProps={{ position: "end" }}
            onChange={(date) => {
              onChange(date, index, "startDate");
              handleStartDateChange(date);
            }}
          />
          <KeyboardDatePicker
            minDate={new Date(selectedStartDate)}
            fullWidth
            autoOk
            variant="inline"
            inputVariant="filled"
            InputProps={{
              disableUnderline: false,
            }}
            InputLabelProps={{ shrink: true }}
            name="endDate"
            id={t('date-picker-dialog end-date-') + randomId()}
            label="End Date"
            format="MMM yyyy"
            value={selectedEndDate}
            onChange={(date = new Date()) => {
              onChange(date, index, "endDate");
              handleEndDateChange(date);
            }}
          />
        </MuiPickersUtilsProvider>
      </div>

      <Textfield
        // error={errors?.isErorr || false}
        // errorMsg={errors?.isErorr ? errors.msg : ""}
        label="Description"
        color="primary"
        multiline={true}
        name="description"
        id={t('description-') + randomId()}
        // className={`website ${errors?.isErorr ? "error" : ""}`}
        value={
          data.attributes.field_project_description === null
            ? ""
            : data.attributes.field_project_description.value
        }
        onChange={(event) => onChange(event, index)}
        onKeyDown={(event) => onKeypress(event, index)}
      />
      <Textfield
        error={errors?.isErorr || false}
        errorMsg={errors?.isErorr ? errors.msg : ""}
        label={t("profile.form_fields.organizations.website.lable")}
        color="primary"
        name="uri"
        id={t('website-') + randomId()}
        className={`website ${errors?.isErorr ? "error" : ""}`}
        value={
          data.attributes.field_project_website === null
            ? ""
            : data.attributes.field_project_website.uri
        }
        onChange={(event) => onChange(event, index)}
        onKeyDown={(event) => onKeypress(event, index)}
      />
      <div className="close-wrapper" onClick={() => deleteOrganization(index)} id="close">
        <img
          className="close-icon"
          src={Close}
          alt="close organization structure"
        />
      </div>
    </div>
  ) : null;
};

export default AddNewOrganization;
