import React, { useState, useEffect } from "react";
import "./drawer.scss";
import { useGlobalState } from "../../context/globalContext";
import Drawer from "@material-ui/core/Drawer";
import { DashboardIcon } from "../../SvgComponents/dashboard";
import { SupportIcon } from "../../SvgComponents/support";
import { ProfileIcon } from "../../SvgComponents/profile";
import { SettingsIcon } from "../../SvgComponents/settings";
import { ResourceIcon } from "../../SvgComponents/resource";
import { ContactsIcon } from "../../SvgComponents/mycontacts";
import { ReactComponent as PolicyIcon } from "../../../images/policy-icon.svg";
import SignOut from "../../../images/signOut.svg";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import promptImage from "../../../images/Open Peeps Bust.svg";
import Button from "@mui/material/Button";

// var deferredPrompt; //--for PWA prompt--
const NavDrawer = (props) => {
  const { toggleDrawer, openDrawer, updateLogin } = props;
  const { userImage, currentProfileImg, fellowData } = useGlobalState();
  const history = useHistory();
  const { t } = useTranslation(["headerAndDashboard"]);
  const { i18n } = useTranslation();
  // const [checkBrowser, setCheckBrowser] = useState(false);
  // const [showInstallMessage, setShowInstallMessage] = useState(false);

  const updateNavigation = (route) => {
    history.push(`/${route}`);
    toggleDrawer(false);
  };

  const logout = async () => {
    let keys = Object.keys(localStorage);
    if (keys.length > 0) {
      keys.forEach((key) => {
        if (key !== "i18nextLng") localStorage.removeItem(key);
      });
    }
    updateLogin(false);
    toggleDrawer(false);
    history.push("/");
  };

  //-----------changes for PWA prompt-----------//
  // const [installable, setInstallable] = useState(true);
  // useEffect(() => {
  //   // var temp = navigator.userAgent.match(/chrome|chromium|crios|safari|iphone|ipad|ipod/i);
  //   // if (temp === undefined) {
  //   //   setCheckBrowser(false);
  //   // } else if (temp[0] === "Chrome" || temp[0] === "Safari") {
  //   //   setCheckBrowser(true);
  //   // }
  //   window.addEventListener("beforeinstallprompt", (e) => {
  //     // Prevent the mini-infobar from appearing on mobile
  //     e.preventDefault();

  //     // Stash the event so it can be triggered later.
  //     deferredPrompt = e;
  //     // Update UI notify the user they can install the PWA
  //     // setInstallable(true);
  //   });

  //   window.addEventListener("appinstalled", () => {
  //     // Log install to analytics
  //     //     console.log("INSTALL: Success");
  //   });

  //   // Detects if device is on iOS
  //   const isIos = () => {
  //     // const userAgent = window.navigator.userAgent.toLowerCase();
  //     // return /iphone|ipad|ipod/.test( userAgent );
  //     return [
  //       'iPad Simulator',
  //       'iPhone Simulator',
  //       'iPod Simulator',
  //       'iPad',
  //       'iPhone',
  //       'iPod'
  //     ].includes(navigator.platform)
  //     // iPad on iOS 13 detection
  //     || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  //   }
  //   // Detects if device is in standalone mode
  //   const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  //   // Checks if should display install popup notification:
  //   if (isIos() && !isInStandaloneMode()) {
  //     setShowInstallMessage({ showInstallMessage: true });
  //   }
  // }, []);


  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    // For Android.
    let deferredPrompt; // Variable should be out of scope of addEventListener method
    window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();	// This prevents default chrome prompt from appearing
        deferredPrompt = e;	 // Save for later
        let infoBar = document.querySelector('#custom-info-bar');
        if (infoBar) {
          infoBar.style.display = '';
          let installBtn = document.querySelector('#custom-install-button');
          installBtn.addEventListener('click', (e) => {
            //This prompt window
            deferredPrompt.prompt();
            // Here we wait for user interaction
            deferredPrompt.userChoice
            .then((choiceResult) => {
              console.log(choiceResult);
              if (choiceResult.outcome === 'accepted') {
                let infoBar = document.querySelector('#custom-info-bar');
                infoBar.style.display = 'none'; // Hide info bar
                deferredPrompt = null; // not need anymore
              }
            });
          });
        }
    });

    // For IOS.
    // Detects if device is on iOS
    const isIosDevice = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod|macintosh/.test(userAgent);
    }

    // Detects if device is on iOS
    const isSafari = () => {
      return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;
    }

    if (isIosDevice() && isSafari()) {
      setIsIOS(true);
    }

  }, [setIsIOS, isIOS]);

  //Function call for show PWA Prompt
  // const handleInstallClick1 = (e) => {
  //   // Hide the app provided install promotion
  //   // setInstallable(false);
  //   if (deferredPrompt !== undefined) {
  //     // Show the install prompt
  //     deferredPrompt.prompt();
  //     // Wait for the user to respond to the prompt
  //   } else {
  //     deferredPrompt = null;
  //   }
  // };
  //-----------changes for PWA prompt-----------//

  function ListItemLink(props) {
    const { icon, primary, to, updateNavigation } = props;

    const renderLink = React.useMemo(
      () =>
        React.forwardRef((itemProps, ref) => (
          <RouterLink to={`/${to}`} ref={ref} {...itemProps} />
        )),
      [to]
    );

    return (
      <ListItem
        button
        component={renderLink}
        onClick={() => updateNavigation(to)}
        className={`${
          window?.location?.pathname === `/${to}` ? "active" : ""
        } list-item`}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    );
  }

  const ListItemExternalLink = (props) => {
    const { icon, primary, to } = props;
    const renderExternalLink = () => window.location = to;
    return (
      <ListItem
        button
        onClick={() => renderExternalLink()}
        className='list-item'
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    );
  };

  const list = () => (
    <div role="presentation" style={{paddingBottom: "60px"}}>
      <div className="user-profile">
        <img
          src={userImage || currentProfileImg}
          className="avt-img"
          alt="user profile"
        />
        <div className="user-name">
          {fellowData.data &&
            fellowData.data.attributes &&
            fellowData.data.attributes.title}
        </div>
      </div>
      <Divider />
      <List>
        <ListItemLink
          icon={
            <DashboardIcon
              isActive={
                window?.location?.pathname === "/dashboard" ? true : false
              }
            />
          }
          primary={t("header.menus.dashboard")}
          to="dashboard"
          updateNavigation={updateNavigation}
        />

        <ListItemLink
          icon={
            <SupportIcon
              isActive={
                window?.location?.pathname === "/support" ? true : false
              }
            />
          }
          primary={t("header.menus.support")}
          to="support"
          updateNavigation={updateNavigation}
        />
        <ListItemExternalLink
          icon={
            <ResourceIcon
              className="nav-icon"
            />
          }
          exact path="/resources"
          to="https://acrc.ashoka.org/en"
          primary={t("header.menus.resources")}
        />
      </List>
      <Divider />
      <List>
        <ListItemLink
          icon={
            <ProfileIcon
              isActive={
                window?.location?.pathname === "/fellowprofile" ? true : false
              }
            />
          }
          primary={t("header.menus.profile")}
          to="fellowprofile"
          updateNavigation={updateNavigation}
        />
        <ListItemLink
          icon={
            <SettingsIcon
              isActive={
                window?.location?.pathname === "/settings" ? true : false
              }
            />
          }
          primary={t("header.menus.settings")}
          to="settings"
          updateNavigation={updateNavigation}
        />
        {/* changes */}
        <ListItemLink
          icon={
            <ContactsIcon
              isActive={
                window?.location?.pathname === "/savedList" ? true : false
              }
            />
          }
          primary="Saved List"
          to="savedList"
          updateNavigation={updateNavigation}
        />
        {/* <ListItemLink
          icon={
            <ContactsIcon
              isActive={
                window?.location?.pathname === "/contactlist" ? true : false
              }
            />
          }
          primary={t("header.menus.contact_list")}
          to="contactlist"
          updateNavigation={updateNavigation}
        /> */}
      </List>
      <Divider />
      <List>
        <ListItemLink
          icon={<PolicyIcon />}
          // primary={t('header.menus.contact_list')}
          primary="Policies"
          to="policies"
          updateNavigation={updateNavigation}
        />
      </List>
      <Divider />
      <List>
        <ListItem className="list-item" onClick={logout}>
          <ListItemIcon>
            <img src={SignOut} alt="signOut" />
          </ListItemIcon>
          <ListItemText primary={t("header.menus.sign_out")} />
        </ListItem>

        {/* ------Changes For popup------ */}
        {/* { showInstallMessage ? (
          <div></div>
        ) : (
          <div
            className="recent-active flex drawer-card"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            <div className="card-left">
              <img src={promptImage} alt="user profile" />
            </div>
            <div className="card-right">
              <div style={{ marginLeft: "5%", color: "dimgray" }}>
                {t("dashboard.prompt_discription")}
              </div>
                <a style={{ float: "right", color: "#4155ee", marginTop: "10px" }}  // eslint-disable-line
                  onClick={handleInstallClick1}
                  className="installClickMenu"
                >
                  {t("dashboard.install")}
                </a>
            </div>
          </div>
        ) } */}

        { isIOS ? (
          <>
          </>
          // <div className='sticky-modal'>Install the app on your iPhone: tap <span style={{width: "16px", display: "inline-block"}}><img src={iconIOS} width="100%" alt="" /></span> and then Add to homescreen.</div>
        ) : (
          <>
            <div
              className="recent-active flex drawer-card only-mbl"
              style={{ backgroundColor: "#f5f5f5" }}
            >
              <div className="card-left">
                <img src={promptImage} alt="user profile" />
              </div>
              <div className="card-right">
                <div style={{ marginLeft: "5%", color: "dimgray" }}>
                  {t("dashboard.prompt_discription")}
                </div>
                  <Button style={{ float: "right", color: "#4155ee", marginTop: "10px" }}  // eslint-disable-line
                    id='custom-install-button'
                    className="installClickMenu"
                  >
                    {t("dashboard.install")}
                  </Button>
              </div>
            </div>
          {/* <div id='custom-info-bar'>
            <Button variant="dark" id='custom-install-button'>Install</Button>
          </div> */}
          </>
        )}
      </List>
    </div>
  );
  // Set language Direction
  return (
    <Drawer
      anchor={i18n.dir() === "rtl" ? "right" : "left"}
      open={openDrawer}
      onClose={toggleDrawer}
      className="menu-drawer"
    >
      {list()}
    </Drawer>
  );
};

export default NavDrawer;
