import React from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Loader from "../loader";

const CustomButton = (props) => {
  const { classes, onClick, text, variant, bgClr, textClr, isLoading, style } =
    props;

  const ColorButton = withStyles((theme) => ({
    root: {
      color: textClr,

      "&:hover": {
        backgroundColor: bgClr,
      },
    },
  }))(Button);

  return (
    <ColorButton
      className={`code-btn ${classes}`}
      onClick={onClick}
      style={style ? style : { outline: "none" }}
      variant={variant}
    >
      {isLoading ? <Loader /> : text}
    </ColorButton>
  );
};

export default CustomButton;
