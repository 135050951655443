import "./notificationSettings.scss";

import React, { useState } from "react";
import { useGlobalDispatch, useGlobalState } from "../../context/globalContext";

import CustomCheckbox from "../../basic-components/checkbox";
import ErrorAlert from "../../basic-components/snackbar";
import Footer from "../footer";
import { updateEmailNotifications } from "../../../services/fellow.patchService";

const NotificationSettings = ({ t, setNotifUpdates, notifUpdates }) => {
  const [errors, setErrors] = useState({
    errorSeverity: "",
    openSnackbar: false,
    snackbarMsg: "",
  });
  const [loader, setLoader] = useState(false);
  const { notificationPrefs } = useGlobalState();
  const globalDispatch = useGlobalDispatch();

  const onSave = () => {
    setLoader(true);
    let data = {};
    notifUpdates.forEach((item) => {
      data[item.fieldName] = item.notificationStatus;
    });
    updateEmailNotifications(data)
      .then((res) => {
        const data = res?.data?.attributes;
        let state = [...notifUpdates];
        data &&
          Object.keys(data).forEach((key) => {
            state.forEach((item) => {
              if (item.fieldName === key) item.notificationStatus = data[key];
            });
          });
        setNotifUpdates(state);
        globalDispatch({
          type: "setListData",
          payload: { name: "notificationPrefs", value: state },
        });
        setErrors({
          errorSeverity: "success",
          openSnackbar: true,
          snackbarMsg: "Notification preferences saved",
        });
        setLoader(false);
      })
      .catch((err) => {
        setErrors({
          errorSeverity: "error",
          openSnackbar: true,
          snackbarMsg: "Could not save Notification preferences",
        });
        console.log("error :", err);
        setLoader(false);
      });
  };

  const onCancel = () => {
    setNotifUpdates(notificationPrefs);
  };

  const handleContacts = (event, index) => {
    const { checked } = event.target;
    let array = JSON.parse(JSON.stringify([...notifUpdates]));
    array[index].notificationStatus = checked;
    setNotifUpdates(array);
  };

  const setSnackbar = (value) => {
    setErrors((errors) => ({ ...errors, openSnackbar: value }));
  };

  const getLabelText = (value) => {
    switch (value) {
      case "field_fd_email_notif_remider":
        return t("settings:notifications.email_notifications.type1");
      case "field_fd_email_notif_fd_to_org":
        return t("settings:notifications.email_notifications.type2");
      case "field_fd_email_notif_org_to_fd":
        return t("settings:notifications.email_notifications.type3");
      case "field_fd_email_notif_marketing":
        return t("settings:notifications.email_notifications.type4");
      default:
        return
    }
  };

  return (
    <div className="notification-settings">
      <div className="notification-settings-content">
        <div className="content-box">
          <div className="head-text">
            {t("settings:notifications.email_notifications.title")}
          </div>
          <div className="contact-options">
            {notifUpdates.map((item, index) => {
              return (
                <CustomCheckbox
                  key={index}
                  label={getLabelText(item.fieldName)}
                  name={item.lable}
                  onChange={(event) => handleContacts(event, index)}
                  isChecked={item.notificationStatus}
                  className="item"
                />
              );
            })}
          </div>
        </div>
      </div>
      <Footer onSave={onSave} onCancel={onCancel} t={t} loader={loader} />
      <ErrorAlert
        openSnackbar={errors.openSnackbar}
        msg={errors.snackbarMsg}
        errorSeverity={errors.errorSeverity}
        setSnackbar={setSnackbar}
      />
    </div>
  );
};

export default NotificationSettings;
