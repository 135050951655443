import "./helpQuestionnaire.scss";

import React, { useEffect } from 'react'

import CustomCheckbox from "../basic-components/checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { green } from '@material-ui/core/colors';
import { useTranslation } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';

const GreenRadio = withStyles({
  root: {
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function HelpQuestionnaire({ questions, setquestions, fellowData }) {

  const { t } = useTranslation([
    "settings",
    "questionnaires"
  ]);

  const handleQuestionSelection = (event, key) => {
    const { name, value } = event.target;
    const data = t("questionnaires:questions", { returnObjects: true });
    let newData=questions.map(s => s.key === key ? { ...s, [name]: value === 'true' } : s);

    if(value==='false'){
      const defaultQuestion = data.find(q=>q.key===key);
      newData = questions.map(q=>q.key===key?defaultQuestion:q)
    }
    setquestions(newData)
  }

  const handleOptionSelection = (event, questionKey, subQuestionKey, optionKey) => {
    const { name, checked } = event.target;
    const data = questions.slice(); 
    const ques = data.find(d => d.key === questionKey);
    const subQues = ques?.subquestions.find(s => s.key === subQuestionKey) || ques.subquestions;
    const option = subQues?.options?.find(op => op.key === optionKey) || subQues.options;
    option[name] = checked;
    setquestions(data);
  }

  useEffect(() => {
    const questions = t("questionnaires:questions", { returnObjects: true });
    setquestions(questions);
  }, [t, setquestions])


  useEffect(() => {
    const questionsData = t("questionnaires:questions", { returnObjects: true });
    if (fellowData && questionsData) {
      const { attributes } = fellowData.data;
      const keys = Object.keys(fellowData.data.attributes);
      const data = questionsData.map(element => {
        if (keys.includes(element.key)) {
          let subquestions = [];
          if (element.subquestions) {
            //subquestions
            subquestions = element.subquestions.map(sub => {
              let options = []
              if (keys.includes(sub.key)) {
                if (typeof (attributes[sub.key]) === 'boolean')
                  return { ...sub, isSelected: attributes[sub.key] }
                else {
                  //options

                  options = sub.options.map(op => {
                    if (attributes[sub.key].includes(op.key))
                      return { ...op, isSelected: true }
                    return op
                  })
                }
                return { ...sub, options }
              }
              return sub
            })
          }
          return { ...element, subquestions, isSelected: attributes[element.key] }
        }
        return element
      });
      setquestions(data);
    }
  }, [fellowData, t, setquestions])

  const renderQuestion = (q, questionKey) => {
    return (
      <div className="question" key={q.key}>
        <div className="text">{q.title}</div>{
          q?.options ?
            //for main questions show radio's 
            <div className="contact-options">
              {
                q?.options?.map((item, index) => {
                  return (
                    <CustomCheckbox
                      name='isSelected'
                      label={item.title}
                      onChange={(event) => handleOptionSelection(event, questionKey, q.key, item.key)}
                      isChecked={item?.isSelected}
                      key={item.key}
                    />
                  );
                })
              }
            </div>
            :
            // for subquestions show multi select options 
            <div className="options">
              <RadioGroup row aria-label="position" name="position" defaultValue="top">
                <FormControlLabel
                  control={
                    <GreenRadio
                      checked={q?.isSelected}
                      onChange={(event) => handleQuestionSelection(event, q.key)}
                      value={true}
                      name="isSelected"
                      inputProps={{ 'aria-label': 'Yes' }}
                      size="small"
                      label="Yes"
                    />
                  }
                  style={{ marginRight: '80px' }}
                  label="Yes"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <GreenRadio
                      checked={!q?.isSelected}
                      onChange={(event) => handleQuestionSelection(event, q.key)}
                      value={false}
                      name="isSelected"
                      inputProps={{ 'aria-label': 'No' }}
                      size="small"
                      label="No"
                    />
                  }
                  className='radio'
                  label="No"
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
        }
      </div>
    )
  }

  return (
    <>
      <div className="head-text">{t("settings:profile.helpQuestionnaire.title")}</div>
      <p className="sub-text">
        {t("settings:profile.helpQuestionnaire.subtitle")}
      </p>
      <div className="questions-wrapper">
        <div className="question">
          {
            questions?.map(q => {
              return <div key={q.key}>
                {renderQuestion(q)}
                {q.isSelected && q?.subquestions?.map((subq) => renderQuestion(subq, q.key))}
              </div>
            })
          }
        </div>
      </div>
    </>
  )
}
