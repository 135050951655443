import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import CustomButton from "../../../components/basic-components/button";
import "../../dashboard/dashboard.scss";
import "./Modal.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Textfield from "../../basic-components/textfield";
import {
  getActivityFeed,
  getAllInterests,
  getAllWorkFields,
} from "../../../services/fellow.getService";
import { useGlobalDispatch, useGlobalState } from "../../context/globalContext";
import { updateExistingPost } from "../../../services/fellow.patchService";
import { getViewActivityFeed } from "../../../services/fellow.getService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import SkeletonRowLoader from "../../Loaders/skeletonRowLoader";
toast.configure();
function EditModal({ setOpenModal, PostId, setActivityFeedData }) {
  const { currentProfileImg, userImage, fellowData, fieldsOfWork, interests, countries } =
    useGlobalState();
  const { t } = useTranslation(["headerAndDashboard"]);
  const globalDispatch = useGlobalDispatch();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const [postData, setPostData] = useState("");
  const [linkData, setLinkData] = useState("");
  const [id, setId] = useState("");
  const [fieldData1, setFieldData1] = useState([]);
  const [tagsData1, setTagsData1] = useState([]);
  const [areDataLoading, setAreDataLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [countryData, setCountryData] = useState('');
  const [flag, setFlag] = useState(// eslint-disable-line
    window.screen.availWidth > 480 ? true : false
  );
  useEffect(() => {
    var temp = [];
    var temp1 = [];
    setFieldData1([]);
    setTagsData1([]);
    if (!fieldsOfWork?.length) {
      getAllWorkFields().then((res1) => {
        if (res1) {
          globalDispatch({
            type: "setListData",
            payload: { name: "fieldsOfWork", value: res1.data || [] },
          });

          if (!interests?.length) {
            getAllInterests().then((res2) => {
              if (res2) {
                globalDispatch({
                  type: "setListData",
                  payload: { name: "interests", value: res2.data },
                });
              }

              if (PostId) {
                getViewActivityFeed(PostId).then((res) => {
                  if (res) {
                    setAreDataLoading(false);
                    setId(res.data.id);
                    setPostData(res.data.attributes.field_post_content);
                    setLinkData(res.data.attributes.field_post_link);
                    setCountryData(res.data.attributes.field_location);
                    //for field of work
                    for (
                      var i = 0;
                      i < res.data.relationships.field_topics.data.length;
                      i++
                    ) {
                      for (var j = 0; j < res1.data.length; j++) {
                        if (
                          res.data.relationships.field_topics.data[i].id ===
                          res1.data[j].id
                        ) {
                          temp.push(res1.data[j]);
                        }
                      }
                    }
                    setFieldData1(temp);
                    //for tags
                    for (
                      var a = 0;
                      a < res.data.relationships.field_tags.data.length;
                      a++
                    ) {
                      for (var b = 0; b < res2.data.length; b++) {
                        if (
                          res.data.relationships.field_tags.data[a].id ===
                          res2.data[b].id
                        ) {
                          temp1.push(res2.data[j]);
                        }
                      }
                    }
                    setTagsData1(temp1);
                  }
                });
              }
            });
          }
        }
      });
    } else {
      if (PostId) {
        getViewActivityFeed(PostId).then((res) => {
          if (res) {
            setAreDataLoading(false);
            setId(res.data.id);
            setPostData(res.data.attributes.field_post_content);
            setLinkData(res.data.attributes.field_post_link);
            setCountryData(res.data.attributes.field_location);
            //for field of work
            for (
              var i = 0;
              i < res.data.relationships.field_topics.data.length;
              i++
            ) {
              for (var j = 0; j < fieldsOfWork.length; j++) {
                if (
                  res.data.relationships.field_topics.data[i].id ===
                  fieldsOfWork[j].id
                ) {
                  temp.push(fieldsOfWork[j]);
                }
              }
            }
            setFieldData1(temp);
            //for tags
            for (
              var a = 0;
              a < res.data.relationships.field_tags.data.length;
              a++
            ) {
              for (var b = 0; b < interests.length; b++) {
                if (
                  res.data.relationships.field_tags.data[a].id ===
                  interests[b].id
                ) {
                  temp1.push(interests[b]);
                }
              }
            }
            setTagsData1(temp1);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onchangePost = (e) => {
    e.target.name = e.target.value;
    setPostData(e.target.value);
  };
  const onchangeLink = (e) => {
    e.target.name = e.target.value;
    setLinkData(e.target.value);
  };
  const onEditPost = () => {
    if (postData === null) {
      toast.error("Post Can't be empty!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      setLoader(true);
      let updatePostData = [];
      updatePostData = {
        title:
          fellowData.data &&
          fellowData.data.attributes &&
          fellowData.data.attributes.title, //current fellow name
        field_post_content: postData,
        field_post_link: linkData,
        field_location: countryData == null ? '' : countryData
      };
      //api call for created post
      updateExistingPost(id, updatePostData, fieldData1, tagsData1).then(
        (res) => {
          if (res) {
            getActivityFeed().then((res) => {
              if (res) {
                setLoader(false);
                setActivityFeedData(res);
                setOpenModal(false);
                toast.success("Successful", {
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              }
            });
          } else {
            setOpenModal(false);
            toast.error("Error", { position: toast.POSITION.BOTTOM_CENTER });
          }
        }
      );
    }
  };
  //details for location form
  const setCountryDetails = (value, name) => {
    if (name === "country") {
      setCountryData(value);
    }
  };

  const [inputValue, setInputValue] = useState('');

  return (
    <div
      className="overlay_background"
      style={{
        position: "fixed",
        zIndex: flag ? 0 : 1,
        paddingLeft: flag ? "0" : "5%",
        paddingRight: flag ? "0" : "5%",
        overflowX: "scroll",
      }}
    >
      <div
        className="modalContainer"
        style={{ height: flag ? "625px" : "600px" }}
      >
        <div className="header-popup">
          <p>{t("activity_feed.edit_popup_heading")}</p>
        </div>
        {areDataLoading &&
          Array(5)
            .fill(null)
            .map((item, index) => {
              return <SkeletonRowLoader key={index} />;
            })}
        <div hidden={areDataLoading}>
          <div className="user" style={{ display: "flex" }}>
            <div className="User-info">
              {token && !location?.pathname?.startsWith("/onboarding/") && (
                <img
                  src={userImage || currentProfileImg}
                  className="avt-img"
                  alt="user profile"
                />
              )}
            </div>
            <div style={{ marginTop: "3%", marginLeft: flag ? "2%" : "12%" }}>
              {fellowData.data &&
                fellowData.data.attributes &&
                fellowData.data.attributes.title}
            </div>
          </div>
          <div
            className="body"
            style={{
              flex: flag ? "50%" : "none",
              marginTop: flag ? "5%" : "10%",
              marginBottom: flag ? "0" : "10%",
            }}
          >
            {/* Post Content */}
            <div className="content-box workfields" style={{ width: "100%" }}>
              <div className="popup-field">
                <Textfield
                  label="New Post*"
                  //  id="standard-multiline-static"
                  // rows={4}
                  maxRows={4}
                  defaultValue="Default Value"
                  //variant="filled"
                  variant="standard"
                  name="new_post"
                  onChange={onchangePost}
                  value={postData}
                  multiline={true}
                />
              </div>
              {/* Post Link */}
              <div className="popup-field">
                <Textfield
                  label="Add link"
                  rows={4}
                  defaultValue="Default Value"
                  variant="filled"
                  name="add_link"
                  onChange={onchangeLink}
                  value={linkData}
                  multiline={true}
                />
              </div>
              {/* Location */}
              <div className="popup-field">
                 <Autocomplete
                  value={countryData == null ? '' : countryData}
                  options={countries.map ((countryName) => (
                    countryName.title
                  ))}
                  onChange={(event, value) => {
                    setCountryDetails(value, "country");
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  name="country"
                  className="autocomplete-feilds address-fields"
                  getOptionSelected={(option) =>
                    option.title === countryData || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Add Location'
                      variant="filled"
                    />
                  )}
                />
              </div>
              {/* Field Of WOrk */}
              <div className="popup-field">
                <Autocomplete
                  multiple
                  options={fieldsOfWork}
                  limitTags={1}
                  getOptionLabel={(option) => option?.attributes?.name || ""}
                  value={fieldData1}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(event, value) => {
                    setFieldData1(value);
                  }}
                  name="work"
                  className="autocomplete-feilds1"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Add Fields of work"
                      placeholder="Add Fields of work"
                    />
                  )}
                />
              </div>
              {/* fields of interests */}

              <Autocomplete
                multiple
                options={interests}
                limitTags={1}
                getOptionLabel={(option) => option?.attributes?.name || ""}
                value={tagsData1}
                getOptionSelected={(option, value) => option.id === value.id}
                filterSelectedOptions
                onChange={(event, value) => {
                  setTagsData1(value);
                }}
                name="tags"
                className="autocomplete-feilds1"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Add Interest tags"
                    placeholder="Add Interest tags"
                  />
                )}
              />
            </div>
          </div>

          <div style={{ marginTop: "5%" }}>
            <CustomButton
              style={{
                width: "20%",
                float: "right",
                marginTop: "3%",
                color: "white",
                backgroundColor: "#4155EE",
              }}
              classes="btn post-analytics"
              onClick={onEditPost}
              text={t("activity_feed.post")}
              variant="contained"
              isLoading={loader}
            />

            <Button
              variant="text"
              style={{
                marginTop: "3%",
                color: "slategray",
                float: "right",
                width: "20%",
              }}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              {t("activity_feed.cancel")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditModal;
