import React from "react";

const ContactStateContext = React.createContext();
const ContactDispatchContext = React.createContext();

let initialState = {
  contactinfo: {
    ccode: "",
    mobile: "",
  },
  mediasToContact: [{ title: "Email", isAllowed: true }],
  smaccounts: [{ mediatype: null, handler: "" }],
  imaccounts: [{ mediatype: null, handler: "" }],
  questions: []
};

function contactReducer(state, action) {
  let result;
  switch (action.type) {
    case "setcontactinfo":
      let contactinfo = { ...state.contactinfo };
      contactinfo[action.payload.name] = action.payload.value;
      result = { ...state, contactinfo: contactinfo };
      return result;

    case "setMediasToContact":
      result = { ...state, mediasToContact: action.payload };
      return result;

    case "addSmAccounts":
      result = { ...state, smaccounts: action.payload };
      return result;

    case "addImAccounts":
      result = { ...state, imaccounts: action.payload };
      return result;

    case "setAllMedias":
      result = { ...state, mediasToContact: action.payload };
      return {
        ...state,
        mediasToContact: action.payload,
      };

    case "setDetails":
      return {
        ...action.payload,
      };

    case "setFellowsExistingData":
      return {};

    case "setQuestionnaireData":
      result = { ...state, questions: action.payload };
      return result;

    default:
      return { ...state };
  }
}

function ContactProvider({ children }) {
  const [state, dispatch] = React.useReducer(contactReducer, initialState);

  return (
    <ContactStateContext.Provider value={state}>
      <ContactDispatchContext.Provider value={dispatch}>
        {children}
      </ContactDispatchContext.Provider>
    </ContactStateContext.Provider>
  );
}

function useContactState() {
  const context = React.useContext(ContactStateContext);
  if (context === undefined) {
    throw new Error("useContactState must be used within a ContactProvider");
  }
  return context;
}

function useContactDispatch() {
  const context = React.useContext(ContactDispatchContext);
  if (context === undefined) {
    throw new Error("useContactDispatch must be used within a ContactProvider");
  }
  return context;
}

export { ContactProvider, useContactState, useContactDispatch };
