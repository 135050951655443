import React, { useState } from "react";
import "./fellow-row.scss";
import CustomButton from "../button";
import Avatar from "@material-ui/core/Avatar";
import { ReactComponent as LocationIcon } from "../../../images/location-icon.svg";
import { Link } from "react-router-dom";
import ContactMethods from "../contactMethods";

const FellowRow = (props) => {
  const {
    id,
    image,
    name,
    location,
    method,
    addFellowToContactList,
    removeFellowFromContactList,
    socialMedias,
    email,
    phone,
    t,
    nid,
    smAccs,
    imAccs,
    contactPrefs,
    isLoading,
    hiddenStatus,
    post,
  } = props;
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen((state) => !state);
  };

  return (
    <div className="fellow-row-wrapper">
      <Link
        className="image-wrap"
        to={{ pathname: `/fellow/${nid}/${name}`, state: { id: id } }}
      >
        <Avatar alt="Remy Sharp" src={image} className="fellow-img" />
      </Link>
      <div className="desc-wrap">
        <Link
          className="fellow-name"
          to={{ pathname: `/fellow/${nid}/${name}`, state: { id: id } }}
        >
          {name}
        </Link>
        {location && (
          <div className="fellow-country">
            <LocationIcon className="location-icon" />
            <span className="country">{location}</span>
          </div>
        )}
        {post && (
          <div className="fellow-country">
            {post}
            {/* <span className="country">{post}</span> */}
          </div>
        )}
        <div
          style={{ display: hiddenStatus ? "none" : "" }}
          className="button-wrapper"
        >
          {method === "DELETE" ? (
            <CustomButton
              text={t("common:contact_list.unsave_button")}
              onClick={() => removeFellowFromContactList(id)}
              classes="unsave outlined-btn"
              variant="outlined"
              bgClr="#E3E6FC"
              isLoading={isLoading}
            />
          ) : (
            <CustomButton
              text={t("common:contact_list.save_button")}
              onClick={() => addFellowToContactList(id)}
              classes="unsave outlined-btn"
              variant="outlined"
              bgClr="#E3E6FC"
              isLoading={isLoading}
            />
          )}
          <CustomButton
            text={t("common:contact_button")}
            onClick={onClose}
            classes="filled-btn"
            variant="contained"
            bgClr="#E3E6FC"
          />
        </div>
      </div>
      <ContactMethods
        onClose={onClose}
        open={open}
        socialMedias={socialMedias}
        smAccs={smAccs}
        imAccs={imAccs}
        contactPrefs={contactPrefs}
        email={email}
        phone={phone}
      />
    </div>
  );
};

export default FellowRow;
