import React from "react";
import Chip from "@material-ui/core/Chip";
import "./displayWorkFields.scss";
import { getRelationshipMappings } from "../../../services/helper-functions";
import { useHistory } from "react-router-dom";

const DisplayWorkFields = ({
  fieldsOfWork,
  includedIn,
  text,
  isClickable,
  className,
  classNameForWOrkField,
}) => {
  const history = useHistory();

  let sortedWorkFields = fieldsOfWork;
  if (fieldsOfWork && includedIn?.length) {
    const list = getRelationshipMappings(fieldsOfWork, includedIn);
    sortedWorkFields = list.sort((a, b) =>
      a.attributes.name > b.attributes.name ? 1 : -1
    );
  }

  const goToSearch = (field) => {
    history.push({
      pathname: "/search",
      state: { searchby: "oneFieldOfWork", workField: field },
    });
  };

  return sortedWorkFields?.length ? (
    <div className="content-box">
      { text ? (
      <div className="header">
        <h1 className="head-text">{text}</h1>
      </div>
      ) : null}
      <div
        className={
          classNameForWOrkField ? classNameForWOrkField : "work-fields"
        }
      >
        {sortedWorkFields &&
          sortedWorkFields.map((w, index) => {
            return (
              <Chip
                key={index}
                label={w?.attributes?.name || w?.name || w?.code}
                className={className ? className : "field"}
                onClick={isClickable ? () => goToSearch(w) : null}
              />
            );
          })}
      </div>
    </div>
  ) : null;
};

export default DisplayWorkFields;
