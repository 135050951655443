import React from "react";
import "./policies.scss";
import CustomButton from "../basic-components/button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Policies = () => {
  const { t } = useTranslation(["policyPage"]);
  return (
    <div className="policy-page">
      <div className="container">
        <h1 className="head-text">{t("policyPage:page_title")}</h1>
        <div className="policy-methods-wrapper">
          <Link
            to={{
              pathname: `/policies/${8666}`,
              state: {
                id: "a16f7274-e842-45a8-b83a-8344727c80a9",
                title: t("policyPage:privacy_policy.page_title"),
              },
            }}
          >
            <CustomButton
              text={t("policyPage:privacy_policy.link_title")}
              classes="outlined-btn"
              variant="outlined"
            />
          </Link>
          <Link
            to={{
              pathname: `/policies/${46978}`,
              state: {
                id: "dc8f850e-e2d7-43fa-a082-bc51219aead1",
                title: t("policyPage:cookie_policy.page_title"),
              },
            }}
          >
            <CustomButton
              text={t("policyPage:cookie_policy.link_title")}
              classes="outlined-btn"
              variant="outlined"
            />
          </Link>
          <Link
            to={{
              pathname: `/policies/${8667}`,
              state: {
                id: "b1ef6ed9-1233-49f6-b453-720ab9c6ca59",
                title: t("policyPage:terms_of_service.page_title"),
              },
            }}
          >
            <CustomButton
              text={t("policyPage:terms_of_service.link_title")}
              classes="outlined-btn"
              variant="outlined"
            />
          </Link>
        </div>
        <div className="policy-footer">
          <div className="questions">
            {t("policyPage:unanswered_questions")}
          </div>
          <a
            href="mailto:fdsupport@ashoka.org"
            className="questions contactlink"
          >
            {t("policyPage:contact_us_link_title")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Policies;
