import "./sticky.scss";
import React, { useEffect, useState } from "react";
import {
  getExistingProfileData,
  getNodeType,
} from "../../../services/helper-functions";
import { useGlobalDispatch, useGlobalState } from "../../context/globalContext";
import {
  useProfileDispatch,
  useProfileState,
} from "../../context/profileContext";
import CustomButton from "../../basic-components/button";
import ErrorAlert from "../../basic-components/snackbar";
import LanguagePicker from "../../basic-components/languagePicker";
import { ReactComponent as LeftArrow } from "../../../images/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../../images/right-arrow.svg";
import {
  updateFellowData,
  createNewtag,
} from "../../../services/fellow.patchService";
import { getAllInterests } from "../../../services/fellow.getService";
import { updatePrefrredLanguage } from "../../../services/fellow.patchService";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
let fellowInfo = [];
const Sticky = (props) => {
  const { fellowData, countriesInJson, interests } = useGlobalState();
  const profileDistpach = useProfileDispatch();
  const { userProfile } = useProfileState();
  const profileDispatch = useProfileDispatch();
  const globalDispatch = useGlobalDispatch();
  const [errors, setErrors] = useState({
    status: {},
    errorSeverity: "",
    openSnackbar: false,
    snackbarMsg: "",
  });
  const { t } = useTranslation(["forms", "common"]);
  let history = useHistory();
  const [loader, setLoader] = useState(false);
  const { lang, updateFDLanguage } = props;

  const setSnackbar = (value) => {
    setErrors((errors) => ({ ...errors, openSnackbar: value }));
    handleSkip();
  };
  useEffect(() => {
    if (!interests.length) {
      getAllInterests().then((res) => {
        if (res)
          globalDispatch({
            type: "setListData",
            payload: { name: "interests", value: res.data || [] },
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = () => {
    updateData();
  };

  const handleBack = () => {
    history.push("/onboarding/profile");
  };

  const handleSkip = () => {
    setLoader(true);
    if (lang) {
      updatePrefrredLanguage(lang.code)
        .then((res) => {
          setLoader(false);
          history.push("/onboarding/status");
        })
        .catch((err) => {
          console.log("Error in saving language preferance :", err);
          setLoader(false);
          setErrors((errors) => ({
            ...errors,
            openSnackbar: true,
            errorSeverity: "error",
            msg: "Could not save the language preferance.",
          }));
        });
    }
  };

  const updateData = async () => {
    const id = await localStorage.getItem("profile_uuid");
    fellowInfo = { data: { attributes: {} } };
    let allTags = [];
    const newTags = [];
    userProfile.selectedInterests.forEach((tag) => {
      if (typeof tag === "string") newTags.push(tag);
      else allTags.push({ type: getNodeType("tags"), id: tag.id });
    });
    setLoader(true);
    await Promise.all(newTags.map((tag) => createNewtag(tag)))
      .then((responses) => responses.map((res) => res))
      .then((results) => {
        allTags = [...allTags, ...results];

        fellowInfo = {
          data: {
            type: getNodeType("fellow"),
            id,
            relationships: {
              ...fellowInfo.data.relationships,
              field_tags: { data: allTags || [] },
            },
          },
        };
        updateFellowData(fellowInfo).then((res) => {
          if (res) {
            setErrors((errors) => ({
              ...errors,
              openSnackbar: true,
              errorSeverity: "success",
              snackbarMsg: "Tags information saved.",
            }));
            setLoader(false);
            globalDispatch({ type: "setUserData", payload: res });
            handleSkip();
          }
          setLoader(false);
        });
      })
      .catch((err) => {
        console.log("error :", err);
        setErrors((errors) => ({
          ...errors,
          openSnackbar: true,
          errorSeverity: "error",
          snackbarMsg: "Could not save your data.",
        }));
        setLoader(false);
      });
  };

  useEffect(() => {
    const existingData = getExistingProfileData(fellowData, countriesInJson);
    if (existingData)
      profileDispatch({ type: "setDetails", payload: existingData });
  }, [fellowData, countriesInJson, profileDispatch]);
  const setInterests = (value, array) => {
    if (value) {
      let a = interests.find(
        (interest) => interest?.attributes?.name === value
      );
      if (a) {
        var index = array.indexOf(value);
        if (index !== -1) array[index] = a;
      }
    }
    profileDistpach({
      type: "setUserProfile",
      payload: { value: array, name: "selectedInterests" },
    });
  };
  return (
    <div className="status-container">
      <div className="lang">
        <LanguagePicker setLang={updateFDLanguage} lang={lang} />
      </div>
      <div className="status">
        <div className="wrapper">
          <h1 className="head-text">{t("sticky.title")}</h1>
          <p className="content">{t("sticky.description.line1")}</p>
          <br />
          <p className="content">{t("sticky.description.line2")}</p>
        </div>
        <br />
        <div className="interest-tags">
          <Autocomplete
            style={{ margin: "0px" }}
            multiple
            options={interests}
            freeSolo
            limitTags={1}
            getOptionLabel={(option) =>
              option?.attributes?.name || option || ""
            }
            value={userProfile?.selectedInterests || []}
            onChange={(event, value) => {
              setInterests(event.target.value, value);
            }}
            name="work"
            className="autocomplete-feilds interests"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                //label={t("profile.form_fields.fields_of_interest.lable")}
                // placeholder={t("profile.form_fields.fields_of_interest.lable")}
              />
            )}
          />
          <div className="helper-text">
            {t("profile.form_fields.fields_of_interest.helper_text")}
          </div>
        </div>
      </div>
      <div className="footer">
        <div className={`wrapper`}>
          <CustomButton
            classes="btn"
            onClick={handleNext}
            text={t("sticky.save_button")}
            variant="contained"
            isLoading={loader}
          />
          <div className="prev-next-btn-wrapper">
            <div className="update-route prev" onClick={handleBack}>
              <LeftArrow />
              <CustomButton text={t("common:button_previous.lable")} />
            </div>
            <div className="update-route next" onClick={handleSkip}>
              <CustomButton
                text={t("common:button_skip.lable")}
                isLoading={loader}
              />
              <RightArrow />
            </div>
          </div>
          {
            <ul>
              {/* changes for pagination */}
              {Array(5)
                .fill(null)
                .map((i, index) => {
                  return (
                    <li
                      className={`${
                        index === 1 || index === 0 || index === 2 || index === 3
                          ? "active"
                          : ""
                      }`}
                      key={index}
                    ></li>
                  );
                })}
            </ul>
          }
        </div>
        <ErrorAlert
          openSnackbar={errors.openSnackbar}
          msg={errors.snackbarMsg}
          errorSeverity={errors.errorSeverity}
          setSnackbar={setSnackbar}
        />
      </div>
    </div>
  );
};

export default Sticky;
