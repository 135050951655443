import React, { useState } from "react";
import { useGlobalState, useGlobalDispatch } from "../../context/globalContext";
import { ReactComponent as HambergerMenu } from "../../../images/drawerbtn.svg";
import { ReactComponent as SearchIcon } from "../../../images/search-icon.svg";
import "./header.scss";
import NavDrawer from "../drawer/drawer";
import { DashboardIcon } from "../../SvgComponents/dashboard";
import { SupportIcon } from "../../SvgComponents/support";
import { ProfileIcon } from "../../SvgComponents/profile";
import { SettingsIcon } from "../../SvgComponents/settings";
import { ResourceIcon } from "../../SvgComponents/resource";
import { ContactsIcon } from "../../SvgComponents/mycontacts";
import { DashboardMenuIcon } from "../../SvgComponents/dashboardmenu";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { ReactComponent as PolicyIcon } from "../../../images/policy-icon.svg";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SignOut from "../../../images/signOut.svg";
import Logo from "../../../images/ashoka-logo.svg";
import { useTranslation } from "react-i18next";
import { matchPath } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

function MenuItemLink(props) {
  const { icon, primary, to, updateNavigation } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={`/${to}`} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <MenuItem
      button
      component={renderLink}
      onClick={() => updateNavigation(to)}
    >
      <div
        className={`menu ${
          window.location.pathname === `/${to}` ? "active" : ""
        }`}
      >
        {icon}
        <div className="nav-title">{primary}</div>
      </div>
    </MenuItem>
  );
}

const MenuItemExternalLink = (props) => {
  const { icon, primary, to } = props;
  const renderExternalLink = () => window.location = to;
  return (
    <MenuItem
      button
      onClick={() => renderExternalLink()}
    >
      <div className='menu'>
        {icon}
        <div className="nav-title">{primary}</div>
      </div>
    </MenuItem>
  );
};

const Header = (props) => {
  const { updateLogin } = props;
  const { currentProfileImg, userImage } = useGlobalState();
  const { t } = useTranslation(["headerAndDashboard", "policyPage"]);
  const [open, setDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const globalDispatch = useGlobalDispatch();
  const history = useHistory();
  const location = useLocation();
  const token = localStorage.getItem("token");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateNavigation = (route) => {
    history.push(`/${route}`);
  };

  const logout = async () => {
    let keys = Object.keys(localStorage);
    if (keys.length > 0) {
      keys.forEach((key) => {
        if (key !== "i18nextLng") localStorage.removeItem(key);
      });
    }
    updateLogin(false);
    history.push("/");
  };

  const toggleDrawer = () => {
    setDrawer((state) => !state);
  };

  const handleKeyDown = (e) => {
    var keycode = e.keyCode || e.which || 0;
    if (e.key === "Enter" || keycode === 13) {
      globalDispatch({ type: "setSearchKeyword", payload: e.target.value });
      history.push({
        pathname: "/search",
        state: { searchby: "randomsearch" },
      });
    }
  };

  const showHamburger = () => {
    if (token) {
      const isPolicyPathActive = !!matchPath(
        location?.pathname,
        "/policies/:nid"
      );

      if (isPolicyPathActive)
        return (
          <ArrowBackIcon
            className="hamburger-menu"
            color="action"
            onClick={history.goBack}
          />
        );
      else if (!location?.pathname?.startsWith("/onboarding/")) {
        return (
          <div className="hamburger-menu" onClick={toggleDrawer}>
            <HambergerMenu />
          </div>
        );
      }
    }
  };

  return (
    <div
      className="header"
      style={{
        borderBottom:
          token && !location?.pathname?.startsWith("/onboarding/")
            ? "1px solid #E0E0E0"
            : "",
        position: location?.pathname === "/" ? "absolute" : "unset",
      }}
    >
      <div className="header-container">
        {showHamburger()}
        <div
          className="logo-wrapper"
          onClick={() => {
            const token = localStorage.getItem("token");
            token ? history.push("/dashboard") : history.push("/");
          }}
        >
          <img src={Logo} alt="logo" className="logo" />
          <p className="title">
            <span>Ashoka</span> <span>Fellow Directory</span>
          </p>
        </div>

        <div className="navigations">
          {token && !location?.pathname?.startsWith("/onboarding/") && (
            // navigation on header
            <div className="nav-menus">
              <div
                className={`menu ${
                  window.location.pathname === "/dashboard" ? "active" : ""
                }`}
                onClick={() => updateNavigation("dashboard")}
              >
                <DashboardIcon
                  isActive={
                    window.location.pathname === "/dashboard" ? true : false
                  }
                />
                <div className="nav-title">{t("header.menus.dashboard")}</div>
              </div>
              <div
                className={`menu ${
                  window.location.pathname === "/savedList" ? "active" : ""
                }`}
                onClick={() => updateNavigation("savedList")}
              >
                <ContactsIcon
                  isActive={
                    window.location.pathname === "/savedList" ? true : false
                  }
                />
                <div className="nav-title">{t("header.menus.saved_list")}</div>
              </div>
              <div
                className={`menu ${
                  window.location.pathname === "/support" ? "active" : ""
                }`}
                onClick={() => updateNavigation("support")}
              >
                <SupportIcon
                  isActive={
                    window.location.pathname === "/support" ? true : false
                  }
                  className="nav-icon"
                />
                <div className="nav-title">{t("header.menus.support")}</div>
              </div>
            </div>
          )}

          {token && !location?.pathname?.startsWith("/onboarding/") && (
            <div className="searchbar-wrapper">
              <div className="searchbar">
                <SearchIcon className="search-icon" />
                <label for="header_search" className="hidden">Search</label>
                <input
                  type="text"
                  placeholder={t("dashboard.search_lable")}
                  className="search-text"
                  onKeyDown={(e) => handleKeyDown(e)}
                  id="header_search"
                />
              </div>
            </div>
          )}
          <div className="User-info">
            {token && !location?.pathname?.startsWith("/onboarding/") && (
              <img
                src={userImage || currentProfileImg}
                className="avt-img"
                alt="user profile"
                onClick={() => updateNavigation("fellowprofile")}
              />
            )}
            {token && !location?.pathname?.startsWith("/onboarding/") && (
              <div onClick={handleClick} className="menu-icon">
                <DashboardMenuIcon
                  color={`${anchorEl ? "#4155EE" : "#666666"} `}
                />
              </div>
            )}
          </div>
        </div>

        {/* clickable navigation on arrow */}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClick={handleClose}
          className="nav-desktop-menu"
        >
          <MenuItemLink
            icon={
              <ProfileIcon
                className="nav-icon"
                isActive={
                  window?.location?.pathname === "/fellowprofile" ? true : false
                }
              />
            }
            primary={t("header.menus.profile")}
            to="fellowprofile"
            updateNavigation={updateNavigation}
          />
          <MenuItemLink
            icon={
              <SettingsIcon
                className="nav-icon"
                isActive={
                  window?.location?.pathname === "/settings" ? true : false
                }
              />
            }
            primary={t("header.menus.settings")}
            to="settings"
            updateNavigation={updateNavigation}
          />
          <MenuItemExternalLink
            icon={
              <ResourceIcon
                className="nav-icon"
              />
            }
            exact path="/resources"
            to="https://acrc.ashoka.org/en"
            primary={t("header.menus.resources")}
          />
          <MenuItemLink
            icon={<PolicyIcon className="nav-icon" />}
            primary={t("policyPage:page_title")}
            to="policies"
            updateNavigation={updateNavigation}
          />
          <MenuItem onClick={handleClose}>
            <div className="menu signout" onClick={logout}>
              <img className="nav-icon signout" src={SignOut} alt="signOut" />
              <div className="nav-title signout">
                {t("header.menus.sign_out")}
              </div>
            </div>
          </MenuItem>
          {/* included for testing error (will be removed after updating test cases) */}
          {/*
          <MenuItemLink
            icon={<img className='nav-icon' src={SignOut} alt="signOut"/>}
            primary="Sign Out"
          /> */}
        </Menu>
      </div>
      <NavDrawer
        openDrawer={open}
        toggleDrawer={toggleDrawer}
        userProfile={currentProfileImg}
        updateLogin={updateLogin}
      />
    </div>
  );
};

export default Header;
