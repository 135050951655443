import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

const SkeletonPolicyLoader = () => {
  return (
    <div className="plain-text-skeleton">
      <Skeleton animation="wave" className="title" />
      <Skeleton animation="wave" className="line" />
      <Skeleton animation="wave" className="line" />
      <Skeleton animation="wave" className="line" />
      <Skeleton animation="wave" className="line" />
      <Skeleton animation="wave" className="line" />
    </div>
  );
};

export default SkeletonPolicyLoader;
