import React from "react";
import "./page-not-found.scss";
import CustomButton from "../../basic-components/button";
import pageNotFoundImg from "../../../images/pageNotFound.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PageNotFound = () => {
  const history = useHistory();
  const { t } = useTranslation(["user-auth"]);

  const redirectToDashboard = () => {
    const token = localStorage.getItem("token");
    token ? history.push("/dashboard") : history.push("/");
  };

  return (
    <div className="page-not-found">
      <div className="container-wrapper">
        <div className="container">
          <img src={pageNotFoundImg} alt="page not found" className="image" />
          <h1 className="head-text">{t("error_screens.404.title")}</h1>
          <p className="content">{t("error_screens.404.sub_title")}</p>
          <CustomButton
            classes="code-btn"
            onClick={redirectToDashboard}
            text={t("error_screens.back_lable")}
          />
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
