import "./activityFeed.scss";

import React, { useState, useEffect } from "react";
import { useGlobalState } from "../../context/globalContext";
import {
  getViewActivityFeed,
  getSavedPostIdList,
} from "../../../services/fellow.getService";
import { removeSavePost } from "../../../services/fellow.patchService";
import Avatar from "@material-ui/core/Avatar";
import { ReactComponent as LocationIcon } from "../../../images/location-icon.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SkeletonRowLoader from "../../Loaders/skeletonRowLoader";
import DeleteIcon from "@mui/icons-material/Delete";
toast.configure();
const ActivityFeed = ({ setNotifUpdates, notifUpdates }) => {
  const { t } = useTranslation(["savedLists"]);
  const [areDataLoading, setAreDataLoading] = useState(true);
  const { fellowData, currentProfileImg } = useGlobalState();
  // const [savedListId, setSavedListId] = useState([]);
  const [savedListData, setsavedListData] = useState([]);

  useEffect(() => {
    var temp = [];
    var temp1 = [];
    var fellowId = fellowData?.data?.id;
    getSavedPostIdList(fellowId).then((res) => {
      if (res) {
        if (res.data.length === 0) {
          setAreDataLoading(false);
        } else {
          for (var t = 0; t < res.data.length; t++) {
            if (res.data[t].id !== "missing") {
              temp.push(res.data[t].id);
            }
          }
          // setSavedListId(temp);
          var count = 0;
          for (var i = 0; i < temp.length; i++) {
            getViewActivityFeed(temp[i]).then((res) => {// eslint-disable-line
              if (res) {
                temp1.push(res);
                count = count + 1;
              }

              if (count === temp.length) {
                setAreDataLoading(false);
                setsavedListData(temp1);
              }
            });
          } //loop end
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRefresh = () => {
    var temp = [];
    var temp1 = [];
    var fellowId = fellowData?.data?.id;
    getSavedPostIdList(fellowId).then((res) => {
      if (res) {
        if (res.data.length === 0) {
          setsavedListData([]);
          setAreDataLoading(false);
          toast.success("Post deleted Successfully!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        } else {
          for (var t = 0; t < res.data.length; t++) {
            temp.push(res.data[t].id);
          }
          // setSavedListId(temp);
          var count = 0;
          for (var i = 0; i < temp.length; i++) {
            getViewActivityFeed(temp[i]).then((res) => { // eslint-disable-line
              if (res) {
                setAreDataLoading(false);
                temp1.push(res);
                count = count + 1;
              }

              if (count === temp.length) {
                setsavedListData(temp1);
                toast.success("Post deleted Successfully!", {
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              }
            });
          } //loop end
        }
      }
    });
  };

  return (
    <div className="settings-content">
      <div className="notification-settings">
        <div className="notification-settings-content">
          {/* <div className="searchbar-wrapper">
            <div className="searchbar">
              <SearchIcon className="search-icon" />
              <label for="act_search" className="hidden">Search</label>
              <input
                type="text"
                placeholder={t("contact_list.search_lable")}
                className="search-text"
                onChange={(e) => setSearchKeyword(e.target.value)}
                id="act_search"
              />
            </div>
          </div> */}
          <div className="fellow-list" style={{ marginTop: "3%" }}>
            {areDataLoading &&
              Array(5)
                .fill(null)
                .map((item, index) => {
                  return <SkeletonRowLoader key={index} />;
                })}
            {savedListData.length > 0 ? (
              savedListData?.map((fellow, index) => {
                // let phoneNo = fellow?.attributes?.field_phone?.split(",");
                var imagePath = "";
                var fellowNid = "";
                var fellowName = "";
                for (var i = 0; i < fellow.included.length; i++) {
                  if (fellow.included[i].type === "media--image") {
                    imagePath =
                      fellow.included[i].relationships.thumbnail.data.meta
                        .imageDerivatives.links.small_round_320x320.href;
                  } else if (fellow.included[i].type === "node--fellow") {
                    fellowNid =
                      fellow.included[i].attributes.drupal_internal__nid;
                    fellowName = fellow.included[i].attributes.title;
                  }
                }
                return (
                  <div className="fellow-row-wrapper " key={fellow.data.id}>
                    <Link
                      className="image-wrap"
                      to={{
                        pathname: `/fellow/${fellowNid}/${fellowName}`,
                        state: { id: fellow.data.id },
                      }}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        //src={currentProfileImg}
                        src={imagePath === "" ? currentProfileImg : imagePath}
                        className="fellow-img"
                      />
                    </Link>
                    <div className="desc-wrap">
                      <button className="delete-btn text-hide" onClick={() => {
                          setsavedListData([]);
                          setAreDataLoading(true);
                          removeSavePost(
                            fellowData.data.id,
                            fellow.data.id
                          ).then((res) => {
                            if (res.status === 204) {
                              handleRefresh();
                            }
                          });
                        }} >
                          Delete
                        <DeleteIcon color="danger"  />
                      </button>
                      {/* <SearchIcon className="search-icon" /> */}
                      <Link
                        className="fellow-name"
                        to={{
                          pathname: `/fellow/${fellowNid}/${fellowName}`,
                          state: { id: fellow.data.id },
                        }}
                      >
                        {fellow.data.attributes.title}
                      </Link>

                      {fellow.data.attributes.field_location && (
                        <div className="fellow-country">
                          <LocationIcon className="location-icon" />
                          <span className="country">
                            {fellow.data.attributes.field_location}
                          </span>
                        </div>
                      )}
                      {fellow.data.attributes.field_post_content && (
                        <div className="fellow-country">
                          {fellow.data.attributes.field_post_content}
                          {/* <span className="country">{post}</span> */}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                hidden={areDataLoading}
                style={{
                  backgroundColor: "#F5F5F5",
                  padding: "2% 5% 2% 5%",
                  margin: "5%",
                }}
              >
                <div>
                  <b
                    style={{
                      textAlign: "initial",
                      color: "gray",
                    }}
                  >
                    {t("saved_activity_feed.welcome_tip.title")}
                  </b>
                  <p
                    style={{
                      textAlign: "initial",
                      color: "gray",
                    }}
                  >
                    {t("saved_activity_feed.welcome_tip.message")}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityFeed;
