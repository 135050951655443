import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const vertical = "bottom",
  horizontal = "center";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ErrorAlert = (props) => {
  const { openSnackbar, errorSeverity, msg, setSnackbar } = props;
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar(false);
  };
  return (
    <Snackbar
      open={openSnackbar}
      autoHideDuration={2000}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
      key={vertical + horizontal}
      style={{ width: "100%" }}
    >
      <Alert onClose={handleClose} severity={errorSeverity}>
        {msg}
      </Alert>
    </Snackbar>
  );
};

export default ErrorAlert;
