import React from "react";
import "./checkbox.scss";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

const GreenCheckbox = withStyles({
  root: {
    color: "rgba(0, 0, 0, 0.54)",
    "&$checked": {
      color: "#6AD29B",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CustomCheckbox = (props) => {
  const { isChecked, onChange, name, className, label } = props;
  return (
    <FormControlLabel
      control={
        <GreenCheckbox checked={isChecked} onChange={onChange} name={name} />
      }
      label={label}
      className={`${className} default ${isChecked ? "checked" : ""}`}
    />
  );
};

export default CustomCheckbox;
