import constants from "./constants";
import i18n from "../i18n";
i18n.loadNamespaces(["toastMessages"]).then(() => {});

const randomCharacters = (length = 8) => {
  return Math.random().toString(16).substr(2, length);
};

export const updateFellowData = async (data) => {
  const token = await localStorage.getItem("token");
  const id = await localStorage.getItem("profile_uuid");

  if (!token) return;

  if (!id) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}/${id}`
  );
  var params = [
    [
      "include",
      "field_media_profile_image,field_tags,field_topics,field_region",
    ],
  ];
  url.search = new URLSearchParams(params).toString();
  const opts = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  };

  return fetch(url, opts).then(async (res) => {
    const data = await res.json();
    if (res.status === 200) {
      return data;
    }
    throw new Error("error :", res);
  });
};

export const createNewtag = async (tagName) => {
  const token = await localStorage.getItem("token");

  if (!token) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.TAXONOMY}${constants.TAGS}`
  );
  var params = [["fields[taxonomy_term--tags]", "id"]];
  url.search = new URLSearchParams(params).toString();
  const opts = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify({
      data: {
        type: "taxonomy_term--tags",
        attributes: {
          status: true,
          name: tagName,
        },
      },
    }),
  };
  return fetch(url, opts).then(async (res) => {
    const data = await res.json();
    if (res.status === 201) {
      return { type: data.data.type, id: data.data.id };
    }
    throw new Error("Tags could noy be created...", data);
  });
};

export const updateExistingImage = async (media_id, image, name) => {
  const token = await localStorage.getItem("token");
  if (!token) return;

  if (!media_id) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.MEDIA}/${media_id}${constants.FIELD_IMAGE_FILE}`
  );

  let array = (await image) && image?.type?.split("/");

  const opts = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/octet-stream",
      Accept: "application/vnd.api+json",
      "Content-Disposition": `file;filename="${
        (name.replace(/[^a-zA-Z ]/g, "") || "profileImage") + randomCharacters()
      }.${array[array.length - 1] || "jpg"}"`,
    },
    body: image,
  };

  return fetch(url, opts).then(async (res) => {
    const data = await res.json();

    if (res.status === 200) {
      return data;
    }
    console.log("error :", data);
    throw new Error("Could not save the image.", data);
  });
};

export const addNewImage = async (image, name) => {
  await createFileEntity(image, name).then(async (res) => {
    createMediaEntity(res.data.id, name).then((res) => {
      updateMediaEntityToFellow(res.data.id).then((res) => {
        return res;
      });
    });
  });
};

export const createFileEntity = async (image, name) => {
  const token = await localStorage.getItem("token");
  if (!token) return;

  if (!image) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.MEDIA}${constants.FIELD_IMAGE_FILE}`
  );

  let array = await image.name.split(".");

  const opts = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/octet-stream",
      Accept: "application/vnd.api+json",
      "Content-Disposition": `file;filename="${
        (name.replace(/[^a-zA-Z ]/g, "") || "profileImage") + randomCharacters()
      }.${array[array.length - 1] || "jpg"}"`,
    },
    body: image,
  };

  return fetch(url, opts).then(async (res) => {
    const data = await res.json();
    if (res.status === 201) {
      return data;
    }
    throw new Error("Could not save the image.", data);
  });
};

export const createMediaEntity = async (file_entity_id, name) => {
  const token = await localStorage.getItem("token");
  if (!token) return;

  if (!file_entity_id) return;

  if (!name) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.MEDIA}`
  );
  const data = {
    data: {
      type: "media--image",
      attributes: {
        name: name + randomCharacters(),
        field_author_source_checkbox: true,
        field_source: name,
      },
      relationships: {
        field_media_image: {
          data: {
            type: "file--file",
            id: file_entity_id,
          },
        },
      },
    },
  };
  const opts = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  };

  return fetch(url, opts).then(async (res) => {
    if (res.status === 201) {
      const data = await res.json();
      return data;
    }
    throw new Error("cannot create media entity...", data);
  });
};

export const updateMediaEntityToFellow = async (media_image_id) => {
  const token = await localStorage.getItem("token");
  const user_id = await localStorage.getItem("profile_uuid");
  if (!token || !media_image_id || !user_id) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}/${user_id}${constants.RELATIONSHIPS}/field_media_profile_image`
  );
  const data = {
    data: {
      type: "media--image",
      id: media_image_id,
    },
  };
  const opts = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  };

  return fetch(url, opts).then(async (res) => {
    if (res.status === 204) {
      const data = await res.text();
      return data;
    }
    throw new Error("could not update fellow image");
  });
};

export const addToContactList = async (fellowId) => {
  const token = await localStorage.getItem("token");
  const id = await localStorage.getItem("profile_uuid");

  if (!token || !fellowId || !id) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}/${id}${constants.RELATIONSHIPS}/field_fd_contact_list`
  );

  const data = {
    data: [
      {
        type: "node--fellow",
        id: fellowId,
      },
    ],
  };

  const opts = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  };

  return fetch(url, opts)
    .then(async (res) => {
      if (res.status === 200 || res.status === 204) {
        return {
          status: true,
          msg: i18n.t("toastMessages:fellow_in_contact_list.save"),
        };
      }
    })
    .catch((err) => {
      console.log("Error :", err);
      return { status: false, msg: "could not add the fellow" };
    });
};

export const removeFromContactList = async (fellowId) => {
  const token = await localStorage.getItem("token");
  const id = await localStorage.getItem("profile_uuid");

  if (!token || !fellowId || !id) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}/${id}${constants.RELATIONSHIPS}/field_fd_contact_list`
  );

  const data = {
    data: [
      {
        type: "node--fellow",
        id: fellowId,
      },
    ],
  };

  const opts = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  };

  return fetch(url, opts)
    .then(async (res) => {
      if (res.status === 204) {
        return {
          status: true,
          msg: i18n.t("toastMessages:fellow_in_contact_list.remove"),
        };
      }
    })
    .catch((err) => {
      console.log("Error :", err);
      return { status: false, msg: "could not remove the fellow" };
    });
};

export const updatePrefrredLanguage = async (code) => {
  const token = await localStorage.getItem("token");
  const id = await localStorage.getItem("profile_uuid");

  if (!token) return;

  if (!id) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}/${id}`
  );

  const data = {
    data: {
      type: "node--fellow",
      id: id,
      attributes: {
        field_fd_ui_language: code,
      },
    },
  };

  const opts = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  };

  return fetch(url, opts).then(async (res) => {
    const data = await res.json();
    if (res.status === 200) {
      return data;
    }
    throw new Error(data?.errors?.title);
  });
};

export const updateEmail = async (code) => {
  const token = await localStorage.getItem("token");
  const id = await localStorage.getItem("profile_uuid");

  if (!token) return;

  if (!id) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}/${id}`
  );

  const data = {
    data: {
      type: "node--fellow",
      id: id,
      attributes: {
        field_email: code,
      },
    },
  };

  const opts = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  };

  return fetch(url, opts).then(async (res) => {
    const data = await res.json();
    if (res.status === 200) {
      return data;
    }
    throw new Error(data?.errors?.title);
  });
};

export const updateEmailNotifications = async (emailNotifications) => {
  const token = await localStorage.getItem("token");
  const id = await localStorage.getItem("profile_uuid");

  if (!token || !id) return;
  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}/${id}`
  );
  var params = [
    [
      "fields[node--fellow]",
      "field_fd_email_notif_fd_to_org,field_fd_email_notif_marketing,field_fd_email_notif_org_to_fd,field_fd_email_notif_remider",
    ],
  ];
  url.search = new URLSearchParams(params).toString();

  const data = {
    data: {
      type: "node--fellow",
      id: id,
      attributes: {
        ...emailNotifications,
      },
    },
  };

  const opts = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  };

  return fetch(url, opts).then(async (res) => {
    const data = await res.json();
    if (res.status === 200) {
      return data;
    }
    throw new Error(data?.errors?.title);
  });
};
//----changes----

export const transLanguage = async (textData) => {
  const url = new URL(
    "https://translation.googleapis.com/language/translate/v2?key=" +
      process.env.REACT_APP_API_KEY
  );
  const opts = {
    method: "POST",
    headers: {
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(textData),
  };

  return fetch(url, opts).then(async (res) => {
    const data = await res.json();
    if (res.status === 200) {
      return data;
    }
    throw new Error(data?.errors?.title);
  });
};

export const langDetect = async (textData) => {
  const url = new URL(
    "https://translation.googleapis.com/language/translate/v2/detect?key=" +
      process.env.REACT_APP_API_KEY
  );
  const opts = {
    method: "POST",
    headers: {
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(textData),
  };

  return fetch(url, opts).then(async (res) => {
    const data = await res.json();
    if (res.status === 200) {
      return data;
    }
    throw new Error(data?.errors?.title);
  });
};

// api for Create post
export const createNewPost = async (createPostData, finalfData, finaltData) => {
  const token = await localStorage.getItem("token");
  if (!token) return;
  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FD_POST}`
  );
  const data = {
    data: {
      type: "node--fd_post",
      attributes: {
        ...createPostData,
      },
      relationships: {
        field_topics: {
          data: finalfData,
        },
        field_tags: {
          data: finaltData,
        },
      },
    },
  };
  const opts = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  };
  return fetch(url, opts).then(async (res) => {
    const data = await res.json();
    if (res.status === 201) {
      return data;
    }
    throw new Error(data?.errors?.title);
  });
};
//Api for delete post
export const removeActivityPost = async (postId) => {
  const token = await localStorage.getItem("token");
  if (!token || !postId) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FD_POST}/${postId}`
  );
  const opts = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
  };

  return fetch(url, opts)
    .then(async (res) => {
      if (res.status === 204) {
        return {
          status: true,
          msg: "Post deleted",
        };
      }
    })
    .catch((err) => {
      console.log("Error :", err);
      return { status: false, msg: "could not remove the post" };
    });
};
// api for Update post
export const updateExistingPost = async (
  postId,
  updatePostData,
  finalfData,
  finaltData
) => {
  const token = await localStorage.getItem("token");
  if (!token) return;
  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FD_POST}/${postId}`
  );
  const data = {
    data: {
      type: "node--fd_post",
      id: postId,
      attributes: {
        ...updatePostData,
      },
      relationships: {
        field_topics: {
          data: finalfData,
        },
        field_tags: {
          data: finaltData,
        },
      },
    },
  };
  const opts = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  };
  return fetch(url, opts).then(async (res) => {
    const data = await res.json();
    if (res.status === 200) {
      return data;
    }
    throw new Error(data?.errors?.title);
  });
};
//api for save post
export const saveThePost = async (fellowId, postId) => {
  const token = await localStorage.getItem("token");
  if (!token) return;
  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}/${fellowId}${constants.RELATIONSHIPS}/field_fd_saved_posts`
  );
  const data = {
    data: [
      {
        type: "node--fd_post",
        id: postId,
      },
    ],
  };
  const opts = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  };
  return fetch(url, opts).then(async (res) => {
    // const data = await res.json();
    if (res.status === 204) {
      return res;
    }
  });
};
//api for remove save post
export const removeSavePost = async (fellowId, postId) => {
  const token = await localStorage.getItem("token");
  if (!token) return;
  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}/${fellowId}${constants.RELATIONSHIPS}/field_fd_saved_posts`
  );
  const data = {
    data: [
      {
        type: "node--fd_post",
        id: postId,
      },
    ],
  };
  const opts = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  };
  return fetch(url, opts).then(async (res) => {
    // const data = await res.json();
    if (res.status === 204) {
      return res;
    }
  });
};
//changes for save search string on search
export const saveSearches = async (searchData) => {
  const token = await localStorage.getItem("token");
  const id = await localStorage.getItem("profile_uuid");

  if (!token) return;

  if (!id) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}/${id}`
  );

  const data = {
    data: {
      type: "node--fellow",
      id: id,
      attributes: {
        field_fd_saved_searches: searchData,
      },
    },
  };

  const opts = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  };

  return fetch(url, opts).then(async (res) => {
    const data = await res.json();
    if (res.status === 200) {
      return data;
    }
    throw new Error(data?.errors?.title);
  });
};
// api for update organizations & projects
export const updateOrgAndProject = async (data1) => {
  const token = await localStorage.getItem("token");
  const id = await localStorage.getItem("profile_uuid");

  if (!token) return;

  if (!id) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.NODE}${constants.FELLOW}/${id}${constants.RELATIONSHIPS}/field_fd_projects`
  );

  const data = {
    data: data1,
  };

  const opts = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  };

  return fetch(url, opts).then(async (res) => {
    //const data = await res.json();
    if (res.status === 204) {
      return res;
    }
    // throw new Error(data?.errors?.title);
  });
};
// api for create New Orgnization & Project
export const createNewOrgAndProject = async (data1) => {
  const token = await localStorage.getItem("token");
  if (!token) return;
  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.PARAGRAPH}${constants.ORGANIZATION_AND_PROJECT}`
  );
  const opts = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify({
      data: data1,
    }),
  };
  return fetch(url, opts).then(async (res) => {
    const data = await res.json();
    if (res.status === 201) {
      return data;
    }
  });
};
//api for existing data update
export const updateExistingOrgAndProject = async (data1, orgProjectId) => {
  const token = await localStorage.getItem("token");
  const id = await localStorage.getItem("profile_uuid");

  if (!token) return;

  if (!id) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.PARAGRAPH}${constants.ORGANIZATION_AND_PROJECT}/${orgProjectId}`
  );

  const data = {
    data: data1,
  };

  const opts = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  };

  return fetch(url, opts).then(async (res) => {
    const data = await res.json();
    if (res.status === 200) {
      return data;
    }
  });
};
//api for delete orgAndProject
export const deleteOrgAndProject = async (orgProjectId) => {
  const token = await localStorage.getItem("token");
  const id = await localStorage.getItem("profile_uuid");

  if (!token || !id) return;

  const url = new URL(
    `${constants.BASE_URL}${constants.JSONAPI}${constants.PARAGRAPH}${constants.ORGANIZATION_AND_PROJECT}/${orgProjectId}`
  );

  const opts = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    },
  };

  return fetch(url, opts).then(async (res) => {
    if (res.status === 204) {
      return res;
    }
  });
};

export const createContact = async (createContactData) => {
  const token = await localStorage.getItem("token");
  const profile_uuid = await localStorage.getItem("profile_uuid");

  if (!token) return;

  const url = new URL(
    `${constants.BASE_URL_NO_LANG}${constants.RESTAPI}${constants.SENDMAIL}`
  );

  const data = {
    uuid:profile_uuid,
    help:createContactData.help,
    message:createContactData.message
  };

  const opts = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data)
  };
  return fetch(url, opts).then(async (res) => {
    const data = await res.json();
    if (res.status === 200) {
      return { data };
    }
    throw new Error(data?.errors?.title);
  });
};
