import "./fellowprofile.scss";

import React, { useEffect, useState } from "react";
import {
  getAvailableMediaContacts,
  getFellowImage,
  getFellowLocation,
  getRelationshipMappings,
  renderIcon,
} from "../../services/helper-functions";
import { instantMessangers, medias } from "../../services/constantValues";

import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import ContactMethods from "../basic-components/contactMethods";
import CustomButton from "../basic-components/button";
import DisplayOrganizations from "../basic-components/displayorganizations";
import DisplayWorkFields from "../basic-components/displayworkfields";
import { Link } from "react-router-dom";
import { ReactComponent as LocationIcon } from "../../images/location-icon.svg";
import { ReactComponent as MailIcon } from "../../images/mail-icon.svg";
import { ReactComponent as PhoneIcon } from "../../images/phone-icon.svg";
import SkeletonFellowCardLoader from "../Loaders/skeletonFellowCardLoader";
import {
  getOtherFellowsInfo,
  getOrganizationDetails,
} from "../../services/fellow.getService";
import { getSocialMedias } from "../../services/helper-functions";
import { useGlobalState } from "../context/globalContext";
import { useTranslation } from "react-i18next";
import ISO6391 from "iso-639-1";
import { transLanguage, langDetect } from "../../services/fellow.patchService";
import { allowedLaguages } from "../../services/constantValues";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const FellowProfile = (props) => {
  const {
    addFellowToContactList,
    match,
    contactList,
    removeFellowFromContactList,
    setContactList,
    loaders,
  } = props;
  const { contactLoader } = loaders;
  const [fellowInfo, setFellowInfo] = useState({});
  const { currentProfileImg, fellowData, userImage, countriesInJson } =
    useGlobalState();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(["profileView", "common", "forms"]);
  const [loader, setLoader] = useState(false);
  const userId = match?.params?.nid;

  const [status, setStatus] = useState(false); // flag to display translated text
  const [detectStatus, setdetectStatus] = useState(false); // flag to display detected language
  const [resData, setresData] = useState("");
  const [langName, setlangName] = useState("");
  const [originalLangcode, setoriginalLangcode] = useState("");
  const [originalLangName, setoriginalLangName] = useState("");
  const [organizationsDetails, setOrganizationsDetails] = useState([]);

  const onClose = () => {
    setOpen((state) => !state);
  };

  const showAllContacts = (socialmedias, mediasToSearch, isIm) => {
    const mediasArray = getSocialMedias(socialmedias, mediasToSearch, isIm);
    return (
      mediasArray?.length > 0 &&
      mediasArray.map((media, index) => (
        <div className="list" key={index}>
          {renderIcon(media.title.toLowerCase(), "icon contact-icons")}
          <a
            className={`media-values`}
            href={
              media.title === "hangout" ? media.url : media.url + media.value
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {media.value}
          </a>
        </div>
      ))
    );
  };

  const shoOnlyAvailableMediaContact = (
    socialMedias,
    contact_prefs,
    phone,
    email,
    allMediasArray
  ) => {
    const { allAllowedMedias, isEmailPrefered, isPhonePrefered } =
      getAvailableMediaContacts(
        socialMedias,
        contact_prefs,
        phone,
        email,
        allMediasArray
      );
    return (
      <>
        {/*  if email exists and allowed to contact or if no other contact has been allowed then display email */}
        {(isEmailPrefered || !allAllowedMedias?.length) && (
          <div className="list">
            <MailIcon className="icon contact-icons" />
            <a
              href={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer"
              className="medias"
            >
              {email || "NA"}
            </a>
          </div>
        )}
        {/*  if phone exists(excluding the ISO code) and allowed to contact then display phone */}
        {isPhonePrefered && (
          <div className="list">
            <PhoneIcon className="icon contact-icons" />
            <a href={`tel:${phone}`} target="_blank" rel="noopener noreferrer">
              {phone}
            </a>
          </div>
        )}
        {/* Show all the allowed media contacts if exists */}
        {allAllowedMedias?.length > 0 &&
          allAllowedMedias.map((media, index) => (
            <div className="list" key={index}>
              {renderIcon(media.title.toLowerCase(), "icon contact-icons")}
              <a
                className={`media-values`}
                href={media.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {media.value}
              </a>
            </div>
          ))}
      </>
    );
  };
  useEffect(() => {
    var langCode = localStorage.i18nextLng;
    var resultLangName = search(langCode, allowedLaguages);
    setlangName(resultLangName);
    var resultLangName1 = search(originalLangcode, allowedLaguages);
    setoriginalLangName(resultLangName1);
  }, [originalLangName, langName, originalLangcode]);
  useEffect(() => {
    setLoader(true);
    if (userId) {
      getOtherFellowsInfo(userId).then((res) => {
        if (res) {
          console.log(res, 'res');
          getOrganizationDetails(res.data[0].id).then((res) => {
            if (res.data) {
              var temp10 = [];
              var temp11 = res.data;
              for (var i = 0; i < res.data.length; i++) {
                if (res.data[i].attributes.field_project_primary) {
                  temp10 = res.data[i];
                  temp11.splice(i, 1);
                }
              }
              if (Object.keys(temp10).length > 0) {
                temp11.unshift(temp10);
              }
              setOrganizationsDetails(temp11);
              //setOrganizationsDetails(res.data);
            }
          });
          setFellowInfo({ ...res, data: res.data[0] });

          detectLang(res.data[0]); //changes
          setLoader(false);
        }
      });
    } else {
      getOrganizationDetails(
        fellowData && fellowData.data && fellowData.data.id
      ).then((res) => {
        if (res) {
          var temp10 = [];
          var temp11 = res.data;
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].attributes.field_project_primary) {
              temp10 = res.data[i];
              temp11.splice(i, 1);
            }
          }
          if (Object.keys(temp10).length > 0) {
            temp11.unshift(temp10);
          }
          setOrganizationsDetails(temp11);
          // setOrganizationsDetails(res.data);
        }
      });
      setLoader(false);
      setFellowInfo(fellowData);
    }
  }, [fellowData, userId]);

  useEffect(() => {
    if (fellowData?.data?.relationships?.field_fd_contact_list?.data) {
      const list = getRelationshipMappings(
        fellowData?.data?.relationships?.field_fd_contact_list?.data,
        fellowData.included
      );
      setContactList((state) => ({
        data: list || [],
        include: fellowData.included,
      }));
    }
  }, [fellowData, setContactList]);
  //------changes------

  const detectLang = (temp) => {
    var temp1 =  // eslint-disable-line
      temp?.attributes?.field_fd_bio ||
      temp?.attributes?.field_fellow_introduction;
    const textData = {
      q: temp1,
    };
    langDetect(textData).then((res) => {
      setoriginalLangcode(res.data.detections[0][0].language);
      if (res.data.detections[0][0].language === localStorage.i18nextLng) {
        setdetectStatus(true);
      } else {
        setdetectStatus(false);
      }
    });
  };

  const textTranslate = () => {
    var temp =
      fellowInfo?.data?.attributes?.field_fd_bio ||
      fellowInfo?.data?.attributes?.field_fellow_introduction;
    const textData = {
      q: temp,
      target: localStorage.i18nextLng,
      //target: "mr",
    };
    transLanguage(textData).then((res) => {
      setresData(res.data.translations[0].translatedText);
      setStatus(true);
    });
  };
  // fuction for detect langName from langCode
  function search(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].code === nameKey) {
        return myArray[i].title;
      }
    }
  }
  let phoneNo = fellowInfo?.data?.attributes?.field_phone?.split(",");

  //show location only if available
  const getLocation = () => {
    let location =
      fellowInfo?.data?.attributes?.field_fd_fellow_location &&
      getFellowLocation(
        fellowInfo.data.attributes.field_fd_fellow_location,
        countriesInJson
      );
    return (
      location && (
        <div className="user-location">
          <LocationIcon className="location-icon" />
          <span className="subdetails">{location}</span>
        </div>
      )
    );
  };

  const getContactButtons = () => {
    //check if other fellow is in contact list or not
    let isFellowExistInContact = false;
    for (let i = 0; i < contactList?.data?.length; i++) {
      if (contactList.data[i].id === fellowInfo?.data?.id) {
        isFellowExistInContact = true;
        break;
      }
    }
    return (
      <>
        <CustomButton
          text={
            isFellowExistInContact
              ? t("common:contact_list.unsave_button")
              : t("common:contact_list.save_button")
          }
          onClick={() =>
            isFellowExistInContact
              ? removeFellowFromContactList(fellowInfo?.data?.id)
              : addFellowToContactList(fellowInfo?.data?.id)
          }
          classes="outlined-btn saveremovecontact"
          variant="outlined"
          bgClr="#E3E6FC"
          isLoading={
            contactLoader?.id === fellowInfo?.data?.id
              ? contactLoader.isLoading
              : undefined
          }
        />
        <CustomButton
          text={t("common:contact_button")}
          onClick={onClose}
          classes="filled-btn contact"
          variant="contained"
        />
      </>
    );
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleDialogOpen = (scrollType) => () => {
    setDialogOpen(true);
    setScroll(scrollType);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (dialogOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open, dialogOpen]);

  return (
    <div className="fellow-profile">
      <div className="content-wrapper">
        {fellowData && !loader ? (
          <>
            <div className="header">
              <div className="profile">
                <Avatar
                  alt="user icon"
                  src={
                    match?.params?.nid
                      ? getFellowImage(
                          fellowInfo && fellowInfo.included,
                          fellowInfo?.data?.relationships
                            ?.field_media_profile_image?.data?.id
                        ) || currentProfileImg
                      : userImage || currentProfileImg
                  }
                  className="user-icon"
                />
                <div className="user-details">
                  <div className="with-edit">
                    <div className="user-name">
                      {fellowInfo.data &&
                        fellowInfo.data.attributes &&
                        fellowInfo.data.attributes.title}
                    </div>
                  </div>
                  {getLocation()}
                  {fellowInfo?.data?.attributes?.field_collaboration_status && (
                    <div className="collaboration">
                      <Chip
                        label={t("seeking_collboration")}
                        className="active-field"
                      />
                    </div>
                  )}
                </div>
              </div>
              {fellowInfo?.data?.attributes?.field_fd_profile_status && (
                <div className="user-status">{`"${fellowInfo?.data?.attributes?.field_fd_profile_status}"`}</div>
              )}
            </div>
            {/* for other fellow profiles */}
            {match?.params?.nid ? (
              <div className="button-wrapper">{getContactButtons()}</div>
            ) : (
              // for own fellow profile
              <Link to="/settings">
                <CustomButton
                  text={t("forms:profile.edit_button.label")}
                  classes="editProfile outlined-btn editprofile"
                  variant="outlined"
                  bgClr="#E3E6FC"
                />
              </Link>
            )}
            {/* bio */}
            {(fellowInfo?.data?.attributes?.field_fd_bio ||
              fellowInfo?.data?.attributes?.field_fellow_introduction) && (
              <div className="content-box">
                <div className="header">
                  <h1 className="head-text">{t("bio.title")}</h1>
                </div>
                <div hidden={status} className="box-content">
                  {fellowInfo?.data?.attributes?.field_fd_bio ||
                    fellowInfo?.data?.attributes?.field_fellow_introduction}
                </div>
                <div hidden={!status} className="box-content">
                  {resData}
                </div>
                <span hidden={detectStatus}>
                  <span hidden={detectStatus || status} className="translate">
                    <a
                      className="orgs-websites website translate"
                      href="#"
                      onClick={textTranslate}
                      style={{ color: "#6ad29b" }}
                    >
                      Translate to {langName}
                    </a>
                    {/* <Chip label="Translate this" onClick={textTranslate}></Chip> */}
                  </span>
                  <span hidden={!detectStatus && !status}>
                    <a
                      href="#"
                      onClick={() => setStatus(false)}
                      className="orgs-websites website"
                      style={{ color: "#6ad29b" }}
                    >
                      Translated from {originalLangName} by GOOGLE.
                      <br />
                      See Original
                    </a>
                    {/* <Chip label="Back" onClick={() => setStatus(false)}></Chip> */}
                  </span>
                </span>
              </div>
            )}

            <DisplayWorkFields
              text={t("work_fields.title")}
              fieldsOfWork={fellowInfo?.data?.relationships?.field_topics.data}
              includedIn={fellowInfo?.included}
              isClickable={true}
            />

            <DisplayWorkFields
              text={t("common:speaking_lang.title")}
              fieldsOfWork={ISO6391.getLanguages(
                fellowInfo?.data?.attributes?.field_fd_spoken_languages
              )}
              includedIn={[]}
              isClickable={false}
            />

            <DisplayWorkFields
              className="tags"
              text={t("interests.title")}
              fieldsOfWork={
                fellowInfo.data &&
                fellowInfo.data.relationships &&
                fellowInfo.data.relationships.field_tags &&
                fellowInfo.data.relationships.field_tags.data
              }
              includedIn={
                fellowInfo && fellowInfo.included && fellowInfo.included
              }
            />
            <DisplayOrganizations
              text={t("organizations.title")}
              organizations={organizationsDetails}
            />

            {(fellowInfo?.data?.attributes?.field_fellow_introduction || fellowInfo?.data?.attributes?.field_fellow_the_new_idea || fellowInfo?.data?.attributes?.field_fellow_the_problem || fellowInfo?.data?.attributes?.field_fellow_the_strategy || fellowInfo?.data?.attributes?.field_fellow_the_person) && (
              <>
                <div className="content-box same-space">
                  <button onClick={handleDialogOpen('paper')} className="board-link">View Board Profile</button>
                </div>
                <Dialog
                  open={dialogOpen}
                  onClose={handleDialogClose}
                  scroll={scroll}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                  className="dialog-wrapper"
                >
                  <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                      id="scroll-dialog-description"
                      ref={descriptionElementRef}
                      tabIndex={-1}
                    >
                      <div className="accordion-wrapper">
                        {(fellowInfo?.data?.attributes?.field_fellow_introduction) && (
                          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>Introduction</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <div>{fellowInfo?.data?.attributes?.field_fellow_introduction}</div>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        )}

                        {(fellowInfo?.data?.attributes?.field_fellow_the_new_idea) && (
                          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                            >
                              <Typography>The New Idea</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <div>{fellowInfo?.data?.attributes?.field_fellow_the_new_idea}</div>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        )}

                        {(fellowInfo?.data?.attributes?.field_fellow_the_problem) && (
                          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel3a-content"
                              id="panel3a-header"
                            >
                              <Typography>The Problem</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <div>{fellowInfo?.data?.attributes?.field_fellow_the_problem}</div>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        )}

                        {(fellowInfo?.data?.attributes?.field_fellow_the_strategy) && (
                          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel4a-content"
                              id="panel4a-header"
                            >
                              <Typography>The Strategy</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <div>{fellowInfo?.data?.attributes?.field_fellow_the_strategy}</div>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        )}

                        {(fellowInfo?.data?.attributes?.field_fellow_the_person) && (
                          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel5a-content"
                              id="panel5a-header"
                            >
                              <Typography>The Person</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <div>{fellowInfo?.data?.attributes?.field_fellow_the_person}</div>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <CustomButton
                      text={t("Dismiss")}
                      onClick={handleDialogClose}
                      classes="outlined-btn"
                      variant="outlined"
                      bgClr="#E3E6FC"
                    />
                  </DialogActions>
                </Dialog>
              </>
            )}
            <div className="content-box contact">
              <div className="header">
                <h1 className="head-text">{t("contact.title")}</h1>
              </div>
              <div className="box-content">
                {match?.params?.nid ? (
                  <>
                    {/* Prefered social medias of other fellow profile */}
                    {shoOnlyAvailableMediaContact(
                      {
                        ...fellowInfo?.data?.attributes
                          ?.field_other_social_media_links?.platform_values,
                        ...fellowInfo?.data?.attributes?.field_fd_fellow_ims
                          ?.platform_values,
                      },
                      fellowInfo?.data?.attributes
                        ?.field_fd_contact_preferences,
                      fellowInfo?.data?.attributes?.field_phone,
                      fellowInfo?.data?.attributes?.field_email,
                      [...medias, ...instantMessangers]
                    )}
                  </>
                ) : (
                  <>
                    {/* All social medias of own fellow profile */}
                    <div className="list">
                      <MailIcon className="icon contact-icons" />
                      <a
                        href={`mailto:${fellowInfo?.data?.attributes?.field_email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="medias"
                      >
                        {fellowInfo?.data?.attributes?.field_email || "NA"}
                      </a>
                    </div>
                    {phoneNo &&
                      phoneNo.length > 1 &&
                      phoneNo[phoneNo.length - 1] && (
                        <div className="list">
                          <PhoneIcon className="icon contact-icons" />
                          <a
                            href={`tel:${fellowInfo?.data?.attributes?.field_phone}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {fellowInfo?.data?.attributes?.field_phone || "NA"}
                          </a>
                        </div>
                      )}
                    {showAllContacts(
                      fellowInfo?.data?.attributes
                        ?.field_other_social_media_links?.platform_values || {},
                      medias,
                      false
                    )}
                    {showAllContacts(
                      fellowInfo?.data?.attributes?.field_fd_fellow_ims
                        ?.platform_values || {},
                      instantMessangers,
                      true
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <SkeletonFellowCardLoader />
        )}
      </div>
      <ContactMethods
        onClose={onClose}
        open={open}
        smAccs={
          fellowInfo?.data?.attributes?.field_other_social_media_links
            ?.platform_values
        }
        imAccs={
          fellowInfo?.data?.attributes?.field_fd_fellow_ims?.platform_values
        }
        contactPrefs={
          fellowInfo?.data?.attributes?.field_fd_contact_preferences
        }
        email={fellowInfo?.data?.attributes?.field_email || ""}
        phone={fellowInfo?.data?.attributes?.field_phone || ""}
      />
    </div>
  );
};

export default FellowProfile;
