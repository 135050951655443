import "./contactform.scss";

import React, { useEffect } from "react";
import { instantMessangers, medias } from "../../services/constantValues";
import { useContactDispatch, useContactState } from "../context/contactContext";

import NewSocialMediaField from "../basic-components/addmedia/newSocialMediaField";
import Textfield from "../basic-components/textfield";

const ContactForm = (props) => {
  const {
    contactinfo,
    mediasToContact,
    smaccounts,
    imaccounts,
  } = useContactState();
  const { setErrorValues, errors, t } = props;
  const contactDispatch = useContactDispatch();

  const handleCountryCode = (event) => {
    const { value, name } = event.target;
    contactDispatch({ type: "setcontactinfo", payload: { value, name } });
  };

  const handleMobileNumber = (event) => {
    const { value, name } = event.target;
    contactDispatch({ type: "setcontactinfo", payload: { value, name } });
  };

  const onMediaSelector = (value, index, name, type) => {
    let accountDetails = type === 0 ? [...smaccounts] : [...imaccounts];
    accountDetails[index][name] = value;
    contactDispatch({
      type: type === 0 ? "addSmAccounts" : "addImAccounts",
      payload: accountDetails,
    });
  };

  const onLinkSelector = (name, value, index) => {
    let accountDetails = [...smaccounts];
    if (value) accountDetails[index][name] = value;
    //not allowing user to to remove the selected media account's url
    if (value.search(accountDetails[index].mediatype?.url) === -1) {
      return;
    }
    //check if the media account has selected e.g "Facebook" or "Twitter" else make it as "Other" media type
    if (value && !accountDetails[index].mediatype?.title)
      isContainSocialMedia(value, index, medias);
    contactDispatch({ type: "addSmAccounts", payload: accountDetails });
    //remove from contact preference if made empty
    if (!value?.handler) {
      removeLastEmptyMediaFromPreference(accountDetails, index);
    }
  };

  const onImLinkSelector = (name, value, index) => {
    let accountDetails = [...imaccounts];
    accountDetails[index][name] = value;
    contactDispatch({ type: "addImAccounts", payload: accountDetails });
    experessionValidations(value, index);
    if (!value?.handler) {
      removeLastEmptyMediaFromPreference(accountDetails, index);
    }
  };

  const emailValidation = (email) => {
    const emailExpression = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gim;
    return emailExpression.test(email);
  };

  const experessionValidations = (value, index) => {
    let data = { ...errors };
    if (!data.imMediaLink[index]) data.imMediaLink[index] = {};
    let accountDetails = [...imaccounts];
    let mediatype = accountDetails[index].mediatype
      ? accountDetails[index].mediatype.title.toLowerCase()
      : "";

    const validationFunction = (value, validator, msg) => {
      if (value && !validator(value)) {
        data.imMediaLink[index].isErorr = true;
        data.imMediaLink[index].msg = msg;
      } else {
        data.imMediaLink[index].isErorr = false;
        data.imMediaLink[index].msg = "";
      }
    };

    switch (mediatype) {
      case "hangout":
        validationFunction(value, emailValidation, "Please enter valid email");
        break;
      case "":
        validationFunction(value, () => false, "Please select the account.");
        break;
      default:
        return;
    }
    setErrorValues(data);
  };

  const isContainSocialMedia = debounce((value, index, array) => {
    let isPresent;
    array.forEach((elem) => {
      if (value.toLowerCase().search(elem.title.toLowerCase()) !== -1) {
        isPresent = elem;
      }
    });
    let accountDetails = [...smaccounts];
    //check if there is valid media account type selected else assign it as "Other" media type
    if (isPresent) accountDetails[index].mediatype = isPresent;
    else accountDetails[index].mediatype = { title: "Other" };
    contactDispatch({ type: "addSmAccounts", payload: accountDetails });
  }, 500);

  const removeLastEmptyMediaFromPreference = (accs, index) => {
    //remove media from mediatocontacts array
    let contactMedias = [...mediasToContact];
    let mediaIndex = -1;
    for (let i = 0; i < contactMedias.length; i++) {
      if (contactMedias[i].title === accs[index]?.mediatype?.title) {
        mediaIndex = i;
        break;
      }
    }
    if (mediaIndex !== -1) contactMedias.splice(mediaIndex, 1);
    contactDispatch({ type: "setAllMedias", payload: contactMedias });
  };

  const deleteMediaType = (type, index) => {
    let accs = type === 0 ? [...smaccounts] : [...imaccounts];
    let data = { ...errors };
    if (accs.length === 1) {
      if (accs[index]?.handler) {
        removeLastEmptyMediaFromPreference(accs, index);
      }
      //remove from smaccounts/imaccounts
      accs[index] = { mediatype: null, handler: "" };
      contactDispatch({
        type: type === 0 ? "addSmAccounts" : "addImAccounts",
        payload: accs,
      });
      type === 1 && data.imMediaLink.splice(index, 1);
      type === 1 && setErrorValues(data);
      return;
    }
    type === 1 && data.imMediaLink.splice(index, 1);
    accs.splice(index, 1);
    type === 1 && setErrorValues(data);
    contactDispatch({
      type: type === 0 ? "addSmAccounts" : "addImAccounts",
      payload: accs,
    });
  };

  const addNewAccount = (type) => {
    let accs = type === 0 ? [...smaccounts] : [...imaccounts];
    accs.push({ mediatype: null, handler: "" });
    contactDispatch({
      type: type === 0 ? "addSmAccounts" : "addImAccounts",
      payload: accs,
    });
  };

  const getContactMediaPreferances = (smaccounts, imaccounts) => {
    //get all non dublicate user accounts
    const data = [...smaccounts, ...imaccounts];
    const allSocialMedias = data.reduce((accumulator, currentValue) => {
      if (currentValue.mediatype) {
        // removing duplicates and null values from smaccounts and imaccounts
        if (
          !accumulator.find(
            (item) => item.title === currentValue.mediatype.title
          )
        ) {
          if (
            currentValue.mediatype.title &&
            currentValue.handler &&
            currentValue.mediatype.title !== "Other"
          ) {
            accumulator.push({
              title: currentValue.mediatype.title,
              isAllowed: false,
            });
          }
        }
      }
      return accumulator;
    }, []);

    //add new filled/valid social medias to mediaTocontactFrom
    if (allSocialMedias.length > 0) {
      let all = [...mediasToContact];
      allSocialMedias.forEach((item) => {
        if (!mediasToContact.find((acc) => acc.title === item.title)) {
          all.push(item);
        }
      });

      //remove empty/invalid entered social medias from mediaTocontactFrom
      mediasToContact.forEach((item, index) => {
        if (
          !allSocialMedias.find((acc) => acc.title === item.title) &&
          item.title !== "Phone Number" &&
          item.title !== "Email"
        ) {
          all.splice(index, 1);
        }
      });

      //saving contactpreferances to contact context
      contactDispatch({ type: "setAllMedias", payload: all });
    }
  };

  useEffect(() => {
    getContactMediaPreferances(smaccounts, imaccounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smaccounts[0], smaccounts, imaccounts]);

  useEffect(() => {
    const callAsync = async () => {
      await getContactMediaPreferances(smaccounts, imaccounts);
    };
    callAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //add phone number to mediaTocontactFrom if valid mobile number provided else remove from mediaTocontactFrom
    let data = [...mediasToContact];
    let index;
    for (let i = 0; i < data.length; i++) {
      if (data[i].title === "Phone Number") {
        index = i;
        break;
      }
    }
    if (!index && contactinfo.mobile && !errors.mobile.isErorr)
      data.push({ title: "Phone Number", isAllowed: false });
    else {
      if ((index !== undefined && errors.mobile.isErorr) || !contactinfo.mobile)
        data.splice(index, 1);
    }
    contactDispatch({ type: "setAllMedias", payload: data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactinfo.mobile]);

  return (
    <>
      <div className="mobile-info">
        <Textfield
          id="countr code"
          onChange={handleCountryCode}
          value={contactinfo.ccode}
          label={t("contact_info.form_fields.country_code.lable")}
          name="ccode"
          error={errors.ccode.isErorr}
          errorMsg={errors.ccode.isErorr && errors.ccode.msg}
        />
        <Textfield
          id="mobile number"
          onChange={handleMobileNumber}
          value={contactinfo.mobile}
          label={t("contact_info.form_fields.phone_number.lable")}
          name="mobile"
          error={errors.mobile.isErorr}
          errorMsg={errors.mobile.isErorr && errors.mobile.msg}
        />
      </div>

      {/* adding social medias */}
      <div className="social-media">
        <div className="media-wrapper">
          {smaccounts &&
            smaccounts.map((acc, index) => {
              return (
                <NewSocialMediaField
                  key={index}
                  account={acc}
                  label={t(
                    "contact_info.form_fields.add_social_media.placeholder"
                  )}
                  onMediaSelector={onMediaSelector}
                  onLinkSelector={onLinkSelector}
                  deleteMediaType={deleteMediaType}
                  mediasToDisplay={medias}
                  index={index}
                  type={0}
                  showCloseIcon={smaccounts.length === 1 ? false : true}
                  filledAccounts={smaccounts}
                />
              );
            })}
        </div>
        <div className="add" onClick={() => addNewAccount(0)}>
          <p className="resend">
            + {t("contact_info.form_fields.add_social_media.lable")}
          </p>
        </div>
      </div>

      <div className="social-media">
        <div className="media-wrapper">
          {imaccounts &&
            imaccounts.map((acc, index) => {
              return (
                <NewSocialMediaField
                  key={index}
                  account={acc}
                  label={t(
                    "contact_info.form_fields.add_instant_messanger.placeholder"
                  )}
                  onMediaSelector={onMediaSelector}
                  onLinkSelector={onImLinkSelector}
                  deleteMediaType={deleteMediaType}
                  mediasToDisplay={instantMessangers}
                  index={index}
                  type={1}
                  isErorr={
                    errors.imMediaLink[index] &&
                    errors.imMediaLink[index].isErorr
                  }
                  errorMsg={
                    errors.imMediaLink[index] && errors.imMediaLink[index].msg
                  }
                  showCloseIcon={imaccounts.length === 1 ? false : true}
                  filledAccounts={imaccounts}
                />
              );
            })}
        </div>
        <div className="add" onClick={() => addNewAccount(1)}>
          <p className="resend">
            + {t("contact_info.form_fields.add_instant_messanger.lable")}
          </p>
        </div>
      </div>
    </>
  );
};

function debounce(fn, wait) {
  let t;
  return function () {
    clearTimeout(t);
    t = setTimeout(() => fn.apply(this, arguments), wait);
  };
}

export default ContactForm;
