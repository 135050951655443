import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import CustomButton from "../../../components/basic-components/button";
import "../../dashboard/dashboard.scss";
import "./Modal.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Textfield from "../../basic-components/textfield";
import {
  getActivityFeed,
  getAllInterests,
  getAllWorkFields,
} from "../../../services/fellow.getService";
import { useGlobalDispatch, useGlobalState } from "../../context/globalContext";
import { createNewPost } from "../../../services/fellow.patchService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
toast.configure();

function Modal({ setOpenModal, setActivityFeedData }) {
  const { currentProfileImg, userImage, fellowData, fieldsOfWork, interests, countries } =
    useGlobalState();
  const { t } = useTranslation(["headerAndDashboard"]);
  const globalDispatch = useGlobalDispatch();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const [fieldData, setFieldData] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [postData, setPostData] = useState([]);
  const [linkData, setLinkData] = useState([]);
  const [flag, setFlag] = useState( // eslint-disable-line
    window.screen.availWidth > 480 ? true : false
  );
  const [loader, setLoader] = useState(false);
  const [locationData, setLocationData] = useState({
    states: [],
    state: null,
    country: null,
    city: "",
    status: "",
    collaborationStatus: false,
    region: null,
    organizations: [{ title: "", uri: "" }],
    mediasToContact: [{ title: "Email", isAllowed: true }],
  });
  useEffect(() => {
    if (!fieldsOfWork?.length) {
      getAllWorkFields().then((res) => {
        if (res)
          globalDispatch({
            type: "setListData",
            payload: { name: "fieldsOfWork", value: res.data || [] },
          });
      });
    }
    if (!interests?.length) {
      getAllInterests().then((res) => {
        if (res)
          globalDispatch({
            type: "setListData",
            payload: { name: "interests", value: res.data },
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onchangePost = (e) => {
    e.target.name = e.target.value;
    setPostData(e.target.value);
  };
  const onchangeLink = (e) => {
    e.target.name = e.target.value;
    setLinkData(e.target.value);
  };
  const onCreatePost = () => {
    if (postData.length === 0) {
      toast.error("Post Can't be empty!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      setLoader(true);
      let finalfData = [];
      let fData = fieldData;
      for (var i = 0; i < fData.length; i++) {
        var id1 = fData[i].id;
        var type = "taxonomy_term--topics";
        finalfData.push({ id: id1, type: type });
      }
      let finaltData = [];
      let tData = tagsData;
      for (var a = 0; a < tData.length; a++) {
        var id2 = tData[a].id;
        type = "taxonomy_term--tags";
        finaltData.push({ id: id2, type: type });
      }
      let createPostData = [];
      createPostData = {
        title:
          fellowData.data &&
          fellowData.data.attributes &&
          fellowData.data.attributes.title, //current fellow name
        field_post_content: postData,
        field_post_link: linkData,
        field_location: locationData.country == null ? '' : locationData.country.title
      };
      //api call for created post
      createNewPost(createPostData, finalfData, finaltData).then((res) => {
        if (res) {
          getActivityFeed().then((res) => {
            if (res) {
              setActivityFeedData(res);
              setOpenModal(false);
              toast.success("Post Created!", {
                position: toast.POSITION.BOTTOM_CENTER,
              });
            }
          });
        } else {
          setOpenModal(false);
          toast.error("Error", { position: toast.POSITION.BOTTOM_CENTER });
        }
      });
    }
  };
  //details for location form
  const setLocationDetails = (value, name) => {
    setLocationData((state) => ({ ...state, [name]: value }));

    //if there are values for country field
    if (name === "country" && value) {
      //if there are values for subdivisions field
      if (value.subdivisions.length > 0)
        setLocationData((state) => ({ ...state, states: value.subdivisions }));
      else
        setLocationData((state) => ({
          ...state,
          city: "",
          states: [],
          state: null,
        }));
    }
    console.log(locationData, 'locationData1')
  };
  return (
    <div
      className="overlay_background"
      style={{
        position: "fixed",
        zIndex: flag ? 0 : 1,
        paddingLeft: flag ? "0" : "5%",
        paddingRight: flag ? "0" : "5%",
        overflowX: "scroll",
      }}
    >
      <div
        className="modalContainer"
        style={{ height: flag ? "625px" : "600px" }}
      >
        <div className="header-popup">
          <p>{t("activity_feed.add_popup_heading")}</p>
        </div>
        {/* <div className="title"></div> */}
        <div className="user" style={{ display: "flex" }}>
          <div className="User-info">
            {token && !location?.pathname?.startsWith("/onboarding/") && (
              <img
                src={userImage || currentProfileImg}
                className="avt-img"
                alt="user profile"
              />
            )}
          </div>
          <div style={{ marginTop: "3%", marginLeft: flag ? "2%" : "12%" }}>
            {fellowData.data &&
              fellowData.data.attributes &&
              fellowData.data.attributes.title}
          </div>
        </div>
        <div
          className="body"
          style={{
            flex: flag ? "50%" : "none",
            marginTop: flag ? "0" : "10%",
            marginBottom: flag ? "0" : "10%",
          }}
        >
          {/* Post Content */}
          <div className="content-box workfields" style={{ width: "100%" }}>
            <div className="popup-field">
              <TextField
                label="New Post*"
                rows={4}
                variant="filled"
                name="new_post"
                onChange={onchangePost}
                //  multiline={true}
                fullWidth
                multiline
              />
            </div>
            {/* Post Link */}
            <div className="popup-field">
              <Textfield
                label="Add link"
                rows={4}
                defaultValue="Default Value"
                variant="filled"
                name="add_link"
                onChange={onchangeLink}
                multiline={true}
              />
            </div>
            {/* Location */}
            <div className="popup-field">
            <Autocomplete
              value={locationData.country}
              options={countries}
              onChange={(event, value) => setLocationDetails(value, "country")}
              name="country"
              className="autocomplete-feilds address-fields"
              getOptionLabel={(option) => option.title}
              getOptionSelected={(option) =>
                option.title === locationData.country.title || option.code === locationData.country.code || null
              }
              renderInput={(params) => {
                params.inputProps.autoComplete = "new-password";
                return (
                  <TextField
                    {...params}
                    label='Add Location'
                    variant="filled"
                  />
                );
              }}
            />
            </div>

            {/* Field Of WOrk */}
            <div className="popup-field">
              <Autocomplete
                multiple
                options={fieldsOfWork}
                limitTags={1}
                getOptionLabel={(option) => option?.attributes?.name || ""}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  setFieldData(value);
                }}
                name="work"
                className="autocomplete-feilds1"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Add Fields of work"
                    placeholder="Add Fields of work"
                  />
                )}
              />
            </div>
            {/* fields of interests */}
            <Autocomplete
              multiple
              options={interests}
              limitTags={1}
              getOptionLabel={(option) => option?.attributes?.name || ""}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(event, value) => {
                setTagsData(value);
              }}
              name="tags"
              className="autocomplete-feilds1"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Add Interest tags"
                  placeholder="Add Interest tags"
                />
              )}
            />
          </div>
        </div>
        <div>
          <CustomButton
            variant="contained"
            style={{
              backgroundColor: "#4155ee",
              width: "20%",
              margin: "2%",
              float: "right",
              marginTop: "3%",
              color: "white",
            }}
            onClick={onCreatePost}
            classes="btn postClicked post-analytics"
            text={t("activity_feed.post")}
            isLoading={loader}
          />
          {/* <Button
            variant="contained"
            style={{
              backgroundColor: "#4155ee",
              width: "20%",
              margin: "2%",
              float: "right",
              marginTop: "3%",
            }}
            onClick={onCreatePost}
          >
            {t("activity_feed.post")}
          </Button> */}
          <Button
            variant="text"
            style={{
              marginTop: "3%",
              color: "slategray",
              float: "right",
              width: "20%",
            }}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            {t("activity_feed.cancel")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
