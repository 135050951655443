import React, { useState } from "react";
import contactList from "../../../images/contact-list-bg.svg";
import connectFellows from "../../../images/connect-fellows-bg.svg";
import updateProfile from "../../../images/update-profile-bg.svg";
import CustomButton from "../../basic-components/button";
import { useTranslation } from "react-i18next";
import LanguagePicker from "../../basic-components/languagePicker";
import "./finishscreen.scss";
import { useHistory } from "react-router-dom";
import { updatePrefrredLanguage } from "../../../services/fellow.patchService";
import ErrorAlert from "../../basic-components/snackbar";
import ownContactList from "../../../images/Screenshot-own-contact-list.png";
import groupImage from "../../../images/Group 61.svg";

const FinishScreen = (props) => {
  const { t } = useTranslation(["forms"]);
  let history = useHistory();
  const { lang, updateFDLanguage } = props;
  const [errors, setErrors] = useState({
    errorSeverity: "",
    openSnackbar: false,
    msg: "",
  });
  const [loader, setLoader] = useState(false);

  const handleNext = () => {
    setLoader(true);
    if (lang) {
      updatePrefrredLanguage(lang.code)
        .then((res) => {
          setLoader(false);
          history.push("/dashboard");
        })
        .catch((err) => {
          console.log("Error in saving language preferance :", err);
          setLoader(false);
          setErrors((errors) => ({
            ...errors,
            openSnackbar: true,
            errorSeverity: "error",
            msg: "Could not save the language preferance.",
          }));
        });
    }
  };
  const setSnackbar = (value) => {
    setErrors((errors) => ({ ...errors, openSnackbar: value }));
  };

  return (
    <div className="finish-container">
      <div className="lang">
        <LanguagePicker setLang={updateFDLanguage} lang={lang} />
      </div>
      <div className="finish-screen">
        <div className="content-wrapper">
          <div className="content-wrapper space">
            <h1 className="head-text">{t("finish_screen.title")}</h1>
            <p className="content">{t("finish_screen.description")}</p>
          </div>
          <div className="image-wrapper1">
            <div className="image-wrapper">
              <div className="desc connect-fellows ">
                <img
                  src={connectFellows}
                  alt="connect fellows"
                  className="image"
                />
                <div
                  className="img-desc"
                  style={{ fontWeight: "bold", fontSize: "larger" }}
                >
                  {t("finish_screen.short_info.connection")}
                </div>
                <p style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                  {t("finish_screen.connect_with_fellow")}
                </p>
                <img src={groupImage} alt="connect fellows" className="image" />
              </div>
              <div className="desc contact-list">
                <img
                  src={contactList}
                  alt="connect fellows"
                  className="image"
                />
                <div
                  className="img-desc"
                  style={{ fontWeight: "bold", fontSize: "larger" }}
                >
                  {t("finish_screen.short_info.contacts")}
                </div>
                <p style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                  {t("finish_screen.create_your_own_contactlist")}
                </p>
                <img
                  src={ownContactList}
                  alt="connect fellows"
                  className="image"
                />
              </div>
              <div className="desc update-profile">
                <img
                  src={updateProfile}
                  alt="connect fellows"
                  className="image"
                />
                <div
                  className="img-desc"
                  style={{ fontWeight: "bold", fontSize: "larger" }}
                >
                  {t("finish_screen.short_info.profile")}
                </div>
                <p style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                  {t("finish_screen.update_your_profile")}
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className={`wrapper finishscreen-footer`}>
          <CustomButton
            classes="btn"
            onClick={handleNext}
            text={t("finish_screen.save_button")}
            variant="contained"
            isLoading={loader}
          />
        </div>
      </div>
      <ErrorAlert
        openSnackbar={errors.openSnackbar}
        msg={errors.msg}
        errorSeverity={errors.errorSeverity}
        setSnackbar={setSnackbar}
      />
    </div>
  );
};

export default FinishScreen;
