import React, { useState, useCallback, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CustomButton from "../button";
import "./editProfileImage.scss";

import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import { useGlobalState, useGlobalDispatch } from "../../context/globalContext";
import {
  updateExistingImage,
  addNewImage,
} from "../../../services/fellow.patchService";

const EditAvatarDialog = (props) => {
  const { open, handleClose, setImages, setErrorValues, t } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const cropSize = { width: 174, height: 174 };
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const { currentProfileImg, userImage, fellowData } = useGlobalState();
  const [dialogImage, setDialogImage] = useState({
    isPresent: false,
    img: userImage || currentProfileImg,
  });
  let fileInput = React.useRef();
  const globalDispatch = useGlobalDispatch();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    if (dialogImage.isPresent) {
      try {
        const croppedImage = await getCroppedImg(
          dialogImage.img,
          croppedAreaPixels,
          rotation
        );
        setImages(croppedImage);
        saveCroppedImage(croppedImage);
        handleClose();
      } catch (e) {
        console.error(e);
      }
    } else {
      setImages();
      handleClose();
      saveCroppedImage({ imageBinary: "", imageUrl: currentProfileImg });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedAreaPixels, rotation]);

  const saveCroppedImage = async ({ imageBinary, imageUrl }) => {
    const profileMediaId =
      fellowData?.data?.relationships?.field_media_profile_image?.data?.id;
    const defaultFellowMediaId = "cba84134-7aec-476d-b21c-e34432cbf2ba";

    if (profileMediaId && profileMediaId !== defaultFellowMediaId) {
      await updateExistingImage(
        fellowData?.data?.relationships?.field_media_profile_image?.data?.id,
        imageBinary,
        fellowData?.data?.attributes?.title
      )
        .then((res) => {
          globalDispatch({
            type: "setCurrentProfileImg",
            payload: res?.data?.links?.small_round_320x320?.href,
          });
          setErrorValues({
            openSnackbar: true,
            errorSeverity: "success",
            snackbarMsg: t("toastMessages:image_update.success"),
          });
          handleClose();
        })
        .catch((err) => {
          console.log("error :", err);
          setErrorValues({
            openSnackbar: true,
            errorSeverity: "error",
            snackbarMsg: t("toastMessages:image_update.error"),
          });
        });
    } else {
      await addNewImage(imageBinary, fellowData?.data?.attributes?.title)
        .then((res) => {
          globalDispatch({ type: "setCurrentProfileImg", payload: imageUrl });
          setErrorValues({
            openSnackbar: true,
            errorSeverity: "success",
            snackbarMsg: t("toastMessages:image_update.success"),
          });
          handleClose();
        })
        .catch((err) => {
          console.log("error :", err);
          setErrorValues({
            openSnackbar: true,
            errorSeverity: "error",
            snackbarMsg: t("toastMessages:image_update.error"),
          });
        });
    }
  };

  useEffect(() => {
    setDialogImage({ isPresent: false, img: userImage });
  }, [userImage]);

  const pickImage = (event) => {
    fileInput.current.click(event);
  };

  const getImage = async (event) => {
    if (event.target.files && event.target.files[0]) {
      let image = event.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        setDialogImage({ isPresent: true, img: e.target.result });
      };
      reader.readAsDataURL(image);
    }
  };

  const onDelete = () => {
    setDialogImage({ isPresent: false, img: currentProfileImg });
  };

  const onCancel = () => {
    setDialogImage({ isPresent: false, img: userImage || currentProfileImg });
    handleClose();
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="edit-avatar-dialog"
    >
      <DialogTitle id="alert-dialog-title" className="head-text">
        {t("profile.image_edit_modal.title")}
      </DialogTitle>
      <DialogContent>
        <div className="sub-title">{t("profile.image_edit_modal.drag")}</div>
        <div className="cropContainer">
          <Cropper
            image={dialogImage?.img}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={1}
            showGrid={false}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="round"
            cropSize={cropSize}
          />
        </div>
        <div className="img-btns">
          <CustomButton
            onClick={onDelete}
            text={t("profile.image_edit_modal.delete_image")}
            classes="btn delete"
          />
          <CustomButton
            onClick={pickImage}
            text={t("profile.image_edit_modal.change_image")}
            classes="btn change-photo"
          />
          <input
            id="fileInput"
            type="file"
            accept="image/png,image/jpg,image/jpeg"
            ref={fileInput}
            style={{ display: "none" }}
            onChange={getImage}
          />
        </div>
      </DialogContent>
      <DialogActions className="buttons">
        <CustomButton
          onClick={showCroppedImage}
          text={t("common:save_button")}
          classes="save"
        />
        <CustomButton
          onClick={onCancel}
          text={t("common:cancel_button")}
          classes="cancel"
        />
      </DialogActions>
    </Dialog>
  );
};

export default EditAvatarDialog;
