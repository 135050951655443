import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "../../../images/edit-icon.svg";
import "./editProfileImage.scss";
import EditAvatarDialog from "../../basic-components/editprofileImage/editAvatarDialog";

const ProfileImageEdit = ({ profileImage, setImages, setErrorValues, t }) => {
  const [openEditAvatar, setEditAvatar] = useState(false);

  const handleDialog = () => {
    setEditAvatar(!openEditAvatar);
  };

  return (
    <div className="profile-img">
      <Avatar
        alt="user icon"
        src={profileImage}
        className="user-icon"
        id="userImage"
      />
      <div className="edit-icon-wrapper">
        <img
          alt="edit icon"
          src={EditIcon}
          className="edit-icon"
          onClick={() => setEditAvatar(true)}
        />
      </div>
      <EditAvatarDialog
        open={openEditAvatar}
        handleClose={handleDialog}
        setImages={setImages}
        setErrorValues={setErrorValues}
        t={t}
      />
    </div>
  );
};

export default ProfileImageEdit;
