import { useState, useEffect } from "react";
import {
  getMostActiveFellow,
  getRecentlyActiveFellow,
  getActivityFeed,
  getFellowsSeekingForCollaboration,
  getNewlyActiveFellow,
  getAllRegions,
  getAllCountriesWithSubdivisions,
} from "../../services/fellow.getService";
import { useGlobalDispatch } from "../context/globalContext";

export const useListings = (setLoader) => {
  const [mostActiveFellows, setMostActiveFellows] = useState();
  const [recentlyActiveFellows, setRecentlyActiveFellows] = useState();
  const [activityFeedData, setActivityFeedData] = useState([]);
  const [fellowsSeekingForCollaboration, setFellowsSeekingForCollaboration] =
    useState();
  const [newlyActiveFellows, setNewlyActiveFellows] = useState();
  const [image1, setImage] = useState([]); // eslint-disable-line
  let id = localStorage.getItem("profile_uuid");
  const globalDispatch = useGlobalDispatch();

  useEffect(() => {
    setLoader("areMostActiveFellowsLoading", true);
    getMostActiveFellow().then((res) => {
      if (res) {
        setMostActiveFellows(res);
        setLoader("areMostActiveFellowsLoading", false);
      }
    });

    setLoader("areActivityFeedDataLoading", true);
    getActivityFeed().then((res) => {
      if (res) {
        setActivityFeedData(res);
        setLoader("areActivityFeedDataLoading", false);
      }
    });

    setLoader("areRecentActiveFellowsLoading", true);
    getRecentlyActiveFellow().then((res) => {
      if (res) {
        setRecentlyActiveFellows(res);
        setLoader("areRecentActiveFellowsLoading", false);
      }
    });

    setLoader("areSeekingCollaborationFellowsLoading", true);
    getFellowsSeekingForCollaboration().then((res) => {
      if (res) {
        setFellowsSeekingForCollaboration(res);
        setLoader("areSeekingCollaborationFellowsLoading", false);
      }
    });

    setLoader("areNewlyActiveFellowsLoading", true);
    getNewlyActiveFellow().then((res) => {
      if (res) {
        setNewlyActiveFellows(res);
        setLoader("areNewlyActiveFellowsLoading", false);
      }
    });

    getAllRegions().then((res) => {
      if (res) {
        globalDispatch({ type: "setAllRegions", payload: res?.data || [] });
      }
    });

    setLoader("areCountriesInJsonLoading", true);
    getAllCountriesWithSubdivisions().then((res) => {
      if (res) {
        globalDispatch({
          type: "setAllCountries",
          payload: res?.allCountries || [],
        });
        globalDispatch({
          type: "setListData",
          payload: { name: "countriesInJson", value: res?.data || {} },
        });
        setLoader("areCountriesInJsonLoading", false);
      }
    });

    setLoader("isDefaultCountryCodeLoading", true);
    let url = "https://ipapi.co/json/";
    fetch(url)
      .then((res) => res.json())
      .then((response) => {
        globalDispatch({
          type: "setListData",
          payload: {
            name: "defaultCountryCode",
            value: response.country_calling_code,
          },
        });
        setLoader("isDefaultCountryCodeLoading", false);
      })
      .catch((data, status) => {
        return;
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, setActivityFeedData]);
  return {
    mostActiveFellows,
    recentlyActiveFellows,
    activityFeedData,
    fellowsSeekingForCollaboration,
    newlyActiveFellows,
    setActivityFeedData,
    image1,
  };
};
