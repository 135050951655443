import constants from "./constants";

export const generateOTP = (data) => {
  const env = constants.BASE_URL_NO_LANG === 'https://www.ashoka.org/' ? 'production' : 'develop';
  const requestData = {
    ...data,
    env: env
  };
  const url = `${constants.BASE_URL}${constants.USER_OTP}${constants.GENERATE_OTP}`;
  const opts = {
    method: "POST",
    body: JSON.stringify(requestData),
  };
  return fetch(url, opts)
    .then(async (res) => {
      const text = await res.text();
      return { status: res.status, message: JSON.parse(text).message };
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const verifyOTP = (data) => {
  const url = `${constants.BASE_URL}${constants.USER_OTP}${constants.VERIFY_OTP}`;
  const opts = {
    method: "POST",
    body: JSON.stringify(data),
  };
  return fetch(url, opts)
    .then(async (res) => {
      const text = await res.text();
      const data = JSON.parse(text);
      return { status: res.status, ...data };
    })
    .catch((err) => {
      return err;
    });
};
