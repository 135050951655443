import "../styles/mixins.scss";

import { instantMessangers, medias } from "../services/constantValues";

import { ReactComponent as FBIcon } from "../images/fb-icon.svg";
import { ReactComponent as Flickr } from "../images/flickr-icon.svg";
import { ReactComponent as Hangout } from "../images/hangout-icon.svg";
import { ReactComponent as Instagram } from "../images/instagram-icon.svg";
import { ReactComponent as LinkedinIcon } from "../images/linkedIn-icon.svg";
import { ReactComponent as MailIcon } from "../images/mail-icon.svg";
import { ReactComponent as Other } from "../images/other-media-icon.svg";
import { ReactComponent as PhoneIcon } from "../images/phone-icon.svg";
import { ReactComponent as Pinterest } from "../images/pinterest-icon.svg";
import React from "react";
import { ReactComponent as Skype } from "../images/skype-icon.svg";
import { ReactComponent as Telegram } from "../images/telegram-icon.svg";
import { ReactComponent as Tumblr } from "../images/tumblr-icon.svg";
import { ReactComponent as TwitterIcon } from "../images/twitter-icon.svg";
import { ReactComponent as Vimeo } from "../images/vimeo-icon.svg";
import { ReactComponent as WhatsApp } from "../images/whatsapp-icon.svg";
import { ReactComponent as Youtube } from "../images/youtube-icon.svg";
import ISO6391 from "iso-639-1";

export const getLocalStorage = (key) => {
  let data = localStorage.getItem(key);
  if (data) return data;
  return;
};

export const getFellowLocation = (location, countriesInJson) => {
  let data = "";
  if (location.locality) data += location.locality;
  if (location.administrative_area) {
    data +=
      data !== ""
        ? `, ${location.administrative_area}`
        : location.administrative_area;
  }
  if (location.country_code) {
    let country = countriesInJson[location.country_code]?.name;
    if (country) data += data !== "" ? `, ${country}` : country;
  }
  return data;
};

export const getFellowImage = (array, id) => {
  if (array && array?.length > 0 && id) {
    const data = array?.filter((fellow) => fellow?.id === id);
    if (data && data.length > 0)
      return data[0]?.relationships?.thumbnail?.data?.meta?.imageDerivatives
        ?.links?.small_round_320x320?.href;
  }
  return;
};

export const getFellowTagName = (array, id) => {
  if (array && id) {
    const data = array?.filter((fellow) => fellow?.id === id);
    if (data && data.length > 0) return data[0].attributes.name;
  }
  return;
};

export const getRelationshipMappings = (relationships, included) => {
  if (relationships && included) {
    let array = [];
    relationships.forEach((fellow) => {
      let match = included.find((elem) => elem.id === fellow.id);
      if (match) array.push(match);
    });
    return array;
  }
  return [];
};

export const getNodeType = (type) => {
  switch (type) {
    case "fellow":
      return "node--fellow";
    case "image":
      return "media--image";
    case "region":
      return "taxonomy_term--regions";
    case "tags":
      return "taxonomy_term--tags";
    case "topics":
      return "taxonomy_term--topics";
    default:
      return;
  }
};

export const validURL = (myURL) => {
  var pattern =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
  return pattern.test(myURL);
};

export const getExistingProfileData = (fellowData, countriesInJson) => {
  if (fellowData && Object.keys(countriesInJson).length) {
    let data;
    const {
      field_fd_fellow_location,
      field_fd_bio,
      field_fellow_introduction,
      field_fd_profile_status,
      field_link_organizations,
      field_collaboration_status,
      field_fd_spoken_languages,
    } = fellowData?.data?.attributes || "";
    const { field_region, field_tags, field_topics } =
      fellowData?.data?.relationships || "";
    const getTopicsAndTags = (array, included) => {
      if (array && included) {
        let selectedValues = [];
        array.forEach((element) => {
          let found = included.filter((value) => value.id === element.id);
          found.length > 0 && selectedValues.push(found[0]);
        });
        return selectedValues;
      }
      return;
    };

    const getFellowTagName = (array, id) => {
      if (array && id) {
        const data = array.filter((fellow) => fellow.id === id);
        if (data && data.length > 0) return data[0];
      }
      return;
    };

    const getAllSpokenLang = (data) => {
      return ISO6391.getLanguages(data);
    };

    data = {
      userProfile: {
        region:
          getFellowTagName(fellowData?.included, field_region?.data?.id) ||
          null,
        country: field_fd_fellow_location?.country_code
          ? {
              code: field_fd_fellow_location.country_code,
              title:
                countriesInJson[field_fd_fellow_location.country_code]?.name,
            }
          : null,
        state: field_fd_fellow_location?.administrative_area
          ? { code: field_fd_fellow_location?.administrative_area }
          : null,
        city: field_fd_fellow_location?.locality || "",
        bio: field_fd_bio || field_fellow_introduction,
        status: field_fd_profile_status || "",
        selectedWorkFields:
          getTopicsAndTags(field_topics?.data, fellowData?.included) || [],
        selectedInterests:
          getTopicsAndTags(field_tags?.data, fellowData?.included) || [],
        collaborationStatus: field_collaboration_status,
        selectedSpokenLanguage: getAllSpokenLang(field_fd_spoken_languages),
      },
      organizations:
        field_link_organizations?.length > 0
          ? field_link_organizations
          : [{ title: "", uri: "" }],
    };
    if (data) return data;
  }
  return;
};

const getContactMediaPreferances = (
  smaccounts,
  imaccounts,
  mediasToContact
) => {
  //get all non dublicate user accounts
  const data = [...smaccounts, ...imaccounts];

  //add new filled/valid social medias to mediaTocontactFrom
  let all = [...mediasToContact];
  const allSocialMedias = data.reduce((accumulator, currentValue) => {
    if (currentValue.mediatype) {
      // removing duplicates and null values from smaccounts and imaccounts
      if (
        !accumulator.find((item) => item.title === currentValue.mediatype.title)
      ) {
        if (
          currentValue.mediatype.title &&
          currentValue.handler &&
          currentValue.mediatype.title !== "Other"
        ) {
          accumulator.push({
            title: currentValue.mediatype.title,
            isAllowed: false,
          });
        }
      }
    }
    return accumulator;
  }, []);
  if (allSocialMedias.length > 0) {
    allSocialMedias.forEach((item) => {
      if (!mediasToContact.find((acc) => acc.title === item.title)) {
        all.push(item);
      }
    });
  }
  all.push({ title: "Email", isAllowed: true });
  return all;
};

export const getExistingContactInfo = (
  fellowData,
  defaultCountryCode,
  questionsData
) => {
  const { field_fd_contact_preferences } = fellowData?.data?.attributes || [];

  const getSocialMedias = (existingMedias, allMedias) => {
    var mediasArray = [];
    Object.keys(existingMedias).forEach((key) => {
      if (existingMedias[key].value) {
        let data = allMedias.filter(
          (media) =>
            media.title.toLowerCase() === key ||
            media.title.toLowerCase() === "other"
        );
        data[0] &&
          mediasArray.push({
            mediatype: data[0],
            handler:
              key === "tumblr"
                ? existingMedias[key].value
                : data[0].url + existingMedias[key].value,
          });
      }
    });
    if (mediasArray.length > 0) return mediasArray;
    return [{ mediatype: null, handler: "" }];
  };

  const socialMediasAccounts = getSocialMedias(
    fellowData?.data?.attributes?.field_other_social_media_links
      ?.platform_values || [],
    medias
  );
  const instantMessangerAccounts = getSocialMedias(
    fellowData?.data?.attributes?.field_fd_fellow_ims?.platform_values || [],
    instantMessangers
  );

  //check if phone number exists excluding the ISO code
  const getMobileInfo = (phone) => {
    let phoneNo = phone?.split(",");
    let contact = "",
      code = defaultCountryCode;
    if (phoneNo?.length > 1) {
      for (let i = 0; i < phoneNo.length; i++) {
        if (i === 0) continue;
        contact += phoneNo[i];
      }
      code = phoneNo[0];
    } else {
      contact = phone;
    }
    return { ccode: code, mobile: contact };
  };

  //get all contact preferences
  const getContactMedias = (contactOptions) => {
    let data;
    try {
      data = contactOptions && JSON.parse(contactOptions);
      if (data?.length > 0) return data;
      else {
        const all = getContactMediaPreferances(
          socialMediasAccounts,
          instantMessangerAccounts,
          []
        );
        return all;
      }
    } catch (e) {
      console.log("error in getting contact methods :", e);
      return [{ title: "Email", isAllowed: true }];
    }
  };

  //get all questions formate
  const getHelpQuestions = () => {
    const { attributes } = fellowData.data;
    const keys = Object.keys(fellowData.data.attributes);
    const data = questionsData?.map((element) => {
      if (keys.includes(element.key)) {
        let subquestions = [];
        if (element.subquestions) {
          //subquestions
          subquestions = element.subquestions.map((sub) => {
            let options = [];
            if (keys.includes(sub.key)) {
              if (typeof attributes[sub.key] === "boolean")
                return { ...sub, isSelected: attributes[sub.key] };
              else {
                //options
                options = sub.options.map((op) => {
                  if (attributes[sub.key].includes(op.key))
                    return { ...op, isSelected: true };
                  return op;
                });
              }
              return { ...sub, options };
            }
            return sub;
          });
        }
        return {
          ...element,
          subquestions,
          isSelected: attributes[element.key],
        };
      }
      return element;
    });
    return data || [];
  };

  let data = {
    contactinfo: getMobileInfo(fellowData?.data?.attributes?.field_phone || ""),
    mediasToContact: getContactMedias(field_fd_contact_preferences),
    smaccounts: [...socialMediasAccounts],
    imaccounts: [...instantMessangerAccounts],
    questions: getHelpQuestions(),
  };

  if (data) return data;
};

export const renderIcon = (index, classes) => {
  switch (index) {
    case "facebook messanger":
    case "facebook":
      return <FBIcon className={classes} />;
    case "twitter":
      return <TwitterIcon className={classes} />;
    case "linkedin":
      return <LinkedinIcon className={classes} />;
    case "instagram":
      return <Instagram className={classes} />;
    case "pinterest":
      return <Pinterest className={classes} />;
    case "tumblr":
      return <Tumblr className={classes} />;
    case "hangout":
      return <Hangout className={classes} />;
    case "telegram":
      return <Telegram className={classes} />;
    case "skype":
      return <Skype className={classes} />;
    case "vimeo":
      return <Vimeo className={classes} />;
    case "flickr":
      return <Flickr className={classes} />;
    case "youtube":
      return <Youtube className={classes} />;
    case "whatsapp":
      return <WhatsApp className={classes} />;
    case "phone number":
      return <PhoneIcon className={classes} />;
    case "email":
      return <MailIcon className={classes} />;
    default:
      return <Other className={classes} />;
  }
};

export const getSocialMedias = (socialmedias, mediasToSearch, isIm) => {
  var mediasArray = [];
  Object.keys(socialmedias).forEach((key) => {
    mediasToSearch.forEach((m) => {
      if (
        m.title.toLowerCase() === key.toLowerCase() &&
        socialmedias[key].value
      ) {
        if (key === "tumblr")
          mediasArray.push({
            title: key,
            url: "",
            value: socialmedias[key].value,
          });
        else
          mediasArray.push({
            title: key,
            url: isIm ? getImAccsUrl(key) : m.url,
            value: socialmedias[key].value,
          });
      }
    });
  });
  return mediasArray;
};

export const getAvailableMediaContacts = (
  socialMedias,
  contact_prefs,
  phone,
  email,
  allMediasArray
) => {
  let allAllowedMedias = [],
    isEmailPrefered = false,
    isPhonePrefered = false;
  socialMedias = { ...socialMedias };
  try {
    let contactPreferences = JSON.parse(contact_prefs);

    if (contactPreferences.length !== undefined) {
      //converting all total medias into a json format
      let allMediasJson = {};
      allMediasArray.forEach((item) => {
        allMediasJson[item.title.toLowerCase()] = item.url;
      });

      //get all allowed contacts title,url and value
      for (let i = 0; i < contactPreferences.length; i++) {
        let item = contactPreferences[i];
        let key = item.title.toLowerCase();

        //if allowed for email, set true and continue
        if (key === "email" && item.isAllowed) {
          isEmailPrefered = true;
          continue;
        }
        //if allowed for phone number set true and continue
        if (key === "phone number" && item.isAllowed) {
          isPhonePrefered = true;
          continue;
        }
        //if allowed for sm and im media accounts add it to the array
        if (
          key &&
          socialMedias[key] &&
          socialMedias[key].value &&
          item.isAllowed
        ) {
          let linkUrl = "";

          //check urls available for social media/messanger accounts (urls only include for sm accounts: refer file 'constantValues.js')
          if (allMediasJson[key]) {
            //if tumblr show BE value (already includes a url in it) as link
            //if hangout show url only and dont attach BE handler value (only url)
            //else attach url+value (e.g "https://facebook.com/abcd")
            if (key === "tumblr") linkUrl = socialMedias[key].value;
            else linkUrl = allMediasJson[key] + socialMedias[key].value;
          } else {
            //if urls do not exist(in 'constantValues.js') use 'getImAccsUrl'
            if (key === "hangout") linkUrl = getImAccsUrl(key);
            else linkUrl = getImAccsUrl(key) + socialMedias[key].value;
          }
          allAllowedMedias.push({
            title: key,
            url: linkUrl,
            value: socialMedias[key].value,
          });
        }
      }
      return { allAllowedMedias, isEmailPrefered, isPhonePrefered };
    }
  } catch (err) {
    //if no contact preferences available show eamil by default
    return { isEmailPrefered: true };
  }
};

const getImAccsUrl = (title) => {
  switch (title) {
    case "whatsapp":
      return "https://api.whatsapp.com/send?phone=";
    case "hangout":
      return "http://hangouts.google.com/start";
    case "telegram":
      return "https://telegram.me/";
    case "skype":
      return "skype:";
    case "facebook messanger":
      return "http://m.me/";
    default:
      return "";
  }
};

export const getFormatedQuestions = (questions) => {
  let data = {};
  questions.forEach((q) => {
    data = { ...data, [q.key]: q.isSelected };
    if (q?.subquestions) {
      q.subquestions.forEach((sub) => {
        let options = [];
        options = sub?.options?.filter((op) => op.isSelected && op.key);
        options = options.map((op) => op.key);
        data = { ...data, [sub.key]: options };
      });
    }
  });
  return data;
};

export const addMetaTag = (name,content) => {
  var meta = document.createElement('meta');
  meta.httpEquiv = name;
  meta.content = content;
  document.getElementsByTagName('head')[0].appendChild(meta);
}

export const removeMetaTag = (name) => {
  var metaTags = document.querySelectorAll('meta');
  metaTags.forEach(function(metaTag) {
    if (metaTag.getAttribute('http-equiv') === name) {
      metaTag.parentNode.removeChild(metaTag);
    }
  });
}
