import React, { useState } from "react";
import CustomButton from "../basic-components/button";
import User1 from "../../images/user1.svg";
import User2 from "../../images/user2.svg";
import { useHistory } from "react-router-dom";
import ErrorAlert from "../basic-components/snackbar";
import { useGlobalState, useGlobalDispatch } from "../context/globalContext";
import { generateOTP } from "../../services/authService";
import "./style.scss";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import LanguagePicker from "../basic-components/languagePicker";

const Login = (props) => {
  const [errors, setErrors] = useState({
    errorSeverity: "",
    openSnackbar: false,
    msg: "",
    isError: false,
  });
  const history = useHistory();
  const { email } = useGlobalState();
  const globalDispatch = useGlobalDispatch();
  const { UpdateEmailProvided, lang, updateFDLanguage } = props;
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation(["user-auth"]);

  const getVerificationCode = () => {
    setLoader(true);

    if (!validateEmail(email)) {
      setErrors((errors) => ({
        ...errors,
        isError: true,
        msg: t("form.email_input.invalid_email_error"),
      }));
      setLoader(false);
      return;
    }
    if (!email) {
      setLoader(false);
      return;
    }
    generateOTP({ email }).then((result) => {
      if (result.status === 200) {
        UpdateEmailProvided(true);
        history.push("/emailverification");
        setLoader(false);
        return;
      }
      setErrors((errors) => ({
        ...errors,
        openSnackbar: true,
        errorSeverity: "error",
        msg: result.message,
      }));
      setLoader(false);
      return;
    });
  };

  const setSnackbar = (value) => {
    setErrors((errors) => ({ ...errors, openSnackbar: value }));
  };

  const onEmailChange = (evt) => {
    globalDispatch({ type: "setEmail", payload: evt });
    if (errors?.isError || errors?.msg)
      setErrors((errors) => ({ ...errors, isError: false, msg: "" }));
  };

  const validateEmail = (value) => {
    const expression = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gim;
    if (value && !expression.test(value)) {
      return false;
    }
    return true;
  };

  const onKeypress = (e) => {
    var keycode = e.keyCode || e.which || 0;
    if (e.key === "Enter" || keycode === 13) getVerificationCode();
  };

  return (
    <div className="login">
      <div className="wrapper">
        <div className="container">
          <div className="lang-block">
            <LanguagePicker setLang={updateFDLanguage} lang={lang} />
          </div>
          <div className="images">
            <img src={User1} alt="user 1" className="img" />
            <img src={User2} alt="user 2" className="img" />
          </div>
          <h1 className="head-text">{t("content.title")}</h1>
          <div className="inputs">
            <TextField
              id="email"
              label={
                errors?.isError
                  ? t("form.email_input.error_lable")
                  : t("form.email_input.lable")
              }
              variant="filled"
              onChange={(e) => onEmailChange(e.target.value.trim())}
              className={`textfield ${errors?.isError ? "error" : ""}`}
              onKeyDown={(e) => onKeypress(e)}
              error={errors?.isError}
              helperText={
                errors?.isError
                  ? errors?.msg
                  : errors?.isError && t("form.email_input.error_lable")
              }
            />
            <CustomButton
              classes="code-btn"
              onClick={getVerificationCode}
              text={t("form.submit_button.lable")}
              variant="contained"
              isLoading={loader}
            />
            <div className="login-support">
              {t("form.trouble.lable")}{" "}
              <a href={`mailto:fdsupport@ashoka.org`} className="conatct_us">
                {t("form.contact.lable")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <ErrorAlert
        openSnackbar={errors.openSnackbar}
        msg={errors.msg}
        errorSeverity={errors.errorSeverity}
        setSnackbar={setSnackbar}
      />
    </div>
  );
};

export default Login;
