import React, { useState, useEffect } from "react";
import Textfield from "../../basic-components/textfield";
import "./profile.scss";
import { useGlobalState, useGlobalDispatch } from "../../context/globalContext";
import {
  useProfileState,
  useProfileDispatch,
} from "../../context/profileContext";
import {
  getLocalStorage,
  getNodeType,
  validURL,
  getExistingProfileData,
} from "../../../services/helper-functions";
import AddNewOrganization from "../../basic-components/addoraganizations/addNewOrganization";
import ErrorAlert from "../../basic-components/snackbar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import ProfileImageEdit from "../../basic-components/editprofileImage";
import UserLocationForm from "../../forms/locationform";
import CustomButton from "../../basic-components/button";
import {
  getAllRegions,
  getAllCountriesWithSubdivisions,
  getAllWorkFields,
  getAllInterests,
  getFellowProfile,
  getAllSubdivisions,
} from "../../../services/fellow.getService";
import {
  updateFellowData,
  createNewtag,
} from "../../../services/fellow.patchService";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ReactComponent as LeftArrow } from "../../../images/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../../images/right-arrow.svg";
import LanguagePicker from "../../basic-components/languagePicker";
import { updatePrefrredLanguage } from "../../../services/fellow.patchService";
import ISO6391 from "iso-639-1";

const Profile = (props) => {
  const { userProfile, organizations } = useProfileState();
  const profileDistpach = useProfileDispatch();
  const {
    userImage,
    regions,
    countries,
    states,
    fieldsOfWork,
    interests,
    fellowData,
    currentProfileImg,
    countriesInJson,
  } = useGlobalState();
  const globalDispatch = useGlobalDispatch();
  const { t } = useTranslation(["forms", "common", "toastMessages"]);
  const [imageFile, setImageFile] = useState({ imageBinary: "", image: "" });
  const history = useHistory();
  const [errors, setErrors] = useState({
    errorSeverity: "",
    openSnackbar: false,
    snackbarMsg: "",
    bio: {},
    orgs: [],
  });
  const [loader, setLoader] = useState(false);
  const { lang, updateFDLanguage } = props;

  const setSnackbar = (value, severity, msg) => {
    setErrors((errors) => ({
      ...errors,
      openSnackbar: value || false,
      errorSeverity: severity || "",
      snackbarMsg: msg || "",
    }));
  };

  const setUserDetails = (event) => {
    let { value, name } = event.target;
    let data = { ...errors };
    setErrors(data);
    profileDistpach({ type: "setUserProfile", payload: { value, name } });
  };

  const setErrorValues = (data) => {
    setErrors((state) => ({ ...state, ...data }));
  };

  const setLocationDetails = (value, name) => {
    profileDistpach({ type: "setUserProfile", payload: { value, name } });

    if (name === "country" && value) {
      if (value.subdivisions.length > 0)
        globalDispatch({ type: "setAllStates", payload: value.subdivisions });
      else globalDispatch({ type: "setAllStates", payload: [] });
    }
    if (name === "country" && (!value || !value?.subdivisions?.length)) {
      profileDistpach({
        type: "setUserProfile",
        payload: { name: "city", value: "" },
      });
      profileDistpach({
        type: "setUserProfile",
        payload: { name: "state", value: null },
      });
    }
  };

  const addOrganizations = () => {
    let result = [...organizations, { title: "", uri: "" }];
    profileDistpach({ type: "updateOrgDetails", payload: result });
  };

  const deleteOrganization = (index) => {
    let result = [...organizations];
    let data = [...errors.orgs];

    if (result.length === 1) result[index] = { title: "", uri: "" };
    else result.splice(index, 1);
    data.splice(index, 1);
    setErrors((state) => ({ ...state, orgs: data }));
    profileDistpach({ type: "updateOrgDetails", payload: result });
  };

  const updateOrgDetails = (event, index) => {
    const { name, value } = event.target;
    let result = [...organizations];
    result[index][name] = value;

    profileDistpach({ type: "updateOrgDetails", payload: result });
    if (name === "uri") {
      let data = { ...errors };
      data.orgs[index] = {};
      if (value && !validURL(value)) {
        data.orgs[index].isErorr = true;
        data.orgs[index].msg = "Enter valid url";
      } else {
        data.orgs[index].isErorr = false;
        data.orgs[index].msg = "";
      }
      setErrors(data);
    }
  };

  const setImages = (croppedImage) => {
    setImageFile((state) => ({
      imageBinary: croppedImage?.imageBinary || "",
      image: croppedImage?.imageUrl || currentProfileImg,
    }));
  };

  const handleNext = () => {
    updateData();
  };

  const handleSkip = () => {
    setLoader(true);
    if (lang) {
      updatePrefrredLanguage(lang.code)
        .then((res) => {
          setLoader(false);
          history.push("/onboarding/sticky");
        })
        .catch((err) => {
          console.log("Error in saving language preferance :", err);
          setLoader(false);
          setErrors((errors) => ({
            ...errors,
            openSnackbar: true,
            errorSeverity: "error",
            msg: "Could not save the language preferance.",
          }));
        });
    }
  };

  const handleBack = () => {
    history.push("/onboarding/contactinfo");
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    profileDistpach({
      type: "setUserProfile",
      payload: { value: checked, name },
    });
  };

  const updateData = async () => {
    const id = await localStorage.getItem("profile_uuid");
    let fellowInfo = { data: { attributes: {} } };
    if (userProfile.bio && errors.bio.isErorr) {
      setErrors((state) => ({
        ...state,
        openSnackbar: true,
        errorSeverity: "error",
        snackbarMsg: "Please enter valid details",
      }));
      return;
    }

    if (userProfile.region && fellowInfo) {
      fellowInfo.data["relationships"] = {
        field_region: {
          data: {
            type: getNodeType("region"),
            id: userProfile?.region?.id,
          },
        },
      };
    }

    //should validate for organizations url here
    let orgsErrors = false;
    errors.orgs.forEach((im) => {
      if (im?.isErorr) {
        orgsErrors = true;
        setErrors((state) => ({
          ...state,
          openSnackbar: true,
          errorSeverity: "error",
          snackbarMsg: "Please enter valid details",
        }));
        return;
      }
    });
    if (orgsErrors) {
      return;
    }

    let orgs = [];
    organizations &&
      organizations.forEach((org) => {
        if (org.uri && org.title)
          orgs.push({
            title: org.title,
            uri:
              !org.uri.startsWith("http://") &&
              !org.uri.startsWith("https://") &&
              !org.uri.includes(":")
                ? "https://" + org.uri
                : org.uri,
          });
      });
    const topics =
      userProfile?.selectedWorkFields.map((topic) => {
        return { type: getNodeType("topics"), id: topic.id };
      }) || [];

    let allTags = [];
    const newTags = [];
    userProfile.selectedInterests.forEach((tag) => {
      if (typeof tag === "string") newTags.push(tag);
      else allTags.push({ type: getNodeType("tags"), id: tag.id });
    });

    const spokenLanguages = userProfile?.selectedSpokenLanguage.map(
      (s) => s.code
    );

    setLoader(true);
    await Promise.all(newTags.map((tag) => createNewtag(tag)))
      .then((responses) => responses.map((res) => res))
      .then((results) => {
        allTags = [...allTags, ...results];
        fellowInfo = {
          data: {
            type: getNodeType("fellow"),
            id,
            attributes: {
              field_fd_fellow_location: {
                country_code: userProfile?.country?.code || null,
                administrative_area: userProfile?.state?.code || null,
                locality: userProfile?.city || "",
              },
              field_fd_bio: { value: userProfile?.bio || "" },
              field_link_organizations: orgs || [],
              field_collaboration_status: userProfile.collaborationStatus,
              field_fd_ui_language: lang?.code,
              field_fd_spoken_languages: spokenLanguages || [],
            },
            relationships: {
              ...fellowInfo.data.relationships,
              field_topics: { data: topics || [] },
              field_tags: { data: allTags || [] },
            },
          },
        };
        updateFellowData(fellowInfo)
          .then((res) => {
            if (res) {
              globalDispatch({ type: "setUserData", payload: res });
              setErrors((errors) => ({
                ...errors,
                openSnackbar: true,
                errorSeverity: "success",
                snackbarMsg: "Profile information updated.",
              }));
              setLoader(false);
              handleSkip();
            }
          })
          .catch((err) => {
            console.log(err);
            setSnackbar(
              true,
              "error",
              t("toastMessages:profile_data_save.error")
            );
            setLoader(false);
          });
      })
      .catch((err) => {
        setSnackbar(true, "error", "Could not create the new interest fields");
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!regions.length) {
      getAllRegions().then((res) => {
        if (res) globalDispatch({ type: "setAllRegions", payload: res.data });
      });
    }

    if (!countries.length) {
      getAllCountriesWithSubdivisions().then((res) => {
        if (res) {
          globalDispatch({
            type: "setAllCountries",
            payload: res?.allCountries || [],
          });
          globalDispatch({
            type: "setListData",
            payload: { name: "countriesInJson", value: res?.data || {} },
          });
          if (userProfile.country) {
            globalDispatch({
              type: "setAllStates",
              payload: userProfile.country.subdivisions,
            });
          }
        }
      });
    }

    if (!fieldsOfWork.length) {
      getAllWorkFields().then((res) => {
        if (res)
          globalDispatch({
            type: "setListData",
            payload: { name: "fieldsOfWork", value: res.data || [] },
          });
      });
    }

    if (!interests.length) {
      getAllInterests().then((res) => {
        if (res)
          globalDispatch({
            type: "setListData",
            payload: { name: "interests", value: res.data || [] },
          });
      });
    }
    const getprofile = async () => {
      let id = await getLocalStorage("profile_uuid");
      getFellowProfile(id)
        .then((res) => {
          if (res) {
            globalDispatch({ type: "setUserData", payload: res });
          }
        })
        .catch((err) =>
          setSnackbar(true, "error", "Sorry, could not fetch the fellows data")
        );
    };
    if (!fellowData) getprofile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const existingData = getExistingProfileData(fellowData, countriesInJson);
    if (existingData) {
      profileDistpach({ type: "setDetails", payload: existingData });
      if (existingData?.userProfile?.country?.code) {
        getAllSubdivisions(existingData.userProfile.country.code)
          .then((res) => {
            if (res) {
              globalDispatch({ type: "setAllStates", payload: res || [] });
              if (existingData?.userProfile?.state?.code) {
                let state = res.find(
                  (s) => s.code === existingData.userProfile.state.code
                );
                profileDistpach({
                  type: "setUserProfile",
                  payload: { name: "state", value: state ? state : null },
                });
              }
            }
          })
          .catch((err) => {
            setSnackbar(true, "error", "Could not fetch the subdivisions");
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fellowData, countriesInJson]);

  // const setInterests = (value, array) => {
  //   if (value) {
  //     let a = interests.find(
  //       (interest) => interest?.attributes?.name === value
  //     );
  //     if (a) {
  //       var index = array.indexOf(value);
  //       if (index !== -1) array[index] = a;
  //     }
  //   }
  //   profileDistpach({
  //     type: "setUserProfile",
  //     payload: { value: array, name: "selectedInterests" },
  //   });
  // };

  return (
    <>
      <div className="profile">
        <div className="lang">
          <LanguagePicker setLang={updateFDLanguage} lang={lang} />
        </div>
        <div className="wrapper">
          <h1 className="head-text">{t("profile.title")}</h1>
          <p className="content">{t("profile.description")}</p>
          <ProfileImageEdit
            profileImage={imageFile.image || userImage}
            setImages={setImages}
            setErrorValues={setErrorValues}
            t={t}
          />
          <div className="seeking-collaboration">
            <Switch
              checked={userProfile.collaborationStatus || false}
              onChange={handleChange}
              color="primary"
              name="collaborationStatus"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <div className="title">
              {t("profile.form_fields.seeking_collaboration")}
            </div>
          </div>
          <form autoComplete="no" style={{ display: "block" }}>
            <UserLocationForm
              regions={regions}
              countries={countries}
              states={states}
              setLocationDetails={setLocationDetails}
              region={userProfile.region && userProfile.region}
              country={userProfile.country}
              state={userProfile.state}
              city={userProfile.city}
              t={t}
            />
            {/* fields of work */}
            <Autocomplete
              multiple
              options={fieldsOfWork}
              limitTags={1}
              getOptionLabel={(option) => option?.attributes?.name || ""}
              value={userProfile?.selectedWorkFields || []}
              onChange={(event, value) => {
                profileDistpach({
                  type: "setUserProfile",
                  payload: { value, name: "selectedWorkFields" },
                });
              }}
              getOptionSelected={(option, value) => option.id === value.id}
              filterSelectedOptions
              name="work"
              className="autocomplete-feilds address-fields"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label={t("profile.form_fields.fields_of_work.lable")}
                  placeholder={t("profile.form_fields.fields_of_work.lable")}
                />
              )}
            />

            {/* fields of Speaking language(s) */}
            <Autocomplete
              id="spokenLanguage"
              multiple
              options={ISO6391.getLanguages(ISO6391.getAllCodes())}
              freeSolo
              limitTags={1}
              value={userProfile?.selectedSpokenLanguage || []}
              onChange={(event, value) => {
                profileDistpach({
                  type: "setUserProfile",
                  payload: { value, name: "selectedSpokenLanguage" },
                });
              }}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.code === value.code}
              name="spokenLanguage"
              className="autocomplete-feilds spoken-language"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label={t("common:speaking_lang.title")}
                />
              )}
            />

            {/* fields of interests will be dependant on fetch query */}
            {/* <>
              <Autocomplete
                multiple
                options={interests}
                freeSolo
                limitTags={1}
                getOptionLabel={(option) =>
                  option?.attributes?.name || option || ""
                }
                value={userProfile?.selectedInterests || []}
                onChange={(event, value) => {
                  setInterests(event.target.value, value);
                }}
                name="work"
                className="autocomplete-feilds interests"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={t("profile.form_fields.fields_of_interest.lable")}
                    placeholder={t(
                      "profile.form_fields.fields_of_interest.lable"
                    )}
                  />
                )}
              />
              <div className="helper-text">
                {t("profile.form_fields.fields_of_interest.helper_text")}
              </div>
            </> */}
            <Textfield
              id="bio"
              label={t("profile.form_fields.bio.lable")}
              rows={4}
              defaultValue="Default Value"
              variant="filled"
              name="bio"
              onChange={setUserDetails}
              value={userProfile.bio}
              multiline={true}
              className="bio"
              error={errors.bio.isErorr}
              errorMsg={errors.bio.isErorr && errors.bio.msg}
            />
            {organizations.map((org, index) => {
              return (
                <AddNewOrganization
                  errors={errors.orgs[index]}
                  key={index}
                  data={org}
                  deleteOrganization={deleteOrganization}
                  index={index}
                  onChange={updateOrgDetails}
                  t={t}
                />
              );
            })}
            <div className="add" onClick={addOrganizations}>
              <p className="resend">
                + {t("profile.form_fields.add_organization.lable")}
              </p>
            </div>
          </form>
        </div>
        <ErrorAlert
          openSnackbar={errors.openSnackbar}
          msg={errors.snackbarMsg}
          errorSeverity={errors.errorSeverity}
          setSnackbar={setSnackbar}
        />
      </div>
      <div className="footer">
        <div className={`wrapper`}>
          <CustomButton
            classes="btn"
            onClick={handleNext}
            text={t("forms:profile.form_fields.save_button")}
            variant="contained"
            isLoading={loader}
          />
          <div className="prev-next-btn-wrapper">
            <div className="update-route prev" onClick={handleBack}>
              <LeftArrow />
              <CustomButton text={t("common:button_previous.lable")} />
            </div>
            <div className="update-route next" onClick={handleSkip}>
              <CustomButton
                text={t("common:button_skip.lable")}
                isLoading={loader}
              />
              <RightArrow />
            </div>
          </div>
          {
            <ul>
              {/* changes for pagination */}
              {Array(5)
                .fill(null)
                .map((i, index) => {
                  return (
                    <li
                      className={`${
                        index === 1 || index === 0 || index === 2
                          ? "active"
                          : ""
                      }`}
                      key={index}
                    ></li>
                  );
                })}
            </ul>
          }
        </div>
      </div>
    </>
  );
};

export default Profile;
