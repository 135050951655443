import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import "./style.scss";

const SkeletonRowLoader = () => {
  return (
    <div className="flex">
      <div>
        <Skeleton
          animation="wave"
          variant="circle"
          width={40}
          height={40}
          style={{ marginBottom: 10, marginRight: 16 }}
        />
      </div>
      <div className="card-titles">
        <Skeleton
          animation="wave"
          height={10}
          width="60%"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={10}
          width="40%"
          style={{ marginBottom: 6 }}
        />
      </div>
    </div>
  );
};

export default SkeletonRowLoader;
