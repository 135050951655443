import "./status.scss";

import React, { useEffect, useState } from "react";
import {
  getExistingProfileData,
  getNodeType,
} from "../../../services/helper-functions";
import { useGlobalDispatch, useGlobalState } from "../../context/globalContext";
import {
  useProfileDispatch,
  useProfileState,
} from "../../context/profileContext";

import Avatar from "@material-ui/core/Avatar";
import CustomButton from "../../basic-components/button";
import ErrorAlert from "../../basic-components/snackbar";
import LanguagePicker from "../../basic-components/languagePicker";
import { ReactComponent as LeftArrow } from "../../../images/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../../images/right-arrow.svg";
import Textfield from "../../basic-components/textfield";
import { updateFellowData } from "../../../services/fellow.patchService";
import { updatePrefrredLanguage } from "../../../services/fellow.patchService";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Status = (props) => {
  const { userImage, currentProfileImg, fellowData, countriesInJson } =
    useGlobalState();
  const { userProfile } = useProfileState();
  const profileDispatch = useProfileDispatch();
  const globalDispatch = useGlobalDispatch();
  const [errors, setErrors] = useState({
    status: {},
    errorSeverity: "",
    openSnackbar: false,
    snackbarMsg: "",
  });
  const { t } = useTranslation(["forms", "common"]);
  let history = useHistory();
  const [loader, setLoader] = useState(false);
  const { lang, updateFDLanguage } = props;

  const setSnackbar = (value) => {
    setErrors((errors) => ({ ...errors, openSnackbar: value }));
    handleSkip();
  };

  const addStatus = (event) => {
    const { name, value } = event.target;
    let data = { ...errors };
    if (value.length > 100) {
      data.status.isErorr = true;
      data.status.msg = "Status should have 100 characters only.";
    } else {
      data.status.isErorr = false;
      data.status.msg = "";
    }
    setErrors(data);
    profileDispatch({ type: "setUserProfile", payload: { name, value } });
  };

  const handleNext = () => {
    updateData();
  };

  const handleBack = () => {
    history.push("/onboarding/sticky");
  };

  const handleSkip = () => {
    setLoader(true);
    if (lang) {
      updatePrefrredLanguage(lang.code)
        .then((res) => {
          setLoader(false);
          history.push("/onboarding/finish");
        })
        .catch((err) => {
          console.log("Error in saving language preferance :", err);
          setLoader(false);
          setErrors((errors) => ({
            ...errors,
            openSnackbar: true,
            errorSeverity: "error",
            msg: "Could not save the language preferance.",
          }));
        });
    }
  };

  const updateData = async () => {
    setLoader(true);
    const id = await localStorage.getItem("profile_uuid");
    let fellowInfo;

    if (userProfile.status && errors.status.isErorr) {
      setErrors((state) => ({
        ...state,
        openSnackbar: true,
        errorSeverity: "error",
        snackbarMsg: "Please enter valid details",
      }));
      return;
    }

    fellowInfo = {
      data: {
        type: getNodeType("fellow"),
        id,
        attributes: {
          field_fd_profile_status: userProfile.status,
          field_fd_status: true,
          field_fd_ui_language: lang?.code,
        },
      },
    };
    updateFellowData(fellowInfo)
      .then((res) => {
        if (res) {
          setErrors((errors) => ({
            ...errors,
            openSnackbar: true,
            errorSeverity: "success",
            snackbarMsg: "Status information saved.",
          }));
          setLoader(false);
          globalDispatch({ type: "setUserData", payload: res });
          handleSkip();
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log("error :", err);
        setErrors((errors) => ({
          ...errors,
          openSnackbar: true,
          errorSeverity: "error",
          snackbarMsg: "Could not save your status.",
        }));
        setLoader(false);
      });
  };

  useEffect(() => {
    const existingData = getExistingProfileData(fellowData, countriesInJson);
    if (existingData)
      profileDispatch({ type: "setDetails", payload: existingData });
  }, [fellowData, countriesInJson, profileDispatch]);

  return (
    <div className="status-container">
      <div className="lang">
        <LanguagePicker setLang={updateFDLanguage} lang={lang} />
      </div>
      <div className="status">
        <div className="wrapper">
          <h1 className="head-text">{t("status.title")}</h1>
          <p className="content">{t("status.description")}</p>
          <Avatar
            alt="user icon"
            src={userImage || currentProfileImg}
            className="avt-img"
            id="user Profile"
          />
          <div className="user-status">
            <Textfield
              id="status"
              onChange={addStatus}
              value={userProfile.status}
              label={t("status.form_fields.status.label")}
              name="status"
              error={errors.status.isErorr}
              errorMsg={errors.status.isErorr && errors.status.msg}
            />
            <p className="tip">{t("status.form_fields.status.tip")} </p>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className={`wrapper`}>
          <CustomButton
            classes="btn"
            onClick={handleNext}
            text={t("status.save_button")}
            variant="contained"
            isLoading={loader}
          />
          <div className="prev-next-btn-wrapper">
            <div className="update-route prev" onClick={handleBack}>
              <LeftArrow />
              <CustomButton text={t("common:button_previous.lable")} />
            </div>
            <div className="update-route next" onClick={handleSkip}>
              <CustomButton
                text={t("common:button_skip.lable")}
                isLoading={loader}
              />
              <RightArrow />
            </div>
          </div>
          {
            <ul>
              {/* changes for pagination */}
              {Array(5)
                .fill(null)
                .map((i, index) => {
                  return (
                    <li
                      className={`${
                        index === 1 ||
                        index === 0 ||
                        index === 2 ||
                        index === 3 ||
                        index === 4
                          ? "active"
                          : ""
                      }`}
                      key={index}
                    ></li>
                  );
                })}
            </ul>
          }
        </div>
        <ErrorAlert
          openSnackbar={errors.openSnackbar}
          msg={errors.snackbarMsg}
          errorSeverity={errors.errorSeverity}
          setSnackbar={setSnackbar}
        />
      </div>
    </div>
  );
};

export default Status;
