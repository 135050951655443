import "./settings.scss";

import React, { useEffect, useState } from "react";

import AccountSettings from "./accountSettings";
import NotificationSettings from "./notificationSettings";
import ProfileSettings from "./profileSettings";
import { SettingsIcon } from "../SvgComponents/settings";
import { getFellowNotificationPreferences } from "../../services/fellow.getService";
import { useGlobalDispatch } from "../context/globalContext";
import { useTranslation } from "react-i18next";

const Settings = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation([
    "forms",
    "settings",
    "common",
    "toastMessages",
    "questionnaires",
  ]);
  const {
    areCountriesInJsonLoading,
    isFellowProfileLoading,
    isDefaultCountryCodeLoading,
  } = props?.loaders;
  const emailNotifications = [
    {
      lable: t("settings:notifications.email_notifications.type1"),
      fieldName: "field_fd_email_notif_remider",
      notificationStatus: false,
    },
    {
      lable: t("settings:notifications.email_notifications.type2"),
      fieldName: "field_fd_email_notif_fd_to_org",
      notificationStatus: false,
    },
    {
      lable: t("settings:notifications.email_notifications.type3"),
      fieldName: "field_fd_email_notif_org_to_fd",
      notificationStatus: false,
    },
    {
      lable: t("settings:notifications.email_notifications.type4"),
      fieldName: "field_fd_email_notif_marketing",
      notificationStatus: false,
    },
  ];

  const [notifUpdates, setNotifUpdates] = useState([...emailNotifications]);
  const globalDispatch = useGlobalDispatch();

  const renderContent = (t) => {
    switch (activeTab) {
      case 0:
        return (
          <ProfileSettings
            t={t}
            areCountriesInJsonLoading={areCountriesInJsonLoading}
            isFellowProfileLoading={isFellowProfileLoading}
            isDefaultCountryCodeLoading={isDefaultCountryCodeLoading}
          />
        );
      case 1:
        return <AccountSettings t={t} />;
      case 2:
        return (
          <NotificationSettings
            t={t}
            notifUpdates={notifUpdates}
            setNotifUpdates={setNotifUpdates}
          />
        );
      default:
        return (
          <ProfileSettings
            t={t}
            areCountriesInJsonLoading={areCountriesInJsonLoading}
            isFellowProfileLoading={isFellowProfileLoading}
            isDefaultCountryCodeLoading={isDefaultCountryCodeLoading}
          />
        );
    }
  };

  useEffect(() => {
    getFellowNotificationPreferences()
      .then((res) => {
        if (res) {
          const data = res?.data?.attributes;
          let state = [...notifUpdates];
          data &&
            Object.keys(data).forEach((key) => {
              state.forEach((item) => {
                if (item.fieldName === key) item.notificationStatus = data[key];
              });
            });
          setNotifUpdates(state);
          globalDispatch({
            type: "setListData",
            payload: { name: "notificationPrefs", value: state },
          });
        }
      })
      .catch((err) => {
        console.log("error in fetching fellow notification preferences :", err);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="settings">
      {/* tabs and main menu  */}
      <div className="settings-header-wrapper">
        <div className="settings-icon">
          <SettingsIcon opacity="1" />
          <div className="settings-title">{t("common:settings")}</div>
        </div>
        <div className="settings-header">
          <div
            className={`menu ${activeTab === 0 ? "active" : ""}`}
            onClick={() => setActiveTab(0)}
          >
            {t("settings:tabs.profile")}
          </div>
          <div
            className={`menu ${activeTab === 1 ? "active" : ""}`}
            onClick={() => setActiveTab(1)}
          >
            {t("settings:tabs.account")}
          </div>
          <div
            className={`menu ${activeTab === 2 ? "active" : ""}`}
            onClick={() => setActiveTab(2)}
          >
            {t("settings:tabs.notifications")}
          </div>
        </div>
      </div>
      <div className="settings-content">{renderContent(t)}</div>
    </div>
  );
};

export default Settings;
