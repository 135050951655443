import React from "react";
import Logo from "../../images/ashoka-logo.svg";
import Loader from "../basic-components/loader";
import "./style.scss";

const PageLanguageLoader = () => {
  return (
    <div className="language-loader-wrapper">
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={Logo} alt="logo" className="logo" />
        <Loader size={24} />
      </div>
    </div>
  );
};

export default PageLanguageLoader;
