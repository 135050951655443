import React, { useCallback, useEffect, useState } from "react";
import "./contact-list.scss";
import FellowRow from "../basic-components/fellowRow";
import { ReactComponent as SearchIcon } from "../../images/search-icon.svg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  getFellowLocation,
  getFellowImage,
  getRelationshipMappings,
} from "../../services/helper-functions";
import { useGlobalState } from "../context/globalContext";
import { useTranslation } from "react-i18next";
import SkeletonFellowCardLoader from "../Loaders/skeletonFellowCardLoader";
import { Link } from "react-router-dom";

const ContactList = (props) => {
  const { t } = useTranslation(["common"]);
  const {
    contactList,
    removeFellowFromContactList,
    setContactList,
    loaders,
  } = props;
  const { contactLoader } = loaders;
  const [filteredFellows, setFilteredFellows] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const allStatus = [
    { isCollaborating: false, title: t("common:search_filter.all_status") },
    {
      isCollaborating: true,
      title: t("common:search_filter.seeking_collaboration"),
    },
  ];
  const [status, setStatus] = useState(allStatus[0]);
  const { currentProfileImg, countriesInJson, fellowData } = useGlobalState();
  const [isLoading, setLoader] = useState(false);

  const getFileteredFellows = useCallback(
    debounce((status, searchKeyword, contactList) => {
      setLoader(true);
      let fellows = contactList?.data ? [...contactList.data] : [];

      if (status?.isCollaborating) {
        fellows = fellows?.filter(
          (fellow) =>
            fellow.attributes.field_collaboration_status ===
            status.isCollaborating
        );
      }

      if (searchKeyword) {
        fellows = fellows?.filter(
          (fellow) =>
            fellow.attributes.title
              .toLowerCase()
              .search(searchKeyword.toLowerCase()) !== -1
        );
      }

      setFilteredFellows(fellows);
      setLoader(false);
    }, 500),
    []
  );

  useEffect(() => {
    getFileteredFellows(status, searchKeyword, contactList);
  }, [status, searchKeyword, getFileteredFellows, contactList]);

  useEffect(() => {
    setLoader(true);
    if (
      fellowData?.data?.relationships?.field_fd_contact_list?.data
      && !contactList?.data?.length
    ) {
      const list = getRelationshipMappings(
        fellowData?.data?.relationships?.field_fd_contact_list?.data,
        fellowData.included
      );
      setContactList({ data: list || [], include: fellowData.included });
      setLoader(false);
    }
    setLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fellowData, setContactList]);

  const noResults = () => {
    return (
      <div className="no-result">
        {!filteredFellows?.length && !contactList?.data?.length ? (
          <>
            <div className="empty-contacts">
              Once you save a fellow contact, it will show up here.{" "}
            </div>
            <Link
              to={{ pathname: "/search", state: { searchby: "topics" } }}
              className="search-fellows"
            >
              Search for fellows in smiliar field as yours?
            </Link>
          </>
        ) : (
          <>
            {searchKeyword && (
              <h1 className="search-text">{`"${searchKeyword}"`}</h1>
            )}
            <div>Sorry! There are no results matching your search.</div>
          </>
        )}
      </div>
    );
  };

  const displayFellows = (list) => {
    return list?.map((fellow, index) => {
      let phoneNo = fellow?.attributes?.field_phone?.split(",");
      return (
        <FellowRow
          key={index}
          id={fellow.id}
          nid={fellow?.attributes?.drupal_internal__nid}
          image={
            (fellow?.relationships?.field_media_profile_image?.data?.id &&
              getFellowImage(
                contactList.include,
                fellow.relationships.field_media_profile_image.data.id
              )) ||
            currentProfileImg
          }
          name={fellow?.attributes?.title || ""}
          location={
            fellow?.attributes?.field_fd_fellow_location &&
            getFellowLocation(
              fellow.attributes.field_fd_fellow_location,
              countriesInJson
            )
          }
          method="DELETE"
          removeFellowFromContactList={removeFellowFromContactList}
          socialMedias={
            fellow?.attributes?.field_other_social_media_links
              ?.platform_values || []
          }
          email={fellow?.attributes?.field_email || ""}
          phone={
            phoneNo && phoneNo.length > 1 && phoneNo[phoneNo.length - 1]
              ? fellow?.attributes?.field_phone
              : ""
          }
          smAccs={
            fellow?.attributes?.field_other_social_media_links?.platform_values
          }
          imAccs={fellow?.attributes?.field_fd_fellow_ims?.platform_values}
          contactPrefs={fellow?.attributes?.field_fd_contact_preferences}
          t={t}
          isLoading={
            contactLoader?.id === fellow?.id
              ? contactLoader.isLoading
              : undefined
          }
        />
      );
    });
  };

  return (
    <div className="contact-list">
      <div className="searchbar-wrapper">
        <div className="searchbar">
          <SearchIcon className="search-icon" />
          <label for="con_search" className="hidden">Search</label>
          <input
            type="text"
            placeholder={t("contact_list.search_lable")}
            className="search-text"
            onChange={(e) => setSearchKeyword(e.target.value)}
            id="con_search"
          />
        </div>
      </div>
      <div className="content-wrapper">
        <div className="title-wrap">
          <div className="title">{t("contact_list.title")}</div>
          <div className="filter-dropdown"></div>
          <Autocomplete
            disableClearable={true}
            value={status}
            options={allStatus}
            onChange={(event, value) => {
              setStatus(value);
            }}
            name="status"
            className="autocomplete-feilds"
            getOptionLabel={(option) => option.title}
            getOptionSelected={(option, value) => option.title === value.title}
            renderInput={(params) => {
              params.inputProps.autoComplete = "new-password";
              return (
                <TextField
                  {...params}
                  label={t("contact_list.filter_lable")}
                  variant="filled"
                />
              );
            }}
          />
        </div>
        <div className="fellow-list">
          {!isLoading &&
            (contactList?.data?.length > 0 || filteredFellows?.length > 0) &&
            displayFellows(
              status?.isCollaborating || searchKeyword
                ? filteredFellows
                : contactList.data
            )}
          {(isLoading || !fellowData) &&
            Array(5)
              .fill(null)
              .map((item, index) => {
                return <SkeletonFellowCardLoader key={index} />;
              })}
          {!isLoading &&
            fellowData &&
            (((searchKeyword || status?.isCollaborating) &&
              !filteredFellows?.length) ||
              (!filteredFellows?.length && !contactList?.data?.length)) &&
            noResults()}
        </div>
      </div>
    </div>
  );
};

export default ContactList;

function debounce(fn, wait) {
  let t;
  return function () {
    clearTimeout(t);
    t = setTimeout(() => fn.apply(this, arguments), wait);
  };
}
