import "./contactMethods.scss";

import {
  getAvailableMediaContacts,
  renderIcon,
} from "../../../services/helper-functions";
import { instantMessangers, medias } from "../../../services/constantValues";

import CancelIcon from "@material-ui/icons/Cancel";
import CustomButton from "../../basic-components/button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ReactComponent as DisabledEmail } from "../../../images/disabled-email-selector.svg";
import { ReactComponent as DisabledPhone } from "../../../images/disabled-mobile-selector.svg";
import Divider from "@material-ui/core/Divider";
import { ReactComponent as EnabledEmail } from "../../../images/enabled-email-selector.svg";
import { ReactComponent as EnabledPhone } from "../../../images/enabled-mobile-selector.svg";
import List from "@material-ui/core/List";
import { ListItem } from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  head_text: {
    padding: "30px 30px 36px 30px",
  },
  title: {
    fontSize: 24,
    padding: 0,
    color: "#4155EE",
  },
  divider: {
    margin: "10px 16px 30px 16px",
  },
  primary_list_wrapper: {
    display: "flex",
    alignItems: "flex-start",
    margin: "0 30px",
    minWidth: "300px",
  },
  primary_list_item: {
    width: "auto",
    marginRight: "20px",
    paddingRight: 0,
    padding: 0,
  },
  secondary_list_wrapper: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    margin: "0 24px 0 24px",
    padding: 0,
  },
  secondary_list_item: {
    border: "1px solid #4155EE",
    margin: "5px",
    width: "auto",
    borderRadius: "4px",
    padding: "9px 13px",
  },
  box_modal: {
    margin: "30px",
  },
  button: {
    margin: "82px 10px 30px 0",
    color: "rgba(0, 0, 0, 0.87)",
    width: "auto",
  },
  close: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  list_item_link: {
    display: "flex",
  },
  enabled_icon: {
    boxShadow: "0 4px 2px -3px gray",
  },
});
const ContactMethods = withStyles(styles)((props) => {
  const {
    onClose,
    open,
    smAccs,
    imAccs,
    contactPrefs,
    email,
    phone,
    classes,
  } = props;
  const handleClose = () => {
    onClose();
  };
  const getAllMedias = () => {
    const {
      allAllowedMedias,
      isEmailPrefered,
      isPhonePrefered,
    } = getAvailableMediaContacts(
      { ...smAccs, ...imAccs },
      contactPrefs,
      phone,
      email,
      [...medias, ...instantMessangers]
    );
    // if email is allowed or no any other allowed show enabled icon
    // if no any other contact allowed show enabled email icon
    // if email not provided or not allowed show disabled icon
    // same for phone no.
    return (
      <>
        <List className={classes.primary_list_wrapper}>
          {/* if email is allowed or no any other allowed show enabled email icon */}
          {isEmailPrefered || !allAllowedMedias?.length ? (
            <ListItem className={classes.primary_list_item}>
              <a
                href={`mailto:${email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <EnabledEmail className={classes.enabled_icon} />
              </a>
            </ListItem>
          ) : (
            <ListItem className={classes.primary_list_item}>
              <DisabledEmail />
            </ListItem>
          )}
          {/* if phone no is allowed show enabled phone icon */}
          {isPhonePrefered ? (
            <ListItem className={classes.primary_list_item}>
              <a
                href={`tel:${phone}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <EnabledPhone className={classes.enabled_icon} />
              </a>
            </ListItem>
          ) : (
            <ListItem className={classes.primary_list_item}>
              <DisabledPhone />
            </ListItem>
          )}
        </List>
        {/* Show all the allowed media contacts if exists */}
        {allAllowedMedias?.length > 0 && (
          <>
            <Divider className={classes.divider} />
            <List className={classes.secondary_list_wrapper}>
              {allAllowedMedias.map((media, index) => {
                return (
                  <a
                    href={media.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${classes.list_item_link} media-values`}
                    key={index}
                  >
                    <ListItem
                      key={index}
                      className={classes.secondary_list_item}
                    >
                      {renderIcon(
                        media.title.toLowerCase(),
                        "icon contact-modal-icons"
                      )}
                    </ListItem>
                  </a>
                );
              })}
            </List>
          </>
        )}
      </>
    );
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={`contact-dialog-box`}
    >
      <div className={classes.head_text}>
        <DialogTitle id="simple-dialog-title" className={classes.title}>
          Contact via
        </DialogTitle>
        <CancelIcon
          color="action"
          onClick={handleClose}
          className={classes.close}
        />
      </div>
      {getAllMedias()}
      <DialogActions>
        <CustomButton
          text="Cancel"
          onClick={handleClose}
          classes={classes.button}
        />
      </DialogActions>
    </Dialog>
  );
});
export default ContactMethods;
