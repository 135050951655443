import React, { useState, useEffect } from "react";
import Footer from "../footer";
import "./accountSettings.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { ReactComponent as DisabledIcon } from "../../../images/disabled.svg";
import { useGlobalState, useGlobalDispatch } from "../../context/globalContext";
import { allowedLaguages } from "../../../services/constantValues";
import {
  updatePrefrredLanguage,
  updateEmail,
} from "../../../services/fellow.patchService";
import ErrorAlert from "../../basic-components/snackbar";
import { useTranslation } from "react-i18next";

const AccountSettings = ({ t }) => {
  const { fellowData, preferredLang } = useGlobalState();
  const [emailVerification, setEmailVerification] = useState(
    fellowData?.data?.attributes.field_email
  );
  const [lang, setLang] = useState(preferredLang);
  const globalDistaptch = useGlobalDispatch();
  const [errors, setErrors] = useState({
    errorSeverity: "",
    openSnackbar: false,
    snackbarMsg: "",
  });
  const [loader, setLoader] = useState(false);

  //i18n
  const { i18n } = useTranslation();

  const onSave = () => {
    setLoader(true);
    if (lang) {
      updatePrefrredLanguage(lang.code)
        .then((res) => {
          setErrors({
            errorSeverity: "success",
            openSnackbar: true,
            snackbarMsg: "Language preference saved",
          });
          globalDistaptch({
            type: "setListData",
            payload: { name: "preferredLang", value: lang },
          });
          i18n.changeLanguage(lang.code);
          setLoader(false);
        })
        .catch((err) => {
          setErrors({
            errorSeverity: "error",
            openSnackbar: true,
            snackbarMsg: "Could not save the language preference",
          });
          setLoader(false);
        });
    }

    fellowData.data.attributes.field_email = emailVerification;
    if (emailVerification) {
      updateEmail(emailVerification)
        .then((res) => {
          setErrors({
            errorSeverity: "success",
            openSnackbar: true,
            snackbarMsg: "Email preference saved",
          });
          globalDistaptch({
            type: "setEmail",
            payload: { emailVerification },
          });
          setLoader(false);
        })
        .catch((err) => {
          setErrors({
            errorSeverity: "error",
            openSnackbar: true,
            snackbarMsg: "Could not save the Email preference",
          });
          setLoader(false);
        });
    }
  };

  const onCancel = () => {
    setLang(preferredLang);
  };

  useEffect(() => {
    let language = fellowData?.data?.attributes?.field_fd_ui_language || {};
    if (language && !preferredLang) {
      let a = allowedLaguages.find((lang) => lang.code === language);
      setLang(a || null);
      globalDistaptch({
        type: "setListData",
        payload: { name: "preferredLang", value: a || null },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSnackbar = (value) => {
    setErrors((errors) => ({ ...errors, openSnackbar: value }));
  };

  return (
    <div className="account-settings">
      <div className="account-settings-content">
        <div className="content-box">
          <div className="head-text">{t("settings:account.title")}</div>

          <Autocomplete
            disableClearable={false}
            value={lang}
            options={allowedLaguages}
            onChange={(event, value) => {
              setLang(value);
            }}
            name="lang"
            className="region"
            getOptionLabel={(option) => option.title || option.code}
            getOptionSelected={(option) => option.title === lang.title}
            renderInput={(params) => {
              params.inputProps.autoComplete = "new-password";
              return (
                <TextField
                  {...params}
                  label={t("common:language")}
                  variant="filled"
                  classes="1234"
                />
              );
            }}
          />
          <div className="disabled">
            <TextField
              id="filled-disabled"
              label={t("common:email")}
              variant="filled"
              className="textfield"
              value={emailVerification}
              onChange={(e) => {
                setEmailVerification(e.target.value);
              }}
            />
            <DisabledIcon className="disabled-icon" />
          </div>
        </div>
      </div>
      <Footer onSave={onSave} onCancel={onCancel} t={t} loader={loader} />
      <ErrorAlert
        openSnackbar={errors.openSnackbar}
        msg={errors.snackbarMsg}
        errorSeverity={errors.errorSeverity}
        setSnackbar={setSnackbar}
      />
    </div>
  );
};

export default AccountSettings;
